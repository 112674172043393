<div class="gt-phongban">
  <div class="tieude-mobile">
    <app-gioithieu-mobile
      [isShowGioithieu2]="true">
    </app-gioithieu-mobile>
  </div>
  <div class="item">
    <div class="item-inner1">GIỚI THIỆU PHÒNG BAN</div>
    <div class="item-inner2" data-aos="fade-up">
      <img class="sodo_tochuc" src="assets/images/sodo_pnc.png" >
    </div>
  </div>
</div>
