/* tslint:disable:typedef */
import { Injectable } from '@angular/core';
import {TableName} from '../../_models/constants/tableName';
import {HttpClient} from '@angular/common/http';
import {ReplaySubject} from 'rxjs';
import {EnvironmentsService} from '../environments.service';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {
  baseUrl: any;

  constructor(private http: HttpClient, private envService: EnvironmentsService) {
    this.baseUrl = envService.apiUrl;
  }

  getDataComboBox(content: any) {
    return this.http.post<any>(this.baseUrl + '/data_format_recruitment', content);
  }

  addCandidate(content: any) {
    return this.http.post<any>(this.baseUrl + '/form_recruitment', content);
  }

  // // @ts-ignore
  // public getUniversityList(type?){
  //   const dt: ReplaySubject<any> = new ReplaySubject(1);
  //   // @ts-ignore
  //   const arr = [];
  //   let table;
  //   if (type === 'ten_truong_hoc'){
  //     // @ts-ignore
  //     table = this.universityTb;
  //   } else if (type === 'ten_chuyen_nganh') {
  //     // @ts-ignore
  //     table = this.majorTb;
  //   } else if (type === 'ten_dan_toc'){
  //     // @ts-ignore
  //     table = this.dantocTb;
  //   } else if (type === 'ten_cty'){
  //     // @ts-ignore
  //     table = this.ctyFptTb;
  //   } else if (type === 'ten_vi_tri') {
  //     // @ts-ignore
  //     table = this.vtcvTb;
  //   }
  //   const query = {
  //     query: 'Select ' + type + ' from ' + table,
  //   };
  //   this.http.post(this.baseUrl + '/empCheckin', query).subscribe((res) => {
  //     if (res){
  //       // @ts-ignore
  //       res.forEach((i) => {
  //         arr.push(i[type]);
  //       });
  //       // @ts-ignore
  //       dt.next(arr);
  //     }
  //   });
  //   return dt;
  // }
  //
  // // @ts-ignore
  // getSelectFilterItem(str){
  //   const content = {
  //     type: str
  //   };
  //   return this.http.post(this.baseUrl + '/thong_tin_emp', content);
  // }
}
