<div class="danhsach">
  <div class="danhsach-inner">
    <div class="danhsach-inner-inner">
      <div class="tieude">15 Trung Tâm Triển Khai & Bảo Trì tại VÙNG 5</div>
      <div class="noidung" *ngFor="let i of listTrungtam | slice: 0 :7 ">
        <div class="name">
          <p class="name-inner"><em>Trung tâm Triển khai & Bảo trì {{i.maso}}: </em>{{i.khuvuc}}</p>
          <p>{{i.diachi}}</p>
        </div>
      </div>
    </div>
    <div class="danhsach-inner-inner">
      <div class="noidung" *ngFor="let i of listTrungtam | slice: 7">
        <div class="name">
          <p class="name-inner"><em>Trung tâm Triển khai & Bảo trì {{i.maso}}: </em>{{i.khuvuc}}</p>
          <p>{{i.diachi}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
