<div class="layout-dv" *ngIf="listDichvu">
  <div class="item1">
    <div class="item-inner">{{listDichvu.gioithieu}}</div>
  </div>
  <div class="item2">
    <div class="item-inner">
      <div class="demuc">
        <div class="icon"><img src="assets/images/icon-spdv4.svg"></div>
        <div class="tieude">
          <div class="tieude-inner">Đáp Ứng</div>
        </div>
      </div>
      <div class="noidung">
        <div class="hinh"><img src="assets/images/{{listDichvu.img}}"></div>
        <div class="noidung-inner">
          <div *ngFor="let i of listDichvu.noidung">{{i}}</div>
          </div>
      </div>
    </div>
  </div>
  <div class="item3">
    <div class="item-inner">
      <div class="demuc">
        <div class="icon"><img src="assets/images/icon-spdv5.svg"></div>
        <div class="tieude">
          <div class="tieude-inner">Tiết Kiệm</div>
          <div class="text">{{listDichvu.tietkiem}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="item4">
    <div class="item-inner">
      <div class="demuc">
        <div class="icon"><img src="assets/images/icon-spdv6.svg"></div>
        <div class="tieude">
          <div class="tieude-inner">Ổn Định - Bảo Mật</div>
          <div class="text">{{listDichvu.baomat}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="item5">
    <div class="item-inner">
      <div class="demuc">
        <div class="icon"><img src="assets/images/icon-spdv7.svg"></div>
        <div class="tieude">
          <div class="tieude-inner">Mở Rộng - Sẵn Sàng</div>
          <div class="text"><div *ngFor="let i of listDichvu.morong">{{i}}</div></div>
        </div>
      </div>
    </div>
  </div>
</div>
