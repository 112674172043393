<div class="l-container">
<!--  <div class="bg"></div>-->
<!--  <div class="bg bg2"></div>-->
<!--  <div class="bg bg3"></div>-->
  <div class="c-body">
<!--    <div data-aos="fade-up" class="news-container pc-view">-->
<!--      <div *ngFor="let o of newsList" class="news-inner">-->
<!--        <div class="news-img">-->
<!--          <a [href]="o.linkUrl">-->
<!--            <img class="img-detail" [src]="o.img | safeResourceUrl">-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="news-content">-->
<!--          <a [href]="o.linkUrl">-->
<!--            <p class="tieude">{{o.tieu_de}}</p>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div data-aos="fade-up" class="apply-form">
      <app-apply-form></app-apply-form></div>
    <div class="fanpage pc-view">
      <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FVi%25E1%25BB%2587c-l%25C3%25A0m-Vi%25E1%25BB%2585n-Th%25C3%25B4ng-Ph%25C6%25B0%25C6%25A1ng-Nam-1918899685107482&tabs=timeline&width=300&height=900&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              width="auto" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
              allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
      </iframe>
    </div>
  </div>
</div>
