import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {GioithieuCtyComponent} from './gioithieu-cty/gioithieu-cty.component';
import {MatTabsModule} from '@angular/material/tabs';
import {SodoComponent} from './sodo/sodo.component';
import {GtCongtyComponent} from './gioithieu-cty/gt-congty/gt-congty.component';
import {LichsuHtComponent} from './gioithieu-cty/lichsu-ht/lichsu-ht.component';
import {TongquanComponent} from './gioithieu-cty/tongquan/tongquan.component';


const routes: Routes = [
  {path: 'gioi-thieu-cong-ty', component: GioithieuCtyComponent,
  children: [
    {path: 'cong-ty', component: GtCongtyComponent},
    {path: 'lich-su-hinh-thanh', component: LichsuHtComponent},
    {path: 'tong-quan', component: TongquanComponent},
  ],
  },
  {path: 'so-do', component: SodoComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [
    RouterModule,
    MatTabsModule
  ]
})
export class GioithieuRoutingModule { }
