import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-img-carousel',
  templateUrl: './img-carousel.component.html',
  styleUrls: ['./img-carousel.component.css']
})
export class ImgCarouselComponent implements OnInit {
  @Input() imageList = ['trang_chu/home21.jpg', 'trang_chu/home7.jpg', 'trang_chu/home8.png'];
  // @Input() imageList = ['anh-bia-1200x400.jpg', 'bia-web-1200x400.jpg', 'IMG_5486-1200x400.jpg'];
  constructor() { }

  ngOnInit(): void {
  }

}
