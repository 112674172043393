<div class="detail-news">
  <div class="detail-news-background">
    <div class="detail-news-inner">
      <div class="item1">
        <div class="container-news">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div class="item2">
        <div class="item2-inner">
          <div class="item2-tieude">TIN LIÊN QUAN</div>
          <app-news-list [flexWidth]="'flexWidth11'"
                         [isShowPageHome]="false"
                         [isShowPage]="true"
                         [typeBang]= "2"
                         [mucluc]="'su_kien'"
          ></app-news-list>
        </div>
<!--        <div class="banner">-->
<!--          <img src="assets/images/banner-tintuc1.png">-->
<!--          <img src="assets/images/banner-tintuc2.png">-->
<!--        </div>-->
      </div>
    </div>
  </div>
</div>

