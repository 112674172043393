<div class="cauchuyentd">
  <div class="nd-tieubieu">
    <div class="nd-tieubieu-inner">
      <div class="item1" data-aos="fade-up">
        <div class="item1-inner1">
          <img src="assets/images/img-cauchuyentd1.png">
        </div>
        <div class="item1-inner2">
          <div class="tieude">PNC TELECOM KÝ KẾT THỎA THUẬN HỢP TÁC VỚI KHOA ĐIỆN – ĐIỆN TỬ TRƯỜNG ĐH CÔNG NGHỆ SÀI GÒN</div>
          <div class="thoigian">
            <div class="icon"><img src="assets/images/icon-home4.png"></div>
            <div class="ngaythang">Thứ Hai, Ngày 26/3/2021</div>
          </div>
          <div class="noidung">Tham dự buổi lễ, về phía đại diện PNC Telecom có anh Phạm Như Hoài Bảo –
            Giám Đốc PNC cùng các cán bộ phòng Đào tạo truyền thông;
            về phía đại diện ban lãnh đạo nhà trường có TS. Trương Quang Mùi – Chủ tịch Hội đồng quản trị,
            PGS.TS Cao Hào Thi – Hiệu trưởng nhà trường,
            TS. Tăng Văn Tơ – Trưởng khoa Điện – Điện tử cùng các lãnh đạo phòng, khoa liên quan, tham gia ký kết.</div>
        </div>
      </div>
      <div class="item2" data-aos="fade-up">
        <div class="item2-inner">
          <div class="tieude">Đăng ký lịch thi tuyển sinh viên công nghệ tập sự 2021 tại Hà Nội</div>
          <div class="thoigian">
            <div class="icon"><img src="assets/images/icon-home4.png"></div>
            <div class="ngaythang">Thứ Hai, Ngày 26/3/2021</div>
          </div>
          <div class="noidung">Tham dự buổi lễ, về phía đại diện PNC Telecom có anh Phạm Như Hoài Bảo –
            Giám Đốc PNC cùng các cán bộ phòng Đào tạo truyền thông;
            về phía đại diện ban lãnh đạo nhà trường có TS. Trương Quang Mùi – Chủ tịch Hội đồng quản trị,
            PGS.TS Cao Hào Thi – Hiệu trưởng nhà trường,
            TS. Tăng Văn Tơ – Trưởng khoa Điện – Điện tử cùng các lãnh đạo phòng, khoa liên quan, tham gia ký kết.</div>
        </div>
        <div class="item2-inner">
          <div class="tieude">Đăng ký lịch thi tuyển sinh viên công nghệ tập sự 2021 tại Hà Nội</div>
          <div class="thoigian">
            <div class="icon"><img src="assets/images/icon-home4.png"></div>
            <div class="ngaythang">Thứ Hai, Ngày 26/3/2021</div>
          </div>
          <div class="noidung">Tham dự buổi lễ, về phía đại diện PNC Telecom có anh Phạm Như Hoài Bảo –
            Giám Đốc PNC cùng các cán bộ phòng Đào tạo truyền thông;
            về phía đại diện ban lãnh đạo nhà trường có TS. Trương Quang Mùi – Chủ tịch Hội đồng quản trị,
            PGS.TS Cao Hào Thi – Hiệu trưởng nhà trường,
            TS. Tăng Văn Tơ – Trưởng khoa Điện – Điện tử cùng các lãnh đạo phòng, khoa liên quan, tham gia ký kết.</div>
        </div>
        <div class="item2-inner">
          <div class="tieude">Đăng ký lịch thi tuyển sinh viên công nghệ tập sự 2021 tại Hà Nội</div>
          <div class="thoigian">
            <div class="icon"><img src="assets/images/icon-home4.png"></div>
            <div class="ngaythang">Thứ Hai, Ngày 26/3/2021</div>
          </div>
          <div class="noidung">Tham dự buổi lễ, về phía đại diện PNC Telecom có anh Phạm Như Hoài Bảo –
            Giám Đốc PNC cùng các cán bộ phòng Đào tạo truyền thông;
            về phía đại diện ban lãnh đạo nhà trường có TS. Trương Quang Mùi – Chủ tịch Hội đồng quản trị,
            PGS.TS Cao Hào Thi – Hiệu trưởng nhà trường,
            TS. Tăng Văn Tơ – Trưởng khoa Điện – Điện tử cùng các lãnh đạo phòng, khoa liên quan, tham gia ký kết.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="nd-lienquan" data-aos="fade-up">
    <div class="nd-lienquan-inner">
      <div class="item1" *ngFor="let item of newsList" data-aos="fade-up">
        <div class="item1-inner">
          <a routerLink="/tuyen-dung/cau-chuyen-tuyen-dung/{{item.tin_tuc_id}}"><div class="hinh"><img src="{{item.img}}"></div>
          <div class="tieude">{{item.title}}</div>
          <div class="noidung">{{item.tom_tat}}</div>
          <div class="lienket">Xem Thêm</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
