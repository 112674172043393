import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {HealthService} from '../../../_services/data/health.service';

@Component({
  selector: 'app-bingmap',
  templateUrl: './bingmap.component.html',
  styleUrls: ['./bingmap.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BingmapComponent implements OnInit, AfterViewInit{
  // @ts-ignore
  @ViewChild('myMap') myMap;
  @Input() pageTitle = '';
  @Input() data: any;
  // @ts-ignore
  @Input() dataEvent: EventEmitter<object>;
  // @ts-ignore
  @Input() dataArrayEvent: EventEmitter<object>;
  dataMap: any;
  dataArray: any;
  @Input() classOfMap = 'default';
  @Input() centerPoint = {
    lat: 16.0544,
    long: 108.2022
  };
  // @ts-ignore
  @Input() centerPointEvent: EventEmitter<object>;
  @Input() infoPoint = 'address';
  @Input() keyLong = 'longitude';
  @Input() keyLat = 'latitude';
  @Input() zoomSize = 7;
  // @ts-ignore
  @Input() isLine: boolean;
  constructor() {
  }
  ngOnInit(): void {
    // console.log(this.data);
  }

  // tslint:disable-next-line:typedef
  async ngAfterViewInit(){
    if (this.dataEvent) {
      this.dataEvent.subscribe(event => {
        // @ts-ignore
        this.dataMap = event.dt;
        // @ts-ignore
        this.centerPoint = event.center;
        this.functionMap();
      });
    } else if (this.data) {
      this.dataMap = this.data;
      this.functionMap();
    }
    if (this.dataArrayEvent){
      this.dataArrayEvent.subscribe(event => {
        // @ts-ignore
        this.dataArray = event.dt;
        // @ts-ignore
        this.centerPoint = event.center;
        this.functionMap();
      });
    }
    if(this.centerPointEvent){
      this.centerPointEvent.subscribe(ev => {
        // @ts-ignore
        this.centerPoint = ev;
      });
    }

  }
  functionMap(): any{
    const map = new Microsoft.Maps.Map(this.myMap.nativeElement, {
      credentials: 'AnjShZOz51fZXOpqQwAvu0MgcOVDPmleaUWxu2n9wGfk1NGXPCuBtfp_nZ5caMkZ',
      center: new Microsoft.Maps.Location(this.centerPoint.lat, this.centerPoint.long),
      zoom: this.zoomSize
    });
    const center = map.getCenter();
    // @ts-ignore
    const pushpin = new Microsoft.Maps.Pushpin(map.getCenter(), null);
    const layer = new Microsoft.Maps.Layer();
    layer.add(pushpin);
    // map.entities.push(polyline);
    map.layers.insert(layer);
    map.entities.push(pushpin);
    pushpin.setOptions({ enableHoverStyle: true, enableClickedStyle: true });
    // @ts-ignore
    const pins = [];
    let pinCounter = 0;
    if (this.dataMap){
      this.dataMap.forEach((i: any) => {
        // @ts-ignore
        if (i[this.keyLat] !== 0 && i[this.keyLong] !== 0) {
          const coor = new Microsoft.Maps.Location(i[this.keyLat], i[this.keyLong]);
          pins[pinCounter] = this.drawPoint(map, coor, i);
          pinCounter++;
        }
      });
      // @ts-ignore
      layer.add(pins);
      map.layers.insert(layer);
    }
    const coors: { flag: any; coor: any[]; }[] = [];
    if (this.dataArray){
      const firstPoint = this.dataArray[0][0];
      const firstCoor = new Microsoft.Maps.Location(firstPoint[this.keyLat], firstPoint[this.keyLong]);
      const lastPoint = this.dataArray[this.dataArray.length - 1][this.dataArray[this.dataArray.length - 1].length - 1];
      const lastCoor = new Microsoft.Maps.Location(lastPoint[this.keyLat], lastPoint[this.keyLong]);
      pins[pinCounter] = this.drawPoint(map, firstCoor, firstPoint);
      pinCounter++;
      pins[pinCounter] = this.drawPoint(map, lastCoor, lastPoint);
      pinCounter++;
      layer.add(pins);
      map.layers.insert(layer);
      this.dataArray.forEach((i: any) => {
        // @ts-ignore
        const a = [];
        i.forEach((j: any) => {
          const coor1 = new Microsoft.Maps.Location(j[this.keyLat], j[this.keyLong]);
          pins[pinCounter] = this.drawPoint(map, coor1, j);
          pinCounter++;
          // @ts-ignore
          layer.add(pins);
          map.layers.insert(layer);
          a.push(coor1);
        });
        // @ts-ignore
        coors.push({flag: i[0].flag, coor: a});
      });
      console.log(coors);
      coors.forEach((i) => {
        if (i.flag === 1){
          const line1 = new Microsoft.Maps.Polyline(i.coor, {
            strokeColor: 'red',
            strokeThickness: 3,
          });
          map.entities.push(line1);
        } else if (i.flag === 0){
          const line2 = new Microsoft.Maps.Polyline(i.coor, {
            strokeColor: 'blue',
            strokeThickness: 2,
          });
          map.entities.push(line2);
        }
      });
    }
  }

  drawPoint(map: any, coor: any, point: any): any{
    const pin = new Microsoft.Maps.Pushpin(coor, {
      color: '#ED1C24',
      enableHoverStyle: true
    });
    const infobox = new Microsoft.Maps.Infobox(coor, {
      title: point[this.infoPoint],
      visible: false
    });
    infobox.setMap(map);
    // tslint:disable-next-line:only-arrow-functions
    Microsoft.Maps.Events.addHandler(pin, 'click', () => {
      infobox.setOptions({ visible: true });
    });
    return pin;
  }

}
