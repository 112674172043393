import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tintuc-sukien',
  templateUrl: './tintuc-sukien.component.html',
  styleUrls: ['./tintuc-sukien.component.css']
})
export class TintucSukienComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
