/* tslint:disable:typedef */
import { Component, OnInit } from '@angular/core';
import { AddressService } from 'src/app/_services/data/address.service';
import {ParticipantService} from '../../../../_services/data/participant.service';
import {DataService} from '../../../../_services/data/data.service';
import {TableName} from '../../../../_models/elements/tableName';
import Swal from 'sweetalert2';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-cohoi',
  templateUrl: './cohoi.component.html',
  styleUrls: ['./cohoi.component.css']
})
export class CohoiComponent implements OnInit {
  chosen = 1;
  jobList: any;
  vitriList: any;
  cityList: any;
  capbacList = ['Thực tập', 'Nhân viên'];
  message = '';
  element = {
    vi_tri: '',
    cap_bac: '',
    khu_vuc: '',
  };
  today = new Date();
  td = [String(this.today.getDate()), String(this.today.getMonth() + 1), this.today.getFullYear()].join('/');
  time = [String(this.today.getHours()), String(this.today.getMinutes() + 1), this.today.getSeconds()].join(':');
  day = this.td + ' ' + this.time;
  content = {
    date_get: this.day
  };
  constructor(private participantService: ParticipantService, private dataService: DataService,
              private addressService: AddressService) { }

  ngOnInit(): void {
    this.getListSelect();
    this.searchVacancy();
  }

  async getListSelect(){
    this.participantService.getDataComboBox(this.content).subscribe((res) => {
      this.vitriList = res.result.data.vi_tri_ung_tuyen;
    });
    const dt = await this.addressService.getInfoCityDistrict();
    this.cityList = Object.keys(dt.data);
  }

  dtEvent(e: any) {
    // console.log(e);
    if (e.str === 'thuc_tap') {
      switch (e.value) {
        case 'Thực tập':
          // @ts-ignore
          this.element.cap_bac = '1';
          break;
        case 'Nhân viên':
          // @ts-ignore
          this.element.cap_bac = '0';
          break;
        case null:
          this.element.cap_bac = '';
          break;
        case undefined:
          this.element.cap_bac = '';
          break;
      }
    }
    if (e.str === 'vi_tri') {
      if (e.value === null || e.value === undefined) {
        this.element.vi_tri = '';
      }
      else {
        this.element.vi_tri = e.value;
      }
    }
    if (e.str === 'khu_vuc') {
      if (e.value === null || e.value === undefined) {
        this.element.khu_vuc = '';
      }
      else {
        this.element.khu_vuc = e.value;
      }
    }
  }

  // tslint:disable-next-line:typedef
  searchVacancy(){
    // @ts-ignore
    const objAdd = {
      condition: this.element,
      name_table: TableName.tuyendungInfo
    };
    this.dataService.getInfoTuyenDung(objAdd).subscribe((res) => {
      if (res.result) {
        const r = res.result;
        if (r.status === 1){
          this.jobList = r.detail.data;
          this.message = '';
        } else {
          this.jobList = r.detail.data;
          this.message = r.msg;
        }
      } else {
        Swal.fire('Lỗi', 'Tìm kiếm thất bại!', 'error');
      }
    }, error => {
      Swal.fire('Lỗi', 'Tìm kiếm thất bại!', 'error');
    });
  }
}
