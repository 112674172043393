// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  logoutUrl: 'https://adfs.fpt.com.vn/adfs/oauth2/logout?id_token_hint=',
  // backendUrl: 'http://localhost:5000',
  // redirectUrl: 'http://localhost:4200',
  backendUrl: 'https://mytinpnc.vn',
  redirectUrl: 'https://mytinpnc.vn/api/auth',
  // backendUrl: 'https://mypt-api.fpt.vn',
  // redirectUrl: 'https://mypt.fpt.vn/api/auth',
  // backendUrl: 'http://mypt-api-stag.fpt.net',
  // redirectUrl: 'http://mypt-stag.fpt.vn/api/auth',
  // backendUrl: 'https://apis-stag.fpt.vn',
  // redirectUrl: 'http://mypt-stag.fpt.vn/api/auth',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
