import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainpageRoutingModule } from './mainpage-routing.module';
import {GioithieuComponent} from './gioithieu/gioithieu.component';
import {TuyendungComponent} from './tuyendung/tuyendung.component';
import {TruyenthongComponent} from './truyenthong/truyenthong.component';
import {SharedModule} from '../shared/shared.module';
import { MainpageComponent } from './mainpage.component';
import {TruyenthongModule} from './truyenthong/truyenthong.module';
import {GioithieuModule} from './gioithieu/gioithieu.module';
import { UngtuyenComponent } from './tuyendung/ungtuyen/ungtuyen.component';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { SanphamDichvuComponent } from './sanpham-dichvu/sanpham-dichvu.component';
import {TuyendungRoutingModule} from './tuyendung/tuyendung-routing.module';
import {TuyendungModule} from './tuyendung/tuyendung.module';
import { LienheComponent } from './lienhe/lienhe.component';
import { NoiboComponent } from './noibo/noibo.component';
import { TintucSukienComponent } from './tintuc-sukien/tintuc-sukien.component';
import {TintucSukienModule} from './tintuc-sukien/tintuc-sukien.module';
import {SanphamDichvuModule} from './sanpham-dichvu/sanpham-dichvu.module';
import {MatIconModule} from '@angular/material/icon';
import { DaoTaoComponent } from './dao-tao/dao-tao.component';
import {NgxPaginationModule} from 'ngx-pagination';



@NgModule({
  declarations: [
    GioithieuComponent,
    TuyendungComponent,
    TruyenthongComponent,
    MainpageComponent,
    UngtuyenComponent,
    SanphamDichvuComponent,
    LienheComponent,
    NoiboComponent,
    TintucSukienComponent,
    DaoTaoComponent
  ],
  imports: [
    CommonModule,
    MainpageRoutingModule,
    SharedModule,
    TruyenthongModule,
    GioithieuModule,
    FormsModule,
    NgSelectModule,
    MatDatepickerModule,
    TuyendungModule,
    TintucSukienModule,
    SanphamDichvuModule,
    MatIconModule,
    NgxPaginationModule
  ],
  exports: [
    UngtuyenComponent
  ]
})
export class MainpageModule {}
