import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-layout-dv',
  templateUrl: './layout-dv.component.html',
  styleUrls: ['./layout-dv.component.css']
})
export class LayoutDvComponent implements OnInit {
  @Input() tenDichvu: any;
  listDichvus = [
    {
      gioithieu: 'Phần cứng là bộ phận thường xuyên cần được bảo dưỡng, bảo trì không kém gì phần mềm.' +
        ' Thiết bị hoạt động đạt hiệu quả tốt nhất thì cả phẩn cứng lẫn ' +
        'phần mềm đều phải đảm bảo trong tình trạng hoạt động tốt nhất. ',
      img: 'img-spdv8.png',
      noidung: [
        'Cung cấp dịch vụ bảo trì phần cứng các thiết bị máy tính, ' +
        'laptop, máy in, máy fax, thiết bị văn phòng…chuyên nghiệp chất lượng cao.',
        'Sửa chữa phần cứng máy tính/Laptop',
        'Sửa chữa hệ thống Camera/CCTV',
        'Sửa chữa hệ thống mạng',
        'Sửa chữa thiết bị khác',
      ],
      tietkiem: 'Tiết kiệm chi phí và xử lý nhanh chóng đồng thời đảm bảo độ ổn định',
      baomat: 'Cài đặt sửa chữa phần cứng đảm bảo chất lượng và chất lượng tốt ',
      morong: [
        'Sẵn sàng thực hiện cài đặt thêm các phần mềm theo nhu cầu của khách hàng khi có phát sinh.',
        'Hỗ trợ tư vấn khắc phục sự cố cho khách hàng trong suốt thời gian sử dụng dịch vụ.'
        ],
    },
    {
      gioithieu: 'Dịch vụ cài đặt hệ điều hành tận nơi sẽ giúp cho máy tính của bạn ' +
        'chạy mượt như mới sau một thời gian sử dụng. Với các chuyên gia' +
        ' chuyên xử lý và ứng cứu sự cố máy tính chúng tôi hoàn toàn có thể đáp ứng nhu ' +
        'cầu của bạn một cách đơn giản và nhanh chóng nhất.',
      img: 'img-spdv9.png',
      noidung: [
        'Cài đặt hệ điều hành máy tính/laptop theo yêu cầu (Windows, Linux,IOS..)',
        'Cài đặt đầy đủ driver chuẩn từ nhà sản xuất đảm bảo máy tính hoạt động ổn định',
        'Cài đặt Microsoft Office (Word, Excel, Powerpoint)',
        'Các phần mềm văn phòng: PDF, Unikey, Chrome, ...',
        'Các phần mềm diệt virus miễn phí/trả phí bản quyền ',
        ],
      tietkiem: 'Tiết kiệm chi phí và xử lý nhanh chóng đồng thời đảm bảo độ ổn định',
      baomat: 'Cài đặt hệ điều hành phần mềm trực' +
        ' tiếp từ các trang cung cấp đảm bảo sự chất lượng, bảo mật và hạn chế tối đa sự tấn công từ hệ thống bên ngoài',
      morong: [
        'Sẵn sàng thực hiện cài đặt thêm các phần mềm theo nhu cầu của khách hàng khi có phát sinh.',
        'Hỗ trợ tư vấn khắc phục sự cố cho khách hàng trong suốt thời gian sử dụng dịch vụ.'
        ]
    },
    {
      gioithieu: 'Hệ thống mạng ổn định là một trong các yếu tố quan trọng được ' +
        'đặt lên hàng đầu của các doanh nghiệp hiện nay.PNC Telecom hơn 10 năm kinh nghiệm triển khai' +
        'bảo trì các hệ thống lớn chúng tôi cung cấp các dịch vụ tối ưu ' +
        'và tiết kiệm chi phí cho các doanh nghiệp & khách hàng.',
      img: 'img-spdv10.jpg',
      noidung: [
        'Nhu cầu triển khai lắp đặt hạ tầng hệ thống mạng',
        'Tư vấn các giải pháp thiết kế tối ưu đến lắp đặt hệ thống mạng wifi diện rộng cho các doanh nghiệp nhanh chóng.',
        'Tư vấn thiết kế hệ thống mạng wifi truyền hình cho các hộ gia đình',
        'Đảm bảo hệ thống sử dụng lâu dài, dễ dàng sửa chữa bảo trì nhanh chóng',
      ],
      tietkiem: 'Tiết kiệm chi phí và xử lý nhanh chóng đồng thời đảm bảo độ ổn định',
      baomat: 'Thiết kế theo tiêu chuẩn hạn chế tối đa các rủi ro của hệ thống và' +
        ' bảo mật được coi trọng nhằm hạn chế việc ảnh hưởng đến dữ liệu và tấn công từ các hệ thống bên ngoài vào.',
      morong: [
        'Sẵn sàng thực hiện các thay đổi mở rộng khi khách hàng có nhu cầu. ',
        'Hệ thống được thiết kế thông minh cho phép các dịch vụ hoạt động',
        'ổn định và hạn chế tối đa lỗi khi có sự cố.',
        'Hỗ trợ khắc phục sự cố cho khách hàng trong suốt thời gian sử dụng dịch vụ.'
        ]
    },
  ];
  listPhanmem: any;
  listKhacphucsuco: any;
  listDichvu: any;

  constructor() { }

  ngOnInit(): void {
    this.getListDichvu();
  }
  getListDichvu(): void {

    switch (this.tenDichvu) {
      case 1:
        this.listDichvu = this.listDichvus[0];
        break;
      case 2:
        this.listDichvu = this.listDichvus[1];
        break;
      case 3:
        this.listDichvu = this.listDichvus[2];
        break;
    }
  }
  //   console.log(this.tenDichvu);
  //   // tslint:disable-next-line:no-conditional-assignment
  //   if (this.tenDichvu = 1) {
  //     this.listDichvu = this.listDichvus[0];
  //     // tslint:disable-next-line:no-conditional-assignment
  //   } else if (this.tenDichvu = 2) {
  //     this.listDichvu = this.listDichvus[2];
  //     console.log(this.listDichvu);
  //   } else {
  //     this.listDichvu = this.listDichvus[2];
  //   }
  // }

}
