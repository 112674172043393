<div class="truyenhinhfpt">
  <div class="truyenhinhfpt-inner">
    <div class="item1">
      <div class="tieude">
        <img src="assets/images/icon-truyenhinhfpt1.png">
        <div class="tieude-text">GIỚI THIỆU</div>
      </div>
      <div class="noidung">
        <div class="noidung-text">
          <div>Gói kênh MAXY  sở hữu gần 200 kênh truyền hình trong nước và quốc tế với nhiều nội dung vượt trội,
          trong đó có 70 kênh chất lượng chuẩn HD. Không những vậy, gói kênh MAXY  còn được bổ sung thêm 8 kênh nội dung nước ngoài,
          lần đầu tiên xuất hiện: Fox Family Movies, FX, Natgeo Wild, DreamWorks, Animax, Boomerang, BabyFirst, CBeebies.
          Đây là những kênh truyền hình quốc tế được yêu thích tại thị trường  Âu Mỹ và khu vực châu Á,
          khai thác các nội dung dành cho thiếu nhi và cả gia đình.</div>
          <div>Ngoài ra quý khách hàng sẽ được trải nghiệm miễn phí các nội dung khác tại các mục như Phim Truyện, Thiếu Nhi, KaraTivi, Thể Thao...
            hoặc tham gia chương trình trò chơi tương tác Chơi Hay Chia.</div>
        </div>
        <div class="noidung-hinh">
          <img src="assets/images/img-truyenhinhfpt1.png">
        </div>
      </div>
    </div>
    <div class="item2">
      <div class="tieude">
        <img src="assets/images/icon-truyenhinhfpt2.png">
        <div class="tieude-text">TÍNH NĂNG</div>
      </div>
      <div id="accordion">
        <div class="accordion">
          <input id="checkbox1" type="checkbox">
          <div class="card">
            <label for="checkbox1">
              <div class="card-header" data-toggle="collapse" data-target="#truyenhinh">
                <div>Truyền Hình</div>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </div>
            </label>
            <div id="truyenhinh" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body">
                <div class="left">
                  <div class="card-noidung">
                    <mat-icon>check_circle_outline</mat-icon>
                    <div>Với gần 200 kênh truyền hình trong nước và quốc tế với nhiều nội dung vượt trội,
                      trong đó có 70 kênh chất lượng chuẩn HD, khách hàng có thể dễ dàng lựa chọn các nội dung khác nhau phù hợp với sở thích.
                      Các kênh truyền hình được chia thành các nhóm như Nhóm kênh Thiếu nhi:
                      Cbeebies, Cartoon Network HD, Disney Junior, Disney Channel...</div>
                  </div>
                  <div class="card-noidung">
                    <mat-icon>check_circle_outline</mat-icon>
                    <div>Nhóm kênh phim truyện Hollywood, Âu Mỹ, Châu Á: HBO, FOX Movies, Red by HBO, Cinemax, Fox Family Movies....</div>
                  </div>
                  <div class="card-noidung">
                    <mat-icon>check_circle_outline</mat-icon>
                    <div>Nhóm kênh Giải trí tổng hợp (thể thao, thời trang, âm nhạc...): AXN HD, Fox HD, Discovery, Blue Ant Extreme, Fox Sport HD...</div>
                  </div>
                  <div class="card-noidung">
                    <mat-icon>check_circle_outline</mat-icon>
                    <div>Ngoài ra Truyền hình FPT còn cung cấp gói kênh K+ với nhiều nội dung thể thao, giải trí hấp dẫn.</div>
                  </div>
                </div>
                <div class="right">
                  <div class="right-tieude">Những tính năng thông minh khi xem truyền hình:</div>
                  <div class="card-noidung">
                    <mat-icon>check_circle_outline</mat-icon>
                    <div>
                      <em>Truyền hình xem lại:  </em> Xem lại chương trình đã phát sóng trước đó lên đến 48h.
                    </div>
                  </div>
                  <div class="card-noidung">
                    <mat-icon>check_circle_outline</mat-icon>
                    <div>
                      <em>Lịch phát sóng điện tử EPG:</em>
                      Xem lịch và cài đặt hẹn giờ cho chương trình muốn xem.
                    </div>
                  </div>
                  <div class="card-noidung">
                    <mat-icon>check_circle_outline</mat-icon>
                    <div>
                      <em>Tùy chỉnh ngôn ngữ:</em>
                      Cho phép người dùng bật /tắt thuyết minh, phụ đề tiếng Việt hoặc ngôn ngữ gốc của nhiều kênh truyền hình quốc tế.
                    </div>
                  </div>
                  <div class="card-noidung">
                    <mat-icon>check_circle_outline</mat-icon>
                    <div>
                      <em>Giám sát nội dung trẻ em:</em>
                      Giúp cha mẹ dễ dàng thiết lập và quản lý nội dung xem của trẻ theo từng độ tuổi thông qua mật khẩu.
                    </div>
                  </div>
                  <div class="card-noidung">
                    <mat-icon>check_circle_outline</mat-icon>
                    <div>
                      <em>Điều khiển Tivi bằng giọng nói:</em>
                      Cài đặt app FPT Remote trên thiết bị di động Android hoặc iOS có kết nối internet để điều khiển tivi qua màn hình cảm ứng và giọng nói.
                      FPT Remote có thể thay thế hoàn toàn các chức năng của điều khiển truyền thống.
                    </div>
                  </div>
                  <div class="card-noidung">
                    <mat-icon>check_circle_outline</mat-icon>
                    <div>
                      <em>Lưu yêu thích:</em>
                      là tính năng giúp khán giả có thể lưu các kênh, các chương trình mà mình yêu thích.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion">
          <input id="checkbox2" type="checkbox">
          <div class="card">
            <label for="checkbox2">
              <div class="card-header" data-toggle="collapse" data-target="#mucphimtruyen">
                <div>Mục Phim Truyện</div>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </div>
            </label>
            <div id="mucphimtruyen" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body">
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Kho phim truyện khổng lồ với hàng nghìn đầu phim điện ảnh và truyền hình trong và ngoài nước.</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Đa dạng và đặc sắc từ nội dung tới thể loại, được phân nhóm để tiện tìm kiếm và theo dõi.</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Cập nhật nhanh chóng, hàng ngày các bộ phim hot,
                    đa dạng thể loại từ hành động, kinh dị, tâm lý, hoạt hình, tài liệu,...
                    đến bộ Hàn Quốc, bộ Việt Nam, bộ Hoa Ngữ, bộ Âu Mỹ…</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Tính năng giám sát trẻ em giúp quản lý nội dung an toàn cho trẻ,
                    tính năng tùy chọn ngôn ngữ thuyết minh, phụ đề…</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion">
          <input id="checkbox3" type="checkbox">
          <div class="card">
            <label for="checkbox3">
              <div class="card-header" data-toggle="collapse" data-target="#mucthieunhi">
                <div>Mục Thiếu Nhi</div>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </div>
            </label>
            <div id="mucthieunhi" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body">
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Kho phim truyện khổng lồ với hàng nghìn đầu phim điện ảnh và truyền hình trong và ngoài nước.</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Đa dạng và đặc sắc từ nội dung tới thể loại, được phân nhóm để tiện tìm kiếm và theo dõi.</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Cập nhật nhanh chóng, hàng ngày các bộ phim hot,
                    đa dạng thể loại từ hành động, kinh dị, tâm lý, hoạt hình, tài liệu,...
                    đến bộ Hàn Quốc, bộ Việt Nam, bộ Hoa Ngữ, bộ Âu Mỹ…</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Tính năng giám sát trẻ em giúp quản lý nội dung an toàn cho trẻ,
                    tính năng tùy chọn ngôn ngữ thuyết minh, phụ đề…</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion">
          <input id="checkbox4" type="checkbox">
          <div class="card">
            <label for="checkbox4">
              <div class="card-header" data-toggle="collapse" data-target="#mucgiaitri">
                <div>Mục Giải Trí</div>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </div>
            </label>
            <div id="mucgiaitri" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body">
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Kho phim truyện khổng lồ với hàng nghìn đầu phim điện ảnh và truyền hình trong và ngoài nước.</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Đa dạng và đặc sắc từ nội dung tới thể loại, được phân nhóm để tiện tìm kiếm và theo dõi.</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Cập nhật nhanh chóng, hàng ngày các bộ phim hot,
                    đa dạng thể loại từ hành động, kinh dị, tâm lý, hoạt hình, tài liệu,...
                    đến bộ Hàn Quốc, bộ Việt Nam, bộ Hoa Ngữ, bộ Âu Mỹ…</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Tính năng giám sát trẻ em giúp quản lý nội dung an toàn cho trẻ,
                    tính năng tùy chọn ngôn ngữ thuyết minh, phụ đề…</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion">
          <input id="checkbox5" type="checkbox">
          <div class="card">
            <label for="checkbox5">
              <div class="card-header" data-toggle="collapse" data-target="#mucphim">
                <div>Mục Xem Phim Theo Yêu Cầu Galaxy Play, Danet</div>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </div>
            </label>
            <div id="mucphim" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body">
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Kho phim truyện khổng lồ với hàng nghìn đầu phim điện ảnh và truyền hình trong và ngoài nước.</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Đa dạng và đặc sắc từ nội dung tới thể loại, được phân nhóm để tiện tìm kiếm và theo dõi.</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Cập nhật nhanh chóng, hàng ngày các bộ phim hot,
                    đa dạng thể loại từ hành động, kinh dị, tâm lý, hoạt hình, tài liệu,...
                    đến bộ Hàn Quốc, bộ Việt Nam, bộ Hoa Ngữ, bộ Âu Mỹ…</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Tính năng giám sát trẻ em giúp quản lý nội dung an toàn cho trẻ,
                    tính năng tùy chọn ngôn ngữ thuyết minh, phụ đề…</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion">
          <input id="checkbox6" type="checkbox">
          <div class="card">
            <label for="checkbox6">
              <div class="card-header" data-toggle="collapse" data-target="#muctructiep">
                <div>Mục Trực Tiếp</div>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </div>
            </label>
            <div id="muctructiep" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body">
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Kho phim truyện khổng lồ với hàng nghìn đầu phim điện ảnh và truyền hình trong và ngoài nước.</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Đa dạng và đặc sắc từ nội dung tới thể loại, được phân nhóm để tiện tìm kiếm và theo dõi.</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Cập nhật nhanh chóng, hàng ngày các bộ phim hot,
                    đa dạng thể loại từ hành động, kinh dị, tâm lý, hoạt hình, tài liệu,...
                    đến bộ Hàn Quốc, bộ Việt Nam, bộ Hoa Ngữ, bộ Âu Mỹ…</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Tính năng giám sát trẻ em giúp quản lý nội dung an toàn cho trẻ,
                    tính năng tùy chọn ngôn ngữ thuyết minh, phụ đề…</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion">
          <input id="checkbox7" type="checkbox">
          <div class="card">
            <label for="checkbox7">
              <div class="card-header" data-toggle="collapse" data-target="#mucungdung">
                <div>Mục Ứng Dụng</div>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </div>
            </label>
            <div id="mucungdung" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body">
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Kho phim truyện khổng lồ với hàng nghìn đầu phim điện ảnh và truyền hình trong và ngoài nước.</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Đa dạng và đặc sắc từ nội dung tới thể loại, được phân nhóm để tiện tìm kiếm và theo dõi.</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Cập nhật nhanh chóng, hàng ngày các bộ phim hot,
                    đa dạng thể loại từ hành động, kinh dị, tâm lý, hoạt hình, tài liệu,...
                    đến bộ Hàn Quốc, bộ Việt Nam, bộ Hoa Ngữ, bộ Âu Mỹ…</div>
                </div>
                <div class="card-noidung">
                  <mat-icon>check_circle_outline</mat-icon>
                  <div>Tính năng giám sát trẻ em giúp quản lý nội dung an toàn cho trẻ,
                    tính năng tùy chọn ngôn ngữ thuyết minh, phụ đề…</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
