import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quytrinhtuyendung',
  templateUrl: './quytrinhtuyendung.component.html',
  styleUrls: ['./quytrinhtuyendung.component.css']
})
export class QuytrinhtuyendungComponent implements OnInit {
  listquytrinh = listQuytrinh;

  constructor() { }

  ngOnInit(): void {
  }

}
export const listQuytrinh = [
  {
    icon: 'assets/images/icon_quytrinhtuyendung1.png',
    tieude: 'Nộp đơn',
    noidung: 'Ứng viên gửi CV ứng tuyển tại website: phuongnamtelecom.com hoặc trực tiếp qua app MyTINPNC.'
  },
  {
    icon: 'assets/images/icon_quytrinhtuyendung2.png',
    tieude: 'Sàng lọc hồ sơ',
    noidung: 'PNC Telecom sẽ chỉ phản hồi những ứng viên vượt qua vòng sàng lọc. Ứng viên được liên hệ qua: điện thoại, mail hoặc SMS tối đa trong vòng 15 ngày làm việc kể từ ngày nộp đơn. Ứng viên điền thông tin trước khi đến phỏng vấn theo form trong thư mời phỏng vấn.'
  },
  {
    icon: 'assets/images/icon_quytrinhtuyendung3.png',
    tieude: 'Test',
    noidung: 'Một số vị trí sẽ có bài test online trên app MyTINPNC theo quy định. Với những ứng viên được liên hệ tại vòng sàng lọc sẽ được Bộ phận Tuyển dụng hướng dẫn và hẹn lịch thi test cụ thể. '
  },
  {
    icon: 'assets/images/icon_quytrinhtuyendung4.png',
    tieude: 'Phỏng Vấn',
    noidung: 'Hình thức phỏng vấn: online, trực tiếp. Tùy theo vị trí ứng tuyển sẽ có số vòng phỏng vấn khác nhau: từ 1 - 2 vòng. '
  },
  {
    icon: 'assets/images/icon_quytrinhtuyendung5.png',
    tieude: 'Thông báo kết quả',
    noidung: 'Ứng viên nhận kết quả trong vòng 7 ngày làm việc, kể từ ngày phỏng vấn trên app MyTINPNC.'
  },
  {
    icon: 'assets/images/icon_quytrinhtuyendung6.png',
    tieude: 'Nhận Việc',
    noidung: 'Ứng viên trúng tuyển sẽ được liên hệ để thông báo offer và hướng dẫn hồ sơ nhận việc trên app MyTINPNC.'
  },
];
