import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dv-phanmem',
  templateUrl: './dv-phanmem.component.html',
  styleUrls: ['./dv-phanmem.component.css']
})
export class DvPhanmemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
