<div>
  <div class="gioithieu">
    <div class="thanhchon">
      <a routerLink="gioi-thieu-cong-ty/cong-ty" data-aos="fade-right"><div class="item1" *ngIf="isShowGioithieu1">
<!--        <img class="background" src="assets/images/background_thanhloc.png">-->
        <div class="item1-inner">
          <div class="number"><img src="assets/images/icon-so1.png"></div>
          <div class="name">Giới thiệu công ty</div>
          <div class="circle">
            <img class="logo" src="assets/images/logo_thanhloc1.png">
          </div>
        </div>
      </div></a>
      <a routerLink="so-do" data-aos="fade-left"><div class="item2" *ngIf="isShowGioithieu2">
<!--        <img class="background" src="assets/images/background_thanhloc.png">-->
        <div class="item2-inner">
          <div class="number"><img src="assets/images/icon-so2.png"></div>
          <div class="name">Giới thiệu phòng ban</div>
          <div class="circle">
            <img class="logo" src="assets/images/logo_thanhloc2.png">
          </div>
        </div>
      </div></a>
    </div>
  </div>
  <div class="gioithieu-cty">
    <div class="noidung">
      <a routerLink="cong-ty" data-aos="fade-up"><div class="item"*ngIf="isShowGioithieucty">Giới Thiệu Công Ty</div></a>
      <a routerLink="tong-quan" data-aos="fade-up"><div class="item"*ngIf="isShowTongquan">Tổng Quan Công Ty</div></a>
      <a routerLink="lich-su-hinh-thanh" data-aos="fade-up"><div class="item"*ngIf="isShowLichsu">Lịch Sử Hình Thành</div></a>
      <!--    <div id="lichsu-ht" class="item" routerLink="lich-su-hinh-thanh" (click)="isActive($event)">Lịch Sử Hình Thành</div>-->
    </div>
  </div>
</div>
