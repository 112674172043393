<div class="gioithieu">
<!--  <img class="sodo_bgd" src="assets/images/sodo_bgd.png">-->
  <div class="thanhchon">
    <a routerLink="gioi-thieu-cong-ty/cong-ty" data-aos="fade-right" [ngClass]="chosen === 1 ? 'is-active': ''" (click)="chosen = 1"><div class="item1">
<!--      <img class="background" src="assets/images/background_thanhloc.png">-->
      <div class="item1-inner">
          <div class="number"><img src="assets/images/icon-so1.png"></div>
          <div class="name">Giới thiệu công ty</div>
          <div class="circle">
            <img class="logo" src="assets/images/logo_thanhloc1.png">
          </div>
        </div>
    </div></a>
    <a routerLink="so-do" data-aos="fade-left" [ngClass]="chosen === 2 ? 'is-active': ''" (click)="chosen = 2"><div class="item2">
<!--      <img class="background" src="assets/images/background_thanhloc.png">-->
      <div class="item2-inner">
          <div class="number"><img src="assets/images/icon-so2.png"></div>
          <div class="name">Giới thiệu phòng ban</div>
          <div class="circle">
            <img class="logo" src="assets/images/logo_thanhloc2.png">
          </div>
        </div>
    </div></a>
  </div>
</div>
<router-outlet></router-outlet>
