<div class="l-container">
<!--  <app-img-carousel [imageList]="imgList"></app-img-carousel>-->
  <div class="sanpham-dichvu" id="sanpham-dichvu">
    <div class="thanhchon" id="thanhchon">
      <div class="thanhchon-inner">
        <div id="carousel_head" class="carousel slide" data-ride="carousel">

          <!-- Indicators -->
          <ul class="carousel-indicators">
            <!--          <li data-target="#carousel_head" data-slide-to="0" class="active"></li>-->
            <!--          <li data-target="#carousel_head" data-slide-to="1"></li>-->
            <!--          <li data-target="#carousel_head" data-slide-to="2"></li>-->
          </ul>
          <!-- The slideshow -->
          <div class="carousel-inner">
            <div class="carousel-item active"
                 [ngClass]="selectedDichvu === 1 ? 'is-active1'
                          : selectedDichvu === 2 ? 'is-active2'
                          : selectedDichvu === 3 ? 'is-active3' : ''">
              <div *ngFor="let i of danhsachDichvu">
                <a routerLink="{{i.duongdan}}" id="dichvu{{i.stt}}" (click)="selectDichvu(i.stt)">
                  <div class="dichvu">
                    <div class="border-hinh">
                      <img class="hinh" src="assets/images/{{i.img}}">
                      <img class="hinh_hover" src="assets/images/{{i.img_hover}}"></div>
                    <div class="tieude"><pre>{{i.name}}</pre></div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <!-- Left and right controls -->
          <a class="carousel-control-prev" href="#carousel_head" data-slide="prev">
            <!--          <span class="carousel-control-prev-icon"></span>-->
          </a>
          <a class="carousel-control-next" href="#carousel_head" data-slide="next">
            <!--          <span class="carousel-control-next-icon"></span>-->
          </a>
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
  <!--<div class="sp-dichvu">-->
  <!--  <div class="sp-dichvu-inner">-->
  <!--    <div class="tieude">SẢN PHẨM -DỊCH VỤ</div>-->
  <!--    <div class="noidung">FTTH hiện đang là công nghệ kết nối viễn thông tiên tiến nhất trên thế giới,-->
  <!--      với đường truyền dẫn hoàn toàn bằng cáp quang từ nhà cung cấp dịch vụ tới tận địa điểm của khách hàng.-->
  <!--      Công nghệ này sở hữu tính năng ưu việt:-->
  <!--      với tốc độ truyền tải dữ liệu Internet xuống/lên (download/upload) ngang bằng nhau.</div>-->
  <!--    <div class="danhsach">-->
  <!--      <div class="item1" routerLink="trienkhai-baotri">-->
  <!--        <div class="hinh">-->
  <!--          <img src="assets/images/img-sanphamdichvu1.png">-->
  <!--          <div class="name">DỊCH VỤ TRIỂN KHAI BẢO TRÌ INTERNET FPT</div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="item2" routerLink="truyenhinhfpt">-->
  <!--        <div class="hinh">-->
  <!--          <img src="assets/images/img-sanphamdichvu2.png">-->
  <!--          <div class="name">DỊCH VỤ TRUYỀN HÌNH FPT</div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="item3" routerLink="fptplay">-->
  <!--        <div class="hinh">-->
  <!--          <img src="assets/images/img-sanphamdichvu3.png">-->
  <!--          <div class="name">FPT PLAY</div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="item4" routerLink="fptcamera">-->
  <!--        <div class="hinh">-->
  <!--          <img src="assets/images/img-sanphamdichvu4.png">-->
  <!--          <div class="name">FPT CAMERA</div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="item5" routerLink="fptihome">-->
  <!--        <div class="hinh">-->
  <!--          <img src="assets/images/img-sanphamdichvu5.png">-->
  <!--          <div class="name">FPT IHOME</div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--</div>-->
</div>
