<div class="daotao">
  <div class="daotao-inner">
    <div class="tieude-tong">MỘT SỐ CHƯƠNG TRÌNH ĐÀO TẠO TẠI PNC</div>
    <div class="item">
      <div class="noidung"  *ngFor="let i of newsList | paginate: { itemsPerPage: 6, currentPage: p }">
        <img [src]="i.img | safeResourceUrl" />
        <div class="tieude"><a>{{i.tieu_de}}</a></div>
        <div class="tomtat">{{i.tom_tat}}</div>
        <div class="xemthem">Xem Thêm</div>
      </div>
    </div>
    <pagination-controls class="phantrang" (pageChange)="p = $event" previousLabel="Trước" nextLabel="Sau" [responsive]=true></pagination-controls>
  </div>
</div>

