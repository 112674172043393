<div *ngIf="!mobile" class="l-container">
<!--  <header id="header0">-->
<!--    <div class="head">-->
<!--      <nav class="nav-menu-container1">-->
<!--        <div class="nav-menu">-->
<!--          <div class="nav-item">-->
<!--&lt;!&ndash;            <img src="assets/images/icon-navigation1.png">&ndash;&gt;-->
<!--            <div class="icon"><img src="assets/images/icon-navigation1.svg"></div>-->
<!--            <div>028 7300 2222</div>-->
<!--          </div>-->
<!--          <div class="nav-item">-->
<!--            <div class="icon"><img src="assets/images/icon-navigation2.svg"></div>-->
<!--            <div>130 Đường số 40, Tân Phong, Quận 7, Thành phố Hồ Chí Minh</div>-->
<!--          </div>-->
<!--          <div class="nav-item">-->
<!--            <div class="icon"><img src="assets/images/icon-navigation3.svg"></div>-->
<!--            <div>phuongnam.tuyendung@fpt.net</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </nav>-->
<!--      <nav class="nav-menu-container2">-->
<!--        <div class="nav-menu">-->
<!--&lt;!&ndash;          <div class="nav-item"><a class="nav-link" routerLink="dao-tao" [ngClass]="chosen === 7 ? 'is-active': ''" (click)="chosen = 7">Đào tạo</a></div>&ndash;&gt;-->
<!--          <div class="nav-item"><a class="nav-link" href="https://mytinpnc.vn/home" target="_blank">Nội bộ</a></div>-->
<!--          <div class="nav-item"><a class="nav-link" routerLink="tuyen-dung/ung-tuyen"  [ngClass]="chosen === 9 ? 'is-active': ''" (click)="chosen = 9">Ứng tuyển</a></div>-->
<!--          <div><img src="assets/images/icon-navegation4.svg"></div>-->
<!--        </div>-->
<!--      </nav>-->
<!--    </div>-->
<!--  </header>-->
  <header id="header">
    <div class="head">
      <div id="logo">
        <a routerLink="home" target="_top" class="img"><img src="assets/images/logo_pnc.png" alt="" title=""/></a>
      </div>
      <nav id="nav-menu-container">
        <ul class="nav-menu">
            <li class="nav-item" id="home" [ngClass]="chosen === 1 ? 'is-active': ''" (click)="chosen = 1" routerLink="home">
              <a class="nav-link">TRANG CHỦ</a></li>
            <li  class="nav-item dropdown dropdown2" id="gioi-thieu" [ngClass]="chosen === 2 ? 'is-active': ''" (click)="chosen = 2" routerLink="gioi-thieu/gioi-thieu-cong-ty/cong-ty">
              <a class="nav-link" id="navbarDropdown2" role="button">
                GIỚI THIỆU
              </a>
            </li>
            <li id="san-pham-dich-vu" [ngClass]="chosen === 3 ? 'is-active': ''" (click)="chosen = 3" routerLink="san-pham-dich-vu/dv-khacphucsuco" class="nav-item">
              <a class="nav-link">SẢN PHẨM - DỊCH VỤ</a></li>
            <li id="tin-tuc-su-kien" [ngClass]="chosen === 4 ? 'is-active': ''" (click)="chosen = 0"  class="nav-item dropdown dropdown2" >
              <a class="nav-link" id="navbarDropdown4" role="button">
                TIN TỨC - SỰ KIỆN
              </a>
              <div class="dropdown-menu dropdown-menu2" aria-labelledby="navbarDropdown4">
                <a class="dropdown-item" routerLink="tin-tuc-su-kien/tintuc" >Tin tức</a>
                <a class="dropdown-item" routerLink="tin-tuc-su-kien/sukien" >Sự kiện</a>
              </div>
            </li>
            <li id="tuyen-dung" [ngClass]="chosen === 5 ? 'is-active': ''" (click)="chosen = 0"   class="nav-item dropdown dropdown2">
              <a class="nav-link" id="navbarDropdown3" role="button">
                TUYỂN DỤNG
              </a>
              <div class="dropdown-menu dropdown-menu2" aria-labelledby="navbarDropdown3">
                <a class="dropdown-item" routerLink="tuyen-dung/moi-truong">Môi trường - chính sách đãi ngộ</a>
                <a class="dropdown-item" routerLink="tuyen-dung/co-hoi">Cơ hội nghề nghiệp</a>
                <a class="dropdown-item" routerLink="tuyen-dung/quy-trinh-tuyen-dung">Quy trình tuyển dụng</a>
<!--                <a class="dropdown-item" routerLink="tuyen-dung/cau-chuyen-tuyen-dung">Câu chuyện tuyển dụng</a>-->
              </div>
            </li>
          <li id="lien-he" [ngClass]="chosen === 6 ? 'is-active': ''" (click)="chosen = 6" routerLink="lien-he" class="nav-item"><a class="nav-link">LIÊN HỆ</a></li>
          <li id="ung-tuyen" [ngClass]="chosen === 9 ? 'is-active': ''" (click)="chosen = 9" routerLink="tuyen-dung/ung-tuyen" class="nav-item"><a class="nav-link">ỨNG TUYỂN</a></li>
          </ul>
      </nav>
    </div>
  </header>
  <header id="header-mobile">
    <div class="head">
<!--      <div class="lgMenu" (click)="toggleMenu()">&#9776;</div>-->
      <div class="lgMenu" (click)="toggleMenu()">
        <div><img src="assets/images/icon-homemoblie1.svg"></div>
      </div>
      <div id="logo-mobile">
        <a routerLink="home" target="_top" class="img"><img src="assets/images/logo_pnc.png" alt="" title=""/></a>
      </div>
    </div>
    <div [@slideInOut]="menuState" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" (click)="toggleMenu()"><img src="assets/images/icon-homemoblie10.svg"></a>
      <div class="sidenav-inner">
        <div class="tieude">
          <div class="tieude-inner">
            <div><img src="assets/images/logo_pnc.png" alt="" title=""/></div>
            <div>
              <img src="assets/images/icon-homemoblie2.svg">
              <div>02873002222</div>
            </div>
          </div>
          <form action="">
            <div class="p-1 bg-light rounded rounded-pill shadow-sm mb-4">
              <div class="input-group">
                <input type="search" placeholder="" aria-describedby="button-addon1" class="form-control border-0 bg-light">
                <div class="input-group-append">
                  <button id="button-addon1" type="submit" class="btn btn-link text-primary"><img src="assets/images/icon-homemoblie9.svg"></button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div id="accordion">
          <div class="accordion">
            <input id="checkbox1" name="luachon" type="radio">
            <div class="card">
              <label for="checkbox1">
                <div class="card-header" data-toggle="collapse">
                  <a routerLink="home" (click)="toggleMenu()"><div class="module">
                    <div class="icon"><img src="assets/images/icon-homemolbie3.svg"></div>
                    <div class="text">TRANG CHỦ</div>
                  </div></a>
                </div>
              </label>
            </div>
          </div>
          <div class="accordion" (click)="toggleSubMenu($event)">
            <input id="checkbox2" name="luachon" type="radio">
            <div class="card">
              <label for="checkbox2">
                <div class="card-header" data-toggle="collapse" data-target="#gioithieu">
                  <div class="module">
                    <div class="icon"><img src="assets/images/icon-homemolbie4.svg"></div>
                    <div class="text">GIỚI THIỆU</div>
                  </div>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
              </label>
              <div id="gioithieu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <a routerLink="gioi-thieu/gioi-thieu-cong-ty/cong-ty" (click)="toggleMenu()"><div class="card-body">Giới Thiệu Công Ty</div></a>
                <a routerLink="gioi-thieu/gioi-thieu-cong-ty/tong-quan" (click)="toggleMenu()"><div class="card-body">Tổng Quan Công Ty</div></a>
                <a routerLink="gioi-thieu/gioi-thieu-cong-ty/lich-su-hinh-thanh" (click)="toggleMenu()"><div class="card-body">Lịch Sử Hình Thành</div></a>
                <a routerLink="gioi-thieu/so-do"><div class="card-body" (click)="toggleMenu()">Giới Thiệu Phòng Ban</div></a>
              </div>
            </div>
          </div>
          <div class="accordion" (click)="toggleSubMenu($event)">
            <input id="checkbox3" name="luachon" type="radio">
            <div class="card">
              <label for="checkbox3">
                <div class="card-header" data-toggle="collapse" data-target="#sp-dv">
                  <div class="module">
                    <div class="icon"><img src="assets/images/icon-homemolbie5.svg"></div>
                    <div class="text">SẢN PHẨM - DỊCH VỤ</div>
                  </div>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
              </label>
              <div id="sp-dv" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <a routerLink="/san-pham-dich-vu/dv-khacphucsuco"><div class="card-body" (click)="toggleMenu()">Triển Khai Bảo Trì Hệ Thống Mạng</div></a>
                <a routerLink="/san-pham-dich-vu/dv-onsite"><div class="card-body" (click)="toggleMenu()">Sửa Chữa Các Thiết Bị Công Nghệ</div></a>
                <a routerLink="/san-pham-dich-vu/dv-phanmem"><div class="card-body" (click)="toggleMenu()">Cài Đặt Phần Mềm</div></a>
              </div>
            </div>
          </div>
          <div class="accordion" (click)="toggleSubMenu($event)">
            <input id="checkbox4" name="luachon" type="radio">
            <div class="card">
              <label for="checkbox4">
                <div class="card-header" data-toggle="collapse" data-target="#tintuc-sukien">
                  <div class="module">
                    <div class="icon"><img src="assets/images/icon-homemolbie6.svg"></div>
                    <div class="text">TIN TỨC - SỰ KIỆN</div>
                  </div>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
              </label>
              <div id="tintuc-sukien" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <a routerLink="tin-tuc-su-kien/tintuc" (click)="toggleMenu()"><div class="card-body">Tin Tức</div></a>
                <a routerLink="tin-tuc-su-kien/sukien" (click)="toggleMenu()"><div class="card-body">Sự Kiện</div></a>
              </div>
            </div>
          </div>
          <div class="accordion" (click)="toggleSubMenu($event)">
            <input id="checkbox5" name="luachon" type="radio">
            <div class="card">
              <label for="checkbox5">
                <div class="card-header" data-toggle="collapse" data-target="#tuyendung">
                  <div class="module">
                    <div class="icon"><img src="assets/images/icon-homemolbie7.svg"></div>
                    <div class="text">TUYỂN DỤNG</div>
                  </div>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
              </label>
              <div id="tuyendung" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <a routerLink="tuyen-dung/moi-truong" (click)="toggleMenu()"><div class="card-body">Môi Trường - Chính Sách Đãi Ngộ</div></a>
                <a routerLink="tuyen-dung/co-hoi" (click)="toggleMenu()"><div class="card-body">Cơ Hội Nghề Nghiệp</div></a>
                <a routerLink="tuyen-dung/quy-trinh-tuyen-dung" (click)="toggleMenu()"><div class="card-body">Quy Trình Tuyển Dụng</div></a>
<!--                <a routerLink="tuyen-dung/cau-chuyen-tuyen-dung" (click)="toggleMenu()"><div class="card-body">Câu Chuyện Tuyển Dụng</div></a>-->
              </div>
            </div>
          </div>
          <div class="accordion" (click)="toggleSubMenu($event)">
            <input id="checkbox6" name="luachon" type="radio">
            <div class="card">
              <label for="checkbox6">
                <div class="card-header" data-toggle="collapse">
                  <a routerLink="lien-he" (click)="toggleMenu()"><div class="module">
                    <div class="icon"><img src="assets/images/icon-homemolbie8.svg"></div>
                    <div class="text">LIÊN HỆ</div>
                  </div></a>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="follow">
          <div class="name">FOLLOW ME</div>
          <div class="icon">
            <a href="https://www.youtube.com/channel/UCfw2rSBg9UOdirSEogK0NmA" target="_blank">
              <img src="assets/images/youtube%201.svg">
            </a>
            <a href="https://www.facebook.com/CongtyvienthongPhuongNam" target="_blank">
              <img src="assets/images/facebook%201.svg">
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>
