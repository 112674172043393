<div class="gioithieu-cty">
  <div class="tieude-mobile">
    <app-gioithieu-mobile
      [isShowGioithieu1]="true"
      [isShowGioithieucty]="true">
    </app-gioithieu-mobile>
  </div>
  <div class="content">
    <div class="tieude-content">GIỚI THIỆU CÔNG TY</div>
    <div class="bieudo">
      <div class="bieudo-inner">
        <div class="item">
          <div class="item-inner" data-aos="fade-right">
            <div class="mocthoigian a1">
              <div class="nam aa1">Năm 2010</div>
            </div>
            <div class="item-inner-inner b1">
              <div class="tieude">4 Vùng, 20 Tỉnh và 4 Khu vực tại Tp.Hồ Chí Minh</div>
              <div class="noidung">
                <strong>Vùng 4:</strong> Bình Định, ĐăkLak, Đà Nẵng, Gia Lai, Huế, Phú Yên, Quảng Nam<br>
                <strong>Vùng 5:</strong> Tp.Hồ Chí Minh (4 khu vực)<br>
                <strong>Vùng 6:</strong> Bình Dương, Bình Thuận, Đồng Nai, Lâm Đồng, Tây Ninh<br>
                <strong>Vùng 7:</strong>  An Giang, Bến Tre, Cà Mau, Cần Thơ, Đồng Tháp, Kiên Giang, Tiền Giang, Vĩnh Long.
              </div>
            </div>
          </div>
          <div class="item-inner" data-aos="fade-left">
            <div class="mocthoigian a2">
              <div class="nam aa2">Năm 2011</div>
            </div>
            <div class="item-inner-inner b2">
              <div class="tieude">Thêm 2 Tỉnh</div>
              <div class="noidung">
                <strong>Vùng 4:</strong> Khánh Hòa<br>
                <strong>Vùng 5:</strong> Tp.Hồ Chí Minh (4 Phòng TK&BT): Bình Thạnh, Chợ Lớn, Gia Định, Bến Thành Vùng<br>
                <strong>Vùng 6:</strong> Vũng Tàu
              </div>
            </div>
          </div>
          <div class="item-inner" data-aos="fade-right">
            <div class="mocthoigian a3">
              <div class="nam aa3">Năm 2012</div>
            </div>
            <div class="item-inner-inner b3">
              <div class="tieude">Thêm 6 Tỉnh</div>
              <div class="noidung">
                <strong>Vùng 4:</strong> Quảng Trị, Quảng Bình, Quảng Ngãi<br>
                <strong>Vùng 6:</strong> Ninh Thuận<br>
                <strong>Vùng 7:</strong>  Long An, Sóc Trăng
              </div>
            </div>
          </div>
          <div class="item-inner" data-aos="fade-left">
            <div class="mocthoigian a4">
              <div class="nam aa4">Năm 2013</div>
            </div>
            <div class="item-inner-inner b4">
              <div class="tieude">Thêm 3 Tỉnh</div>
              <div class="noidung">
                <strong>Vùng 4:</strong> Kon Tum<br>
                <strong>Vùng 7:</strong>  Hậu Giang, Bạc Liêu
              </div>
            </div>
          </div>
          <div class="item-inner" data-aos="fade-right">
            <div class="mocthoigian a5">
              <div class="nam aa5">Năm 2014</div>
            </div>
            <div class="item-inner-inner b5">
              <div class="tieude">31 Tỉnh và 4 Khu vực tại TP.Hồ Chí Minh</div>
              <div class="noidung">
              </div>
            </div>
          </div>
          <div class="item-inner" data-aos="fade-left">
            <div class="mocthoigian a6">
              <div class="nam aa6">Năm 2015</div>
            </div>
            <div class="item-inner-inner b6">
              <div class="tieude">29 Tỉnh và 6 Khu vực tại TP.Hồ Chí Minh</div>
              <div class="noidung">
                <strong>Vùng 4:</strong> cắt 4 Tỉnh cho đối tác TIN quản lý: Quảng Bình, Quảng Trị, Thừa Thiên Huế, Đà Nẵng
              </div>
            </div>
          </div>
          <div class="item-inner" data-aos="fade-right">
            <div class="mocthoigian a7">
              <div class="nam aa7">Năm 2017</div>
            </div>
            <div class="item-inner-inner b7">
              <div class="tieude">29 Tỉnh và 8 Khu vực tại TP.Hồ Chí Minh</div>
              <div class="noidung">
              </div>
            </div>
          </div>
          <div class="item-inner" data-aos="fade-left">
            <div class="mocthoigian a8">
              <div class="nam aa8">Năm 2018</div>
            </div>
            <div class="item-inner-inner b8">
              <div class="tieude">29 Tỉnh và 9 Khu vực tại TP.Hồ Chí Minh</div>
              <div class="noidung">
              </div>
            </div>
          </div>

          <div class="item-inner" data-aos="fade-right">
            <div class="mocthoigian a9">
              <div class="nam aa9">Năm 2020</div>
            </div>
            <div class="item-inner-inner b9">
              <div class="tieude">29 Tỉnh và 16 Khu vực thuộc 11 VP Giao dịch tại TP.Hồ Chí Minh</div>
              <div class="noidung">
              </div>
            </div>
          </div>
          <div class="item-inner" data-aos="fade-left">
            <div class="mocthoigian a10">
              <div class="nam aa10">Năm 2022</div>
            </div>
            <div class="item-inner-inner b10">
              <div class="tieude">29 Tỉnh và 15 Khu vực thuộc TP.Hồ Chí Minh</div>
              <div class="noidung">
              </div>
            </div>
          </div>
        </div>
<!--        <div class="item-inner end" data-aos="fade-up">-->
<!--          <div class="mocthoigian a11">-->
<!--            <div class="nam aa11">Năm 2020</div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <div class="item-inner-inner b11">-->
<!--              <div class="tieude">29 Tỉnh và 16 Khu vực thuộc 11 VP Giao dịch tại TP.Hồ Chí Minh</div>-->
<!--              <div class="noidung"></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="bando"><img src="assets/images/background_gioithieucongty.png"></div>
    </div>
  </div>
  <div data-aos="fade-up" class="tm-baotri">
    <app-trungtambaotri></app-trungtambaotri>
  </div>
  <!--  <div class="container" [class.fixed]="fixed">-->
  <!--    <div class="row">-->
  <!--      <div class="col-12">-->
  <!--        <div ngbDropdown class="dropdown custom-dropdown">-->
  <!--          <a class="dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle>Toggle dropdown</a>-->
  <!--          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">-->
  <!--            <a class="dropdown-item">Action - 1</a>-->
  <!--            <a class="dropdown-item">Another Action</a>-->
  <!--            <a class="dropdown-item">Something else is here</a>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</div>
