import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MatTabsModule} from '@angular/material/tabs';
import {TintucComponent} from './tintuc/tintuc.component';
import {SukienComponent} from './sukien/sukien.component';
import {DetailNewsPnComponent} from './detail/detail-news-pn/detail-news-pn.component';
import {DetailNewsTpComponent} from './detail/detail-news-tp/detail-news-tp.component';
import {DetailNewsComponent} from './detail/detail-news/detail-news.component';
import {DetailNewsSkPnComponent} from './detail/detail-news-sk-pn/detail-news-sk-pn.component';
import {DetailNewsTtPnComponent} from './detail/detail-news-tt-pn/detail-news-tt-pn.component';


const routes: Routes = [
  {path: 'tintuc', component: TintucComponent},
  {path: 'sukien', component: SukienComponent},
  // {
  //   path: 'tp', component: DetailNewsComponent,
  //   children: [
  //     {path: ':tin_tuc_id', component: DetailNewsTpComponent},
  //   ],
  // },
  { path: 'tin-tuc', component: DetailNewsTtPnComponent,
    children : [
      {path: ':tin_tuc_id', component: DetailNewsPnComponent},
      {path: 'tp/:tin_tuc_id', component: DetailNewsTpComponent},
    ]},
  { path: 'su-kien', component: DetailNewsSkPnComponent,
    children : [
      {path: ':tin_tuc_id', component: DetailNewsPnComponent},
      {path: 'tp/:tin_tuc_id', component: DetailNewsTpComponent},
    ]}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [
    RouterModule,
    MatTabsModule
  ]
})
export class TintucSukienRoutingModule { }
