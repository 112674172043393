import { Component, OnInit } from '@angular/core';
import {NewsService} from '../../../_services/data/news.service';
import {DataService} from '../../../_services/data/data.service';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  type: any;
  branchUser: any;
  newsList: any;

  constructor(private newsService: NewsService, private dataService: DataService,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    // this.get();
  }
  async get(): Promise<any>{
    this.branchUser = 'cau_chuyen_tuyen_dung';
    this.type = 2;
    // @ts-ignore
    this.newsService.getNewsList(this.branchUser, this.type).subscribe((res) => {
      // console.log(res);
      this.newsList = res;
    });

  }
  trackByFn(index: number, item: any): any {
    return index;
  }
}
