import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {HealthService} from '../../../_services/data/health.service';
import {AddressService} from '../../../_services/data/address.service';

@Component({
  selector: 'app-lienhe',
  templateUrl: './lienhe.component.html',
  styleUrls: ['./lienhe.component.css']
})
export class LienheComponent implements OnInit {
  cityList = [];
  listCity: any;
  selectCity = 'Hồ Chí Minh';
  dataMap: any;
  zoomSize = 6;
  centerPointEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(private healthService: HealthService, private addressService: AddressService) { }
  // selectCity = null;

  ngOnInit(): void {
    this.getCityList();
  }
  getCityList(): any {
    this.addressService.getInfoChinhanh().subscribe((res: any) => {
      this.listCity = res.result.detail.data;
      // console.log(this.listCity);
      this.getDataMap();
      this.listCity.forEach((i: any) => {
        // @ts-ignore
        this.cityList.push(i.label);
      });
      this.cityList = this.cityList.filter((item: any, i: any, arr: any) =>
        arr.findIndex((t: any) => t === item) === i);
    });
  }
  getDataMap(): void{
    const centerPoint = {
      lat: parseFloat(this.listCity[0].latitude),
      long: parseFloat(this.listCity[0].longitude),
    };
    this.centerPointEvent.emit(centerPoint);
    this.listCity.forEach((i: any) => {
      i.latitude = parseFloat(i.latitude);
      i.longitude = parseFloat(i.longitude);
    });
    this.dataMap = this.listCity;
  }

}
