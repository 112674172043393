import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NewsService} from '../../../_services/data/news.service';
import {DataService} from '../../../_services/data/data.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css']
})
export class NewsListComponent implements OnInit {
  newsList: any;
  newsList1: any;
  newsList2: any;
  firstNews: any;
  // @ts-ignore
  @Input() isShowVideo: boolean;
  // @ts-ignore
  @Input() isShowPageHome: boolean;
  // @ts-ignore
  @Input() isShowPageHome1: boolean;
  // @ts-ignore
  @Input() isShowPage: boolean;
  @Input() flexWidth = 'flexWidth100';
  @Input() typeBang: any;
  @Input() mucluc = 'trang_chu';
  routing: any;

  constructor(private newsService: NewsService, private dataService: DataService,
              private router: Router, private route: ActivatedRoute) {
  }
  ngOnInit(): void{
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    if (this.typeBang === 2){
      this.routing = this.mucluc.split('_').join('-');
    }
    this.getNewsList();
  }

  getNewsList(): any {
    this.newsService.getNewsList(this.mucluc, this.typeBang).subscribe((res: any) => {
      this.newsList1 = res.slice(1, 4);
      this.newsList2 = res.slice(4, 7);
      this.newsList = res;
      if (this.typeBang === 1){
        this.newsList = this.newsList.filter((i: any) => i.chi_nhanh === 'PNC');
      }
      if (!this.isShowPageHome) {
        this.newsList = this.newsList.splice(0, 5);
      }
    });
  }

  trackByFn(index: number, item: any): any {
    return index;
  }
}
