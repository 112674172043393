<div class="moitruong">
  <div class="moitruong-inner">
    <div class="item1">
      <div class="tieude" data-aos="fade-up">MÔI TRƯỜNG CHÍNH SÁCH ĐÃI NGỘ</div>
      <div class="noidung" data-aos="fade-up">
        <div class="item1-inner"data-aos="fade-up">
          <p>Công ty TNHH MTV DV Viễn Thông Phương Nam được thành lập ngày 01 tháng 04 năm 2010.
            Hoạt động kinh doanh của doanh nghiệp theo giấy phép kinh doanh số 0309130075 do Sở kế hoạch và
            đầu tư thành phố Hồ Chí Minh cấp ngày 01/03/2010.</p>
          <p>PNC tổ chức các hoạt động truyền thống như team building, khám sức khỏe định kỳ,…
            cùng nhiều chương trình thi đua, hoạt động phong trào thể thao – văn hóa…
            hàng năm nhằm thu hút và khích lệ người PNC luôn nỗ lực không ngừng “Làm được – chơi được”.
            Luôn đảm bảo cho cán bộ nhân viên ngày càng được nâng cao.</p>
          <p class="noibat">Chính sách đãi ngộ của PNC được xây dựng căn cứ trên các tiêu chí:</p>
          <ul>
            <li>Tương xứng với kết quả công việc, giá trị đóng góp cho PNC</li>
            <li>Khuyến khích tăng kết quả và chất lượng công việc</li>
            <li>Công bằng và minh bạch.</li>
          </ul>
        </div>
        <div class="item1-inner" data-aos="fade-up">
          <img src="assets/images/img-moitruong1.jpg">
        </div>
        <div class="item1-inner hinh" data-aos="fade-up">
          <img src="assets/images/img-moitruong2.jpg">
        </div>
        <div class="item1-inner" data-aos="fade-up">
          <div class="noibat">Hệ thống đãi ngộ PNC được phân thành:</div>
          <ul>
              <li> Lương: Lương triển khai bảo trì (12 tháng).</li>
              <li>Thưởng lương tháng thứ 13 tuỳ theo tình hình SXKD của tập đoàn FPT.</li>
              <li> Phụ cấp: cho một số hoạt động phục vụ công việc gồm hỗ trợ chi
                phí đi lại cho nhân viên khi đi công tác theo sự phân công và điều động của công ty…</li>
              <li>Thưởng theo hiệu quả lao động và theo thành tích thực hiện các chương trình thi đua do chính PNC hoặc FPT Telecom tổ chức.</li>
              <li>Phúc lợi xã hội: Bảo hiểm xã hội, bảo hiểm y tế, bảo hiểm thất nghiệp…</li>
            </ul>
        </div>
      </div>
    </div>
    <div class="item2">
      <div class="tieude" data-aos="fade-up">CHĂM SÓC SỨC KHỎE CHO NHÂN VIÊN</div>
      <div class="tomtat" data-aos="fade-up">Chính sách chăm sóc sức khoẻ của PNC được thiết kế đặc biệt để cán
        bộ nhân viên luôn có được thể trạng tốt nhất từ đó phát huy tính sáng tạo,
        nâng cao hiệu quả công việc:</div>
      <div class="noidung" data-aos="fade-up">
        <div class="hinh">
          <img src="assets/images/img-moitruong3.jpg">
        </div>
        <ul class="noidung-inner">
          <li>
            <div class="icon"><img src="assets/images/icon-moitruong1.png"></div>
            <div class="text">Kiểm tra sức khỏe tổng quát cho cán bộ nhân viên PNC định kỳ miễn phí 01 lần trong năm.</div>
          </li>
          <li>
            <div class="icon"><img src="assets/images/icon-moitruong2.png"></div>
            <div class="text">Chương trình bảo hiểm phúc lợi cho cán bộ nhân viên nhằm giảm bớt áp lực về kinh tế
              và được sử dụng các dịch vụ y tế chất lượng cao khi người lao động gặp rủi ro do tai nạn, ốm đau, bệnh tật.</div>
          </li>
          <li>
            <div class="icon"><img src="assets/images/icon-moitruong3.png"></div>
            <div class="text">Khuyến khích cán bộ nhân viên rèn luyện sức khỏe thông qua các phong trào thi đua,
              các giải thi đấu thể dục thể thao hay các câu lạc bộ thể thao trong nội bộ công ty PNC tổ chức.</div>
          </li>
          <li>
            <div class="icon"><img src="assets/images/icon-moitruong4.png"></div>
            <div class="text">Chế độ nghỉ mát nghỉ dưỡng hằng năm (chi phí trong gói thu nhập của cán bộ nhân viên).</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
