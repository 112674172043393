import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noibo',
  templateUrl: './noibo.component.html',
  styleUrls: ['./noibo.component.css']
})
export class NoiboComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
