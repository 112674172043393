import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvironmentsService} from '../environments.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  baseUrl: any;

  constructor(private http: HttpClient, private envService: EnvironmentsService) {
    this.baseUrl = envService.apiUrl;
  }
  getInfoCityDistrict(): any{
    const a = this.http.post<any>(this.baseUrl + '/t_t_tinh', {_type: 'viet_nam'}).toPromise();
    return a;
  }
  getInfoChinhanh(): any{
    const content = {
      name_table: 'get_info_chi_nhanh',
      condition: {
        label: ''
      }
    };
    return this.http.post<any>(this.baseUrl + '/get_info_chi_nhanh', content);
  }
}
