import { Component, OnInit } from '@angular/core';
import {TableName} from '../../../../../_models/elements/tableName';

@Component({
  selector: 'app-detail-news-tp',
  templateUrl: './detail-news-tp.component.html',
  styleUrls: ['./detail-news-tp.component.css']
})
export class DetailNewsTpComponent implements OnInit {
  newsTb = TableName.newsTb;
  constructor() { }

  ngOnInit(): void {
  }

}
