// @ts-ignore

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GioithieuCtyComponent} from './gioithieu-cty/gioithieu-cty.component';
import {SharedModule} from '../../shared/shared.module';
import {GioithieuRoutingModule} from './gioithieu-routing.module';
import {MatIconModule} from '@angular/material/icon';
import {SodoComponent} from './sodo/sodo.component';
import {RouterModule} from '@angular/router';
import { GtCongtyComponent } from './gioithieu-cty/gt-congty/gt-congty.component';
import { LichsuHtComponent } from './gioithieu-cty/lichsu-ht/lichsu-ht.component';
import { TongquanComponent } from './gioithieu-cty/tongquan/tongquan.component';
import { TrungtambaotriComponent } from './gioithieu-cty/shared/trungtambaotri/trungtambaotri.component';
import { RightLichsuHtComponent } from './gioithieu-cty/lichsu-ht/right-lichsu-ht/right-lichsu-ht.component';
import {GioithieuComponent} from './gioithieu.component';
import { GioithieuMobileComponent } from './gioithieu-cty/shared/gioithieu-mobile/gioithieu-mobile.component';


@NgModule({
  declarations: [
    GioithieuCtyComponent,
    SodoComponent,
    GtCongtyComponent,
    LichsuHtComponent,
    TongquanComponent,
    TrungtambaotriComponent,
    RightLichsuHtComponent,
    GioithieuMobileComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    GioithieuRoutingModule,
    MatIconModule,
    SharedModule,
  ],
  exports: [
    TrungtambaotriComponent,
    GioithieuMobileComponent
  ],
})
export class GioithieuModule {
}
