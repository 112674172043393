import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MatTabsModule} from '@angular/material/tabs';
import {FptcameraComponent} from './fptcamera/fptcamera.component';
import {FptihomeComponent} from './fptihome/fptihome.component';
import {FptplayComponent} from './fptplay/fptplay.component';
import {TrienkhaiBaotriComponent} from './trienkhai-baotri/trienkhai-baotri.component';
import {TruyenhinhfptComponent} from './truyenhinhfpt/truyenhinhfpt.component';
import {LayoutDvComponent} from './share/layout-dv/layout-dv.component';
import {DvOnsiteComponent} from './dv-onsite/dv-onsite.component';
import {DvPhanmemComponent} from './dv-phanmem/dv-phanmem.component';
import {DvKhacphucsucoComponent} from './dv-khacphucsuco/dv-khacphucsuco.component';



const routes: Routes = [
  {path: 'fptcamera', component: FptcameraComponent},
  {path: 'fptihome', component: FptihomeComponent},
  {path: 'fptplay', component: FptplayComponent},
  {path: 'trienkhai-baotri', component: TrienkhaiBaotriComponent},
  {path: 'truyenhinhfpt', component: TruyenhinhfptComponent},
  {path: 'dv-onsite', component: DvOnsiteComponent},
  {path: 'dv-phanmem', component: DvPhanmemComponent},
  {path: 'dv-khacphucsuco', component: DvKhacphucsucoComponent},
  {path: 'layout-dv', component: LayoutDvComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [
    RouterModule,
    MatTabsModule
  ]
})
export class SanphamDichvuRoutingModule { }
