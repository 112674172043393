import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dv-khacphucsuco',
  templateUrl: './dv-khacphucsuco.component.html',
  styleUrls: ['./dv-khacphucsuco.component.css']
})
export class DvKhacphucsucoComponent implements OnInit {
  onsite: any;

  constructor() { }

  ngOnInit(): void {
  }

}
