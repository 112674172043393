<app-img-carousel></app-img-carousel>
<div class="tintuc" id="tintuc">
  <div class="tintuc-background">
    <div class="tintuc-inner">
      <div class="left">
        <div class="tieude-tong"*ngIf="tieude">
          <img src="assets/images/icon-tintuc1.svg">
          {{tieude}}</div>
        <div class="item2">
          <div *ngIf="!newsList" class="none-noidung" data-aos="fade-up">Không có tin tức</div>
          <div class="noidung1"  *ngFor="let news of newsList | paginate: { itemsPerPage: 5, currentPage: p }" data-aos="fade-up" >
            <div class="hinh"><img src="{{news.link_hinh_anh}}"></div>
            <div class="thongtin">
              <div class="tieude"><a routerLink="/tin-tuc-su-kien/{{routing}}/tp/{{news.tin_tuc_id}}">{{news.tieu_de}}</a></div>
              <div class="thoigian">
                <img src="assets/images/icon-home4.svg">
                <div>{{news.ngay_dang_bai}}</div>
              </div>
              <div class="tomtat">{{news.tom_tat}}</div>
              <!--              <div class="text">{{news.noidung}}</div>-->
            </div>
          </div>
          <pagination-controls class="phantrang" (pageChange)="onPageChange($event)" previousLabel="Trước" nextLabel="Sau" [responsive]=true></pagination-controls>
        </div>
      </div>
      <div class="right" data-aos="fade-up" *ngIf="newsListPN">
        <div class="item1">
          <div class="item1-tieude">TIN LIÊN QUAN</div>
          <div class="noidung1" *ngFor="let news of newsListPN">
            <div class="hinh"><img src="{{news.link_hinh_anh}}"></div>
            <div class="thongtin">
              <div class="tieude"><a routerLink="/tin-tuc-su-kien/{{routing}}/{{news.tin_tuc_id}}">{{news.tieu_de}}</a></div>
              <div class="thoigian">
                <img src="assets/images/icon-home4.svg">
                <div>{{news.ngay_dang_bai}}</div>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="item2">-->
        <!--          <img src="assets/images/banner-tintuc1.png">-->
        <!--          <img src="assets/images/banner-tintuc2.png">-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</div>
