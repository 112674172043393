import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gt-congty',
  templateUrl: './gt-congty.component.html',
  styleUrls: ['./gt-congty.component.css']
})
export class GtCongtyComponent implements OnInit {
  listTrungtam = [
    {maso: '01', khuvuc: 'khu vực Quận 9', diachi: 'Số 7, Đường số 4, Phường Phú Trung, Quận Tân Phú.' },
    {maso: '02', khuvuc: 'Quận 6, Quận 11, Tân Phú', diachi: '96/1D, Hòa Bình, Phường Phú Trung, Quận Tân Phú.' },
    {maso: '03', khuvuc: 'Tân Bình, Gò Vấp, Quận Phú Nhuận', diachi: '158/7/9 Hoàng Hoa Thám, Phường 12, Quận Tân Bình.' },
    {maso: '04', khuvuc: 'Quận 1, Quận 3, Quận 4', diachi: '124 Sương Nguyệt Ánh, Phường Bến Thành, Quận 1.' },
    {maso: '05', khuvuc: 'Quận 2, Quận Bình Thạnh.', diachi: '32/4 Bùi Đình Túy, Phường 12, Quận Bình Thạnh, TPHCM.' },
    {maso: '06', khuvuc: 'Quận 5, Quận 10, Quận 8', diachi: '66 Đường 218, Cao Lỗ, Phường 4, Quận 8, TPHCM.' },
    {maso: '07', khuvuc: 'khu vưc Củ Chi', diachi: '408 Tỉnh lộ 8, Khu phố 4, Thị trấn Củ Chi, Huyện Củ Chi.' },
    {maso: '08', khuvuc: 'khu vưc Thủ Đức', diachi: '7/5B Lý Tế Xuyên, Phường Linh Đông, Thủ Đức' },
    {maso: '09', khuvuc: 'Khuc Vực Hóc Môn, Quận 12', diachi: '33/2A Nguyễn Thị Búp, Phường Hiệp Thành, Quận 12.' },
    {maso: '10', khuvuc: 'khu vực Bình Chánh, Bình Tân', diachi: '473/20 Lê Văn Quới, Phường Bình Trị Đông A, Quận Bình Tân.\n' },
    {maso: '11', khuvuc: 'khu vực Nhà Bè, Quận 7', diachi: '130 đường 40, Khu dân cư Tân Quy Đông, phường Tân Phong, Quận 7' },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
