<div class="lichsu">
  <div class="tieude-mobile">
    <app-gioithieu-mobile
      [isShowGioithieu1]="true"
      [isShowLichsu]="true">
    </app-gioithieu-mobile>
  </div>
  <div class="noidung-mobile" *ngFor="let i of yearList2">
    <app-right-lichsu-ht [year]="i"></app-right-lichsu-ht>
  </div>
  <div class="lichsu-inner">
    <div class="tieude">LỊCH SỬ HÌNH THÀNH CÔNG TY</div>
    <div class="noidung">
      <div class="noidung-inner">
        <div class="left">
          <div class="icon">
<!--            data-aos="fade-up"-->
            <img src="assets/images/icon-lichsu1.png" >
            <img src="assets/images/icon-lichsu2.png">
            <img src="assets/images/icon_hinhtron2.png">
            <img src="assets/images/icon-lichsu3.png">
            <img src="assets/images/icon_hinhtron1.png">
            <img src="assets/images/icon-lichsu4.png">
            <img src="assets/images/icon_hinhtron3.png">
            <img src="assets/images/icon-lichsu2.png">
            <img src="assets/images/icon_hinhtron2.png">
            <img src="assets/images/icon-lichsu3.png">
            <img src="assets/images/icon_hinhtron1.png">
            <img class="hinh-mobile" src="assets/images/icon-lichsu2.png">
            <img class="hinh-mobile" src="assets/images/icon_hinhtron2.png">
          </div>
          <div class="item">
            <div class="item-inner" *ngFor="let y1 of yearList1" data-aos="fade-up">
              <a (click)="year = y1" href="javascript:void(0)">Năm {{y1}}</a></div>
          </div>
<!--          <div class="item">-->
<!--            <div class="item-inner" *ngFor="let y2 of yearList2" data-aos="fade-up">-->
<!--              <a (click)="year = y2" href="javascript:void(0)">Năm {{y2}}</a></div>-->
<!--          </div>-->
        </div>
        <div class="right" data-aos="fade-up">
          <app-right-lichsu-ht [year]="year"></app-right-lichsu-ht>
        </div>
      </div>
    </div>
  </div>
  <div data-aos="fade-up">
    <app-trungtambaotri></app-trungtambaotri>
  </div>
</div>
