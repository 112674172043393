import { Component, OnInit } from '@angular/core';
import {NewsService} from '../../../_services/data/news.service';

@Component({
  selector: 'app-dao-tao',
  templateUrl: './dao-tao.component.html',
  styleUrls: ['./dao-tao.component.css']
})
export class DaoTaoComponent implements OnInit {
  newsList: any;
  p = 1;

  constructor( private newsService: NewsService) { }

  ngOnInit(): void {
    this.getNewsList();
  }
  getNewsList(): any {
    this.newsService.getNewsList('', 2).subscribe((res: any) => {
      this.newsList = res;
      console.log(this.newsList);
    });
  }

}
