import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NewsService} from 'src/app/_services/data/news.service';
import {DataService} from 'src/app/_services/data/data.service';
import {listCategory, listCategoryEng} from '../../../../_models/elements/newsItem';

@Component({
  selector: 'app-news-pages',
  templateUrl: './news-pages.component.html',
  styleUrls: ['./news-pages.component.css']
})
export class NewsPagesComponent implements OnInit {
  page: any;
  newsList: any;
  // brachUser: any;
  category: any;
  // isShowVideo: boolean;
  listVideo = listVideo;

  constructor(private router: Router, private newsService: NewsService,
              private dataService: DataService) {
    this.router.events.subscribe((event) => {
      // @ts-ignore
      if (event.url) {
        // @ts-ignore
        const a = event.url.split('/')[2];
        const indexOfA = listCategoryEng.indexOf(a);
        this.category = listCategory[indexOfA];
        console.log(this.category);
        this.getSomeNews();
      }
    });
  }

  ngOnInit(): void {
  }
  // tslint:disable-next-line:typedef
  async getSomeNews() {
  }

}

export const listVideo = [
  {
    thumbnail: 'assets/images/hinhvideo/huongdansudungthang.PNG',
    title: 'Hướng dẫn sử dụng thang nhôm',
    url: 'hdsd_thang_nhom',
    des: ''
  },
  {
    thumbnail: 'assets/images/hinhvideo/kysuchuyennghe_1.PNG',
    title: '[Số 1] Ký sự chuyện nghề Phương Nam: PNC - những điều chưa kể',
    url: 'chuyen_nghe_phuong_nam',
    des: `<p> Số đầu tiên của ký sự chuyện nghề với chủ đề “PNC – Những điều chưa kể” sẽ mang đến cho mọi người một nhân vật đặc biệt. Anh Nguyễn Ngọc Tiên – KTV Onsite Phòng KT SG09 đã dành trọn 10 năm thanh xuân của mình để gắn bó với ngôi nhà Phương Nam.
      <br>
      Anh Tiên cũng là một trong những thành viên tiêu biểu khi trở thành Đại sứ Chăm sóc khách hàng với câu chuyện dùng trái tim ấm áp của mình để lan tỏa tình cảm đến với khách hàng đang gặp nhiều tâm sự.
      <br>
      Chúng ta hãy cùng đón xem câu chuyện nghề của chàng KTV nhà Phương Nam có gì đặc biệt nhé! </p>`
  },
  {
    thumbnail: 'assets/images/hinhvideo/sudungtrangphucbaoho.PNG',
    title: 'Sử dụng trang phục bảo hộ y tế trong mùa dịch',
    url: 'su_dung_do_bao_ho', des: ''
  },
  {
    thumbnail: 'assets/images/hinhvideo/gioithieunoibo.PNG',
    title: 'Giới thiệu nội bộ',
    iframe: 'https://mytinpnc.vn/api/auth/videodetail/gioi_thieu_noi_bo?&amp;end=5;rel=0&amp;controls=0',
    url: 'gioi_thieu_noi_bo', des: `<p>ALO... ALO...🔥🔥🔥<br>
      TUYỂN DỤNG ĐANG GỌI... 🙋‍♀️🙋‍♀️<br>
      CẦN LẮM ANH EM PHƯƠNG NAM NGHE RÕ TRẢ LỜI !!! :speaking_head_in_silhouette:🗣<br>
      💥💥Chương trình GIỚI THIỆU NỘI BỘ 2021 chính thức bắt đầu rồi đâyyyy 💥💥<br>
      Anh em mau mau giới thiệu cộng sự nhé!<br>
      Lì xì cực xịn cho: 💰💰💰💰💰<br>
      - Người giới thiệu thành công: nhận 500.000Đ/ ứng viên 💰💰💰💰💰<br>
      - Top 5 chi nhánh giới thiệu thành công từ 5 ứng viên trở lên: nhận 1.000.000Đ/ chi nhánh 💰💰💰💰💰<br>
      Chương trình áp dụng cho tất cả CBNV PNC khi giới thiệu thành công Nhân viên kỹ thuật làm việc tại khu vực TP.HCM (Đội TF)🤝🤝<br>
      _________________________________<br>
      Liên hệ bộ phận Tuyển dụng: :point_down:<br>
      :office: Add: 130 Đường số 40, KDC Tân Quy Đông, phường Tân Phong, Quận 7<br>
      :envelope_with_arrow: Mail: phuongnam.tuyendung@fpt.net<br>
      ☎️ Điện thoại: 028 7300 2222 - EXT: 80954/80955<br>
      📲 Zalo: 034 986 4912 (Minh Anh)<br>
      ✍️ Hoặc điền thông tin giới thiệu trực tiếp vào link: https://docs.google.com/.../1zNq0R-0tebS_TnxhO3.../edit</p>`
  },
  {
    thumbnail: 'assets/images/hinhvideo/dietgiaccorona.PNG',
    title: 'Diệt giặc corona',
    iframe: 'https://mytinpnc.vn/api/auth/videodetail/stco_diet_giac_corona?&amp;end=5;rel=0&amp;controls=0',
    url: 'stco_diet_giac_corona',
    des: `<p>Bài hát này xin gửi tặng đến toàn thể các chiến binh nhà
       Phương Nam trên hành trình "Chống dịch như chống giặc", mỗi chiến binh Phương
      Nam đã luôn hết mình đề cao cảnh giác
      tuân thủ các quy định phòng chống dịch bệnh, bảo vệ bản thân trước dịch Covid-19.</p>`
  },

];
