<div class="lichsu-ht-noidung">
  <div *ngIf="year === 0" class="tongquan">
    <div>Công ty TNHH MTV DV Viễn Thông Phương Nam được thành lập ngày 01 tháng 04 năm 2010.
      Hoạt động kinh doanh của doanh nghiệp theo giấy phép kinh doanh số 0309130075 do Sở kế hoạch
      và đầu tư thành phố Hồ Chí Minh cấp ngày 01/03/2010.</div>
    <div>Năm 2003, FPT Telecom bắt đầu chuyển đổi công nghệ Internet từ Dial Up sang ADSL,
      vừa làm vừa tìm hiểu, rút kinh nghiệm. Tuyển dụng 20 nhân viên kỹ thuật đầu tiên từ trường Trung Cấp Lý Tự Trọng.
      Kéo những tuyến cáp đầu tiên cho các khách hàng ADSL.
    </div>
    <div>Năm 2004 – 2006, FPT Telecom thay đổi, phát triển liên tục cả về công nghệ và quy mô mở rộng ra khắp thành phố Hồ Chí Minh, Hà Nội và toàn quốc.
      Nhân sự phát triển liên tục cả về số lượng và chất lượng,
      hoàn thiện dần quy trình, thành lập các phòng ban, trung tâm kỹ thuật triển khai – bảo trì dịch vụ Internet. </div>
    <div>Năm 2007, FPT Telecom outsource bộ phận triển khai – bảo trì thuê bao cho đối tác, tiền thân của PNC Telecom hiện tại.
      Với sự ra đời của 2 đối tác đầu tiên Hưng Thông và Xa Gần.
      Trong đó Xa Gần là do anh Phan Thế Anh Lân – cựu quản lý kỹ thuật FPT Telecom thành lập. </div>
    <div>Năm 2008, bắt đầu triển khai công nghệ FTTH, các đối tác khác lần lượt ra đời: Siêu Tốc,
      Hà Khuê, Trương Nguyễn, Hợp Nhất, chia nhau hoạt động ở 29 tỉnh thành miền Trung và miền Nam.
      Tất cả đều do các quản lý kỹ thuật FPT Telecom thành lập.</div>
    <div>Sau giai đoạn phát triển liên tục và mạnh mẽ của các công ty đối tác, FPT Telecom đã đến lúc có
      lượng khách hàng đủ lớn cần phải được quản lý theo một mô hình thống nhất và chuyên nghiệp hơn, đó là lý do thành lập
      nên công ty TNHH MTV DV Viễn Thông Phương Nam –
      đối tác độc quyền của FPT Telecom khu vực phía Nam dưới sự quản lý của Giám đốc –
      Phạm Mạnh Hoàng, Trưởng Phòng Kỹ Thuật anh Cáp Xuân Hòa.</div>
  </div>
  <div *ngIf="year === 2010" class="nam_2010">
    <div class="year">Năm 2010</div>
    <div class="noidung">
      <div>
        Ngày 20/4/2010, PNC thu nhận đối tác đầu tiên – Siêu Tốc, với những nhân viên đầu tiên và PNC chính thức hoạt động với công tác triển khai – bảo trì dịch vụ Internet tại địa bàn Thủ Đức.
      </div>
      <div>
        Tháng 7/2010, anh Võ Đình Hảo được bổ nhiệm làm Giám đốc công ty PNC.
      </div>
      <div>
        Mạng lưới hoạt động của Công ty trải dài trên 21 tỉnh thành và 4 khu vực tại Thành phố Hồ Chí Minh:
      </div>
      <div>
        <b>Vùng 4:</b> (7 tỉnh)  Quảng Nam, Huế, Bình Định, Gia Lai, Dắk Lắk, Phú Yên, Đà Nẵng.
      </div>
      <div>
        <b>Vùng 6:</b> (6 tỉnh) Bình Thuận, Lâm Đồng, Đồng Nai, Tây Ninh, Bình Phước, Bình Dương.
      </div>
      <div>
        <b>Vùng 7:</b> (8 tỉnh) An Giang, Bến Tre, Cà Mau, Cần Thơ, Đồng Tháp, Kiên Giang, Tiền Giang, Vĩnh Long.
      </div>
    </div>
    <div class="hinh">
      <img src="assets/images/ban_do/2010.gif"/>
    </div>
  </div>
  <div *ngIf="year === 2011" class="nam_2011">
    <div class="year">Năm 2011</div>
    <div class="noidung">
      <div>
        Trước nhu cầu ngày càng cao của khách hàng, mở rộng vùng phủ và cung cấp dịch vụ Internet
        tốt hơn tại những địa phương mà FPT Telecom đi qua. Đầu năm 2011,
        FPT Telecom khởi công dự án “Đường trục Bắc – Nam” có chiều dài
        4.000 km và xây mới các tuyến cáp trục liên tỉnh, với tổng số gần 1.000.000 km phục vụ cho 36 chi nhánh trên toàn quốc.
      </div>
      <div>
        Nhằm đáp ứng tiến độ dự án phát triển hạ tầng quan trọng của đối tác.
        Ngày 28/2/2011, đối tác cuối cùng – Trương Nguyễn đã xác nhập với PNC,
        PNC đã củng cố và chính thức mở rộng mạng lưới hoạt động ra toàn bộ các tỉnh có văn phòng của
        FPT Telecom lên 2 tỉnh thành và 2 khu vực. Thành phố Hồ Chí Minh,
        chuyển 6 khu vực thành 4 Phòng Triển khai & Bảo Trì.
      </div>
      <div>
        <b>Vùng 5:</b> 6 khu vực – 4 Phòng Triển khai & Bảo Trì: Bình Thạnh, Chợ Lớn, Gia Định, Bến Thành.
      </div>
      <div>
        <b>Vùng 4:</b> thêm tỉnh Khánh Hòa.
      </div>
      <div>
        <b>Vùng 6:</b> thêm tỉnh Vũng Tàu.
      </div>
    </div>
    <div class="hinh">
      <img src="assets/images/ban_do/2011.gif"/>
    </div>
  </div>
  <div *ngIf="year === 2012" class="nam_2012">
    <div class="year">Năm 2012</div>
    <div class="noidung">
      <div>
        Khởi đầu giai đoạn đầy khó khăn của PNC chiến đấu
        với kế hoạch chỉnh trang của Điện Lực thành phố
        Hồ Chí Minh với hàng trăm tuyến đường bị hạ cáp và ngầm hóa.
      </div>
      <div>
        Tháng 9/2012, FPT Telecom hoàn thành mục tiêu đầu tiên của tuyến trục đường Bắc – Nam, không ngừng phủ sóng ở các tỉnh thành,
        mạng lưới hoạt động của PNC cũng được mở rộng ra 6 tỉnh thành nhằm đáp nhu cầu của khách hàng tại các Vùng 4, 6, 7:
      </div>
      <div><b>Vùng 4:</b> thêm vào 3 tỉnh Quảng Trị, Quảng Bình, Quảng Ngãi.</div>
      <div><b>Vùng 6:</b> thêm vào tỉnh Ninh Thuận.</div>
      <div><b>Vùng 7:</b> thêm vào 2 tỉnh Long An, Sóc Trăng.</div>
    </div>
    <div class="hinh"><img src="assets/images/ban_do/2012.gif"></div>
  </div>
  <div *ngIf="year === 2013" class="nam_2013">
    <div class="year">Năm 2013</div>
    <div class="noidung">
      <div>
        Tháng 1/2013, FPT Telecom hoàn thiện tuyến đường trục huyết mạch Bắc –
        Nam xuyên suốt từ Lạng Sơn đến đất mũi Cà Mau, với tổng chiều dài 4000 km. Cùng với
        đó là sự phát triển của PNC Telecom với 4 Phòng Triển khai & Bảo Trì tại
        Thành phố Hồ Chí Minh và trãi dài trên 32 tỉnh thành.
      </div>
      <div><b>Vùng 4:</b> thêm vào tỉnh Kom Tum.</div>
      <div><b>Vùng 7:</b> thêm vào 2 tỉnh Hậu Giang, Bạc Liêu.</div>
    </div>
    <div class="hinh">
      <img src="assets/images/ban_do/2013.gif">
    </div>
  </div>
  <div *ngIf="year === 2014" class="nam_2014">
    <div class="year">Năm 2014</div>
    <div class="noidung">
      <div>FPT Telecom tiến hành triển khai Chiến dịch quang hóa (SWAP) chuyển
        đổi hạ tầng từ cáp đồng sang cáp quang tại hai thành phố lớn là Hà Nội và Tp Hồ Chí Minh,
        nhằm nâng cao chất lượng dịch vụ và mang đến cho khách hàng trải nghiệm công nghệ mới.</div>
      <div>
        Tháng 3/2014, PNC là đơn vị nhận nhiệm vụ thực hiện SWAP – quang hóa chuyển đổi toàn bộ hạ tầng tại khu vực Tp.
        Hồ Chí Minh và các hoạt động ứng cứu,
        hỗ trợ tại các tỉnh thành phía Nam với mạng lưới hoạt động trải dài trên
        32 tỉnh thành kéo dài từ Quảng Bình cho đến Mũi Cà Mau.
      </div>
      <div>
        <b>Vùng 5:</b> Thành phố Hồ Chí Minh với 4 Phòng Triển khai & Bảo Trì.
      </div>
      <div>
        <b>Vùng 4:</b> (12 tỉnh) Quảng Bình, Quảng Trị, Quảng Ngãi,
        Quảng Nam, Huế, Đà Nẵng, Bình Định, Gia Lai, Dắk Lắk, Phú Yên,  Khánh Hòa, Kom Tum.
      </div>
      <div>
        <b>Vùng 6:</b> (8 tỉnh) Ninh Thuận, Bình Thuận, Bình Dương, Bình Phước, Lâm Đồng, Đồng Nai, Tây Ninh, Vũng Tàu.
      </div>
      <div>
        <b>Vùng 7:</b> (12 tỉnh) Long An, Bến Tre,
        Cần Thơ, Đồng Tháp, An Giang, Kiên Giang, Tiền Giang, Hậu Giang, Vĩnh Long, Bạc Liêu, Sóc Trăng, Cà Mau.
      </div>
    </div>
    <div class="hinh">
      <img src="assets/images/ban_do/2014.gif">
    </div>
  </div>
  <div *ngIf="year === 2015" class="nam_2015">
    <div class="year">Năm 2015</div>
    <div class="noidung">
      <div>
        Cắt 4 tỉnh Quãng Bình, Quảng Trị, Thừa Thiên Huế và Đà Nẵng cho Trung tâm Quản lý đối tác miền bắc (TIN).
        PNC quản lý 6 Phòng Triển khai & Bảo Trì tại khu vực thành phố Hồ Chí Minh và 29 tỉnh thành.
      </div>
      <div>
        Tháng 2/2015, anh Phạm Như Hoài Bảo được bổ nhiệm làm Giám đốc PNC,
        quy mô công ty lớn mạnh với gần 3000 nhân viên bao gồm ở các bộ phận khác như INF, CUS, CS.
      </div>
      <div>
        Tháng 10/2015, về cơ bản đã hoàn tất chiến dịch SWAP, quang hóa toàn bộ hạ tầng tại Tp. Hồ Chí Minh.
        Những nỗ lực của PNC trong việc hoàn tất chiến dịch SWAP,
        quang hóa toàn bộ hạ tầng đã được ghi nhận qua việc PNC trở thành đơn vị đạt danh hiệu tập thể xuất sắc nhất FPT Telecom.
      </div>
      <div>
        <b>Vùng 5:</b> 6 Phòng Triển khai & Bảo Trì.
        <b>Vùng 4:</b> cắt 4 tỉnh Quãng Bình, Quảng Trị, Thừa Thiên Huế và Đà Nẵng.
      </div>
    </div>
    <div class="hinh">
      <img src="assets/images/ban_do/2015.gif">
    </div>
  </div>
  <div *ngIf="year === 2016" class="nam_2016">
    <div class="year">Năm 2016</div>
    <div class="noidung">
      <div>
        PNC Telecom quản lý tất cả là 29 chi nhánh tỉnh thành phố trong cả nước kéo dài từ Quãng Nam trở vào mũi Cà Mau.
        Với lực lượng nhân sự trẻ lên đến 2.967 nhân viên với độ tuổi trung bình là 26,71.
        Đảm bảo phục vụ hơn 950.000 khách hàng với hơn 1,8 triệu ca, vụ. Trong đó, tỷ lệ nổi bật nhất là 84,55% ca bảo
        trì được thực hiện trong vòng 2 giờ,
        tăng 46% so với cùng kỳ năm 2014 và tăng 7% so với năm 2015. Trong khi đó, chi phí phục vụ mỗi khách hàng giảm 20%.
      </div>
      <div>
        Thành phố Hồ Chí Minh với 6 Trung tâm Triển khai & Bảo trì:
      </div>
      <div>
        <b>PNC 01:</b> 860/60K Xô Viết Nghệ Tĩnh, phường 25, quận Bình Thạnh
      </div>
      <div>
        <b>PNC 02:</b> 27 Đường 817 Tạ Quang Bửu, phường 5, Quận 8.
      </div>
      <div>
        <b>PNC 03:</b> 158/7/9 Hoàng Hoa Thám, phường 12, quận Tân Bình.
      </div>
      <div>
        <b>PNC 04:</b> 32A, Đường 81, phường Tân Quy, Quận 7
      </div>
      <div>
        <b>PNC 05:</b> 52/16 Đông Hưng Thuận 10, phường Đông Hưng Thuận, Quận 12
      </div>
      <div>
        <b>PNC 06:</b> 702/3E Sư Vạn Hạnh, phường 12, Quận 10
      </div>
    </div>
    <div class="hinh hinh_2016">
      <img src="assets/images/ban_do/2016.png">
    </div>
  </div>
  <div *ngIf="year === 2017" class="nam_2017">
    <div class="year">Năm 2017</div>
    <div class="noidung">
      <div>
        Bước vào 2017, PNC với phương châm “NHANH HƠN –TẬN TÂM HƠN” trong công tác phục vụ khách hàng,
        thông qua việc sử dụng nhiều ứng dụng công nghệ và làm việc sáng tạo không ngừng.
        PNC không ngừng mở rộng quy mô và phạm vi hoạt động trên 29 tỉnh thành Việt Nam,
        riêng khu vực thành phố Hồ Chí Minh với 8 Trung tâm Triển khai & Bảo trì.
        Sở hữu một đội ngũ lên đến 3000 nhân viên, dồi dào sức trẻ (tuổi trung bình là 26.71), nhiệt huyết và tràn đầy khát khao thể hiện.
      </div>
    </div>
  </div>
  <div class="nam_2018" *ngIf="year === 2018">
    <div class="year">Năm 2018</div>
    <div class="noidung">
      <div>
        PNC Telecom đã trở thành một trong những nhà cung cấp dịch vụ triển khai viễn thông và
        Internet hàng đầu trong nước và luôn là đối tác độc quyền đáng tin cậy của FPT Telecom.
        Tại thành phố Hồ Chí Minh,
        mở rộng quy mô hoạt động lên 9 Trung tâm Triển khai & Bảo trì, hệ thống phòng kỹ thuật trãi dài 29 chi nhánh tỉnh.
      </div>
    </div>
    <div class="hinh">
      <img src="assets/images/ban_do/2018.png">
    </div>
  </div>
  <div *ngIf="year === 2019" class="nam_2019">
    <div class="year">Năm 2019</div>
    <div class="noidung"></div>
  </div>
  <div *ngIf="year === 2020" class="nam_2020">
    <div class="year">Năm 2020</div>
    <div class="noidung">
      <div class="tieude">
        29 Tỉnh và 16 khu vực thuộc 10 văn phòng giao dịch tại TPHCM
      </div>
      <ul>
        <li>- 7 Đường số 14, Phường Phước Bình, Quận 9, PNC01.</li>
        <li>- 96/1 D Hòa Bình, P. Phú Trung, Quận Tân Phú: PNC 02 , PNC 14</li>
        <li>- 158/7/9 Hoàng Hoa Thám , P.12, Quận Tân Bình: PNC 03, PNC 13</li>
        <li>- 169/5 Võ Thị Sáu, P.6, Quận 3: PNC 04</li>
        <li>- 699 Bùi Đình Túy, P.12, Quận Bình Thạnh: PNC 05</li>
        <li>- 66 Đường 218 Cao lỗ, P4, Quận 8: PNC 06, PNC 15</li>
        <li>- 128 Đường Huỳnh Thị Hai, Phường Tân Chánh Hiệp, Q.12: PNC 07, PNC 09, PNC 12</li>
        <li>- 7/5B Lý Tế Xuyên, P. Linh Đông, Q. Thủ Đức: PNC 08</li>
        <li>- 473/20 Lê Văn Quới, Phường Bình Trị Đông A, Quận Bình Tân: PNC 10, PNC 11</li>
      </ul>
    </div>
  </div>
  <div *ngIf="year === 2021">
    <div class="year">2010</div>
    <div class="noidung"></div>
  </div>
</div>

