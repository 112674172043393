import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-sukien',
  templateUrl: './sukien.component.html',
  styleUrls: ['./sukien.component.css']
})
export class SukienComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
