import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-gioithieu',
  templateUrl: './gioithieu.component.html',
  styleUrls: ['./gioithieu.component.css']
})
export class GioithieuComponent implements OnInit {
  chosen = 1;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      // @ts-ignore
      if (event.url) {
        // @ts-ignore
        const r = event.url.split('/');
        if (r[r.length - 1] === 'cong-ty') {
          this.chosen = 1;
        }
        else if (r[r.length - 1] === 'so-do') {
          this.chosen = 2;
        }
      }
    });
  }

  ngOnInit(): void {
  }

}
