import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'phuongnamtelecom';
  // @ts-ignore
  private config: {version: string};

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit(): void {}

  refreshCache(): any {
    // @ts-ignore
    this.config = require('./../assets/config.json');

    const headers = new HttpHeaders().set('Cache-Control', 'no-cache').set('Pragma', 'no-cache');
    console.log(this.config);

    this.httpClient.get<{ version: string }>('/assets/config.json', {headers}).subscribe(config => {
      console.log(config);
      if (config.version !== this.config.version) {
        this.httpClient.get('', { headers, responseType: 'text'}).subscribe(() => location.reload());
      }
    });
  }

  onActivate(event: any): void {
    window.scroll(0, 0);
  }
}
