import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trungtambaotri',
  templateUrl: './trungtambaotri.component.html',
  styleUrls: ['./trungtambaotri.component.css']
})
export class TrungtambaotriComponent implements OnInit {
  listTrungtam = [
    {maso: '01', khuvuc: 'Quận 9', diachi: '385 Man Thiện, Phường Tăng Nhơn Phú A, TP.Thủ Đức' },
    {maso: '02', khuvuc: 'Quận 6, Quận 11', diachi: '96/1D Hòa Bình, Phường Phú Trung, Quận Tân Phú' },
    {maso: '03', khuvuc: 'Quận Tân Bình, Quận Phú Nhuận', diachi: '340E-F Hoàng Văn Thụ, Phường 4, Quận Tân Bình' },
    {maso: '04', khuvuc: 'Quận 1, Quận 3, Quận 4', diachi: '124 Sương Nguyệt Ánh, Phường Bến Thành, Quận 1' },
    {maso: '05', khuvuc: 'Quận 2, Quận Bình Thạnh.', diachi: '32/4 Bùi Đình Túy, Phường 12, Quận Bình Thạnh' },
    {maso: '07', khuvuc: 'Củ Chi', diachi: '408 Tỉnh lộ 8, Khu phố 4, Thị trấn Củ Chi, Huyện Củ Chi' },
    {maso: '08', khuvuc: 'Thủ Đức', diachi: '7/5B Lý Tế Xuyên, Phường Linh Đông, Thủ Đức' },
    {maso: '09', khuvuc: 'Quận Hóc Môn', diachi: '33/2A Nguyễn Thị Búp, Phường Hiệp Thành, Quận 12' },
    {maso: '10', khuvuc: 'Bình Chánh', diachi: '473/20 Lê Văn Quới, Phường Bình Trị Đông A, Quận Bình Tân.' },
    {maso: '11', khuvuc: 'Bình Chánh', diachi: '473/20 Lê Văn Quới, Phường Bình Trị Đông A, Quận Bình Tân' },
    {maso: '12', khuvuc: 'Quận 12', diachi: '33/2A Nguyễn Thị Búp, Phường Hiệp Thành, Quận 12' },
    {maso: '13', khuvuc: 'Quận Gò Vấp', diachi: '1015 Phan Văn Trị, Phường 7, Quận Gò Vấp' },
    {maso: '14', khuvuc: 'Quận Tân Phú', diachi: '96/1D Hòa Bình, Phường Phú Trung, Quận Tân Phú' },
    {maso: '15', khuvuc: 'Quận 8', diachi: '42 Dương Quang Đông, Phường 5, Quận 8' },
    {maso: '16', khuvuc: 'Quận 7', diachi: '198 Lâm Văn Bền, Phường Tân Quy, Quận 7' },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
