<div class="lienhe" xmlns="http://www.w3.org/1999/html">
  <div class="item1">
    <div class="gioithieu">
      <div class="gioithieu-content">
        <div class="tencongty">CÔNG TY TNHH MTV DV VIỄN THÔNG PHƯƠNG NAM</div>
        <div class="diachi">
          <img src="assets/images/icon-lienhe2.svg">
          <p><span>Địa chỉ: </span>D4-D4bis-D8 đường Thất Sơn, phường 15, Quận 10, TP. Hồ Chí Minh</p>
        </div>
        <div class="mail">
          <img src="assets/images/icon-lienhe3.svg">
          <p><span>Email: </span>lienhe@fpt.net</p>
        </div>
        <div class="sdt">
          <img src="assets/images/icon-lienhe4.svg">
          <p><span>Điện thoại: </span>028 7300 2222
        </div>
      </div>
    </div>
  </div>
  <div class="item2" data-aos="fade-up">
    <div class="item2-inner">
      <div class="left">
        <div class="thanhloc">
          <ng-select class="custom-ng" [items]="cityList"
                     placeholder="Chọn vùng"
                     [(ngModel)]="selectCity">
          </ng-select>
        </div>
        <div class="noidung">
          <div class="thongtin" *ngFor="let e of listCity">
            <div *ngIf="e.label === selectCity">
              <div>
                <div class="tenchinhanh">PNC TELECOM {{e.ten_chi_nhanh}}<mat-icon>remove</mat-icon>
                </div>
              </div>
              <div class="diachi">{{e.dia_chi_chi_nhanh}}</div>
              <hr *ngIf="e.admin">
              <div *ngIf="e.admin">
                <div class="diachi-inner" *ngFor="let i of e.admin">
<!--                  <div class="admin"><div><img src="assets/images/icon-lienhe5.svg"></div><p>Admin: {{i.ho_ten}}</p></div>-->
                  <div class="mail"><div><img src="assets/images/icon-lienhe3.svg"></div><p>Mail: {{i.email}}</p></div>
                  <div class="sodt"><div><img src="assets/images/icon-lienhe4.svg"></div><p>Phone: {{i.sdt}}</p></div>
                  <div class="mayle"><p *ngIf="i.ext">028 7300 2222 - Máy lẻ: {{i.ext}}</p></div>
                </div>
              </div>
              <hr *ngIf="e.dia_chi_giao_dich.length > 0">
              <div class="list-diachi" *ngIf="e.dia_chi_giao_dich.length > 0">
                <div class="tieude">Danh sách địa chỉ phòng giao dịch:</div>
                <div *ngFor="let o of e.dia_chi_giao_dich">
                  <div class="diachi-gd"><mat-icon>location_on</mat-icon><p>{{o.dia_chi}}</p></div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="right">
        <app-bingmap *ngIf="dataMap" [data]="dataMap"
                     [classOfMap]="'checkinMap'"
                     [centerPointEvent]="centerPointEvent"
                     [zoomSize]="zoomSize">
        </app-bingmap>
      </div>
    </div>
  </div>
</div>

