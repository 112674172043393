<div class="tongquan-cty">
  <div class="tieude-mobile">
    <app-gioithieu-mobile
      [isShowGioithieu1]="true"
      [isShowTongquan]="true">
    </app-gioithieu-mobile>
  </div>
  <div class="tieude-tong">THÔNG TIN CÔNG TY</div>
  <div class="item1" data-aos="fade-left">
    <div class="item1-inner">
      <div class="noidung">
        <div class="left"><img src="assets/images/img-tongquan1.png"></div>
        <div class="right">
          <div class="name">CÔNG TY TNHH MTV DV VIỄN THÔNG PHƯƠNG NAM</div>
          <p><b>Tên giao dịch đối ngoại:</b> PHUONG NAM TELECOMMUNICATION SERVICES COMPANY LIMITED</p>
          <p><b>Tên giao dịch rút gọn:</b> PHUONG NAM TELECOM CO., LTD</p>
          <p><b>Tên viết tắt:</b> PNC</p>
          <p><b>Trụ sở chính:</b> 202 Ngô Gia Tự, Phường 4, Quận 10, Tp. Hồ Chí Minh.</p>
          <p><b>Ngày thành lập:</b>  01/04/2010 </p>
          <p><b>Giấy phép kinh doanh số:</b> 0309130075 </p>
          <p><b>Nơi cấp:</b> Sở kế hoạch và đầu tư thành phố Hồ Chí Minh </p>
          <p><b>Ngày cấp:</b> 01/03/2010</p>
          <p><b>Mã số thuế:</b> 0309130075</p>
          <p><b>Hotline:</b>  02873002222 </p>
          <p><b>Ext:</b> 80954  or  80955</p>
          <p><b>Email:</b> phuongnam.tuyendung@fpt.net.</p>
          <p><b>Website:</b> http://phuongnamtelecom.vn</p>
          <p><b>Facebook:</b> Phương Nam Telecom</p>
        </div>
      </div>
    </div>
  </div>
  <div class="item2" data-aos="fade-right">
    <div class="item2-inner">
      <div class="left">
        <div class="tomtat">Thành lập ngày 1/4/2010, Công ty TNHH MTV Dịch vụ Viễn thông Phương Nam
          (PNC) là đơn vị đối tác độc quyền của FPT Telecom tại khu vực phía Nam về
          lĩnh vực kỹ thuật viễn thông của FPT Telecom với các công việc chính như:</div>
        <div class="dichvu">
          <mat-icon>check</mat-icon>
          <div class="text">Triển khai, bảo trì Internet, Truyền hình FPT và các
            sản phẩm giá trị gia tăng của FPT Telecom</div>
        </div>
        <div class="dichvu">
          <mat-icon>check</mat-icon>
          <div class="text">Chuyển địa điểm mạng lưới Internet tốc độ cao</div>
        </div>
        <div class="dichvu">
          <mat-icon>check</mat-icon>
          <div class="text">Lắp đặt dịch vụ Truyền hình FPT</div>
        </div>
        <div class="dichvu">
          <mat-icon>check</mat-icon>
          <div class="text">Nâng cấp chuyển đổi công nghệ cáp quang mới…</div>
        </div>
      </div>
      <div class="right">
        <iframe src="https://www.youtube.com/embed/2H9eCV5o7n8">
        </iframe>
      </div>
    </div>
  </div>
  <div class="item3" data-aos="fade-left">
    <div class="hinh"><img src="assets/images/img-tongquan2.png"></div>
    <div class="item3-inner">
      <div class="item3-inner-inner">
        <div class="tieude">PHƯƠNG CHÂM HOẠT ĐỘNG</div>
        <div class="noidung">
          <div class="text1">Với phương châm “KHÁCH HÀNG LÀ TRỌNG TÂM” PNC Telecom tập trung chú trọng hơn cả về chất lượng phục vụ khách hàng.
            Đây là vấn đề cốt lõi, mang tính chiến lược hàng đầu để phát triển bền vững trong cuộc chiến “giữ chân khách hàng”.</div>
          <div class="text2">Với mục tiêu tăng trải nghiệm của khách hàng, để lắng nghe, thấu hiểu và làm khách hàng hài
            lòng hơn PNC đã áp dụng quản trị trải nghiệm khách hàng CEM (Customer Experience Management) và điểm số đánh giá độ hài lòng khách hàng CSAT
            (Customer Satisfaction) được ghi nhận bởi bộ phận chăm sóc khách hàng vào cơ
            chế lương thưởng của nhân viên.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="item4" data-aos="fade-right">
    <div class="item4-inner">
      <div class="left">
        <div class="tieude">NÂNG CAO CHẤT LƯỢNG SẢN PHẨM - DỊCH VỤ</div>
        <div class="hinh">
          <div class="hinh1">
            <img src="../../../../../../assets/images/img-tongquan5.png">
          </div>
          <div class="hinh2">
            <img src="../../../../../../assets/images/img-tongquan6.png">
          </div>
        </div>
        <div class="noidung">
          Không ngừng nâng cao chất lượng đường truyền Internet FPT,
          dịch vụ Truyền hình FPT nhằm đem lại lợi ích tối đa cho khách hàng khi sử dụng. Đồng thời,
          đẩy mạnh nâng cấp cơ sở hạ tầng viễn thông của FPT Telecom và chuyển đổi hạ
          tầng cáp Quang cho toàn bộ khách hàng đang sử dụng dịch vụ Internet của FPT.
        </div>
      </div>
      <div class="right">
        <img class="hinh1" src="../../../../../../assets/images/img-tongquan5.png">
        <img class="hinh2" src="../../../../../../assets/images/img-tongquan6.png">
      </div>
    </div>
  </div>
</div>
