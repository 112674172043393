import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NewsListComponent} from './news-list/news-list.component';
import {NavigationComponent} from './navigation/navigation.component';
import {RouterModule} from '@angular/router';
import {ImgCarouselComponent} from './img-carousel/img-carousel.component';
import {SafeUrlPipe} from '../../_pipes/blob.pipe';
import {SafePipe} from '../../_pipes/safe.pipe';
import {MatIconModule} from '@angular/material/icon';
import {FooterComponent } from './footer/footer.component';
import { BingmapComponent } from './bingmap/bingmap.component';
import { SelectTemplateComponent } from './select-template/select-template.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    NewsListComponent,
    NavigationComponent,
    ImgCarouselComponent,
    SafeUrlPipe,
    SafePipe,
    FooterComponent,
    BingmapComponent,
    SelectTemplateComponent,
  ],
  exports: [
    NavigationComponent,
    NewsListComponent,
    SafeUrlPipe,
    SafePipe,
    ImgCarouselComponent,
    FooterComponent,
    BingmapComponent,
    SelectTemplateComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    NgSelectModule,
    FormsModule,
  ]
})
export class SharedModule { }
