
<footer class="l-container">
  <div class="footer">
    <div class="item1">
      <div class="item1-inner">
        <div class="hinh">
          <div class = "fb-page" data-href = "https://www.facebook.com/CongtyvienthongPhuongNam"
               data-small-header = "false"
               data-adapt-container-width = "false"
               data-hide-cover = "false"
               data-show-facepile = "false" >
<!--            <blockquote cite = "https://www.facebook.com/CongtyvienthongPhuongNam">-->
<!--              <a href ="https://www.facebook.com/CongtyvienthongPhuongNam" > Phương Nam Telecom </a>-->
<!--            </blockquote>-->
            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FCongtyvienthongPhuongNam%2F&amp;appId=1713457448693036"
                    width="100%"></iframe>
<!--            </iframe>-->
<!--            data-tabs = ""-->
<!--            data-width = ""-->
<!--            data-height = ""-->
          </div>
        </div>
        <ul class="about">
          <li class="tieude">VỀ PHƯƠNG NAM TELECOM</li>
          <li class="noidung"><a routerLink="../gioi-thieu/gioi-thieu-cong-ty/cong-ty" target="_top">Giới thiệu công ty</a></li>
          <li class="noidung"><a routerLink="../gioi-thieu/so-do" target="_top">Giới thiệu phòng ban</a></li>
          <li class="noidung"><a routerLink="../tuyen-dung/quy-trinh-tuyen-dung" target="_top">Quy trình tuyển dụng</a></li>
        </ul>
        <ul class="tintuyendung">
          <li class="tieude">TIN TUYỂN DỤNG</li>
          <li class="noidung"><a routerLink="../tuyen-dung/co-hoi" target="_top">Tuyển dụng hot</a></li>
          <li class="noidung"><a routerLink="../tuyen-dung/co-hoi" target="_top">Tuyển dụng mới</a></li>
          <li class="noidung"><a routerLink="../tuyen-dung/co-hoi" target="_top">Vị trí quản lý</a></li>
        </ul>
        <div class="follow">
          <div class="tieude">FOLLOW ME</div>
          <div class="noidung"><img src="assets/images/img-footer2.png"></div>
          <div class="noidung">
            <a href="https://play.google.com/store/apps/details?id=com.pnc.mytinpnc">
              <img src="assets/images/img-footer3.png"></a>
          </div>
          <div class="icon">
            <!--          <img src="assets/images/icon-footer1.png">-->
            <a href="https://www.youtube.com/channel/UCfw2rSBg9UOdirSEogK0NmA" target="_blank"><img src="assets/images/icon-footer2.png"></a>
            <a href="https://www.facebook.com/CongtyvienthongPhuongNam" target="_blank"><img src="assets/images/icon-footer3.png"></a>
          </div>
        </div>
        <div class="item2-moblie">
          <div class="item2-inner">Giấy chứng nhận ĐKKD số 0309130075 do sở Kế hoạch và đầu tư Thành Phố Hồ Chí Minh cấp ngày 01/03/2010</div>
          <div class="item2-inner1">
            <!--        <div class="hinh"><img src="assets/images/img-footer4.png"></div>-->
            <a href="http://online.gov.vn/HomePage/CustomWebsiteDisplay.aspx?DocId=35569">
              <img src="https://fpt.vn/storage/upload/images/blocks/20150827110756-dathongbao.png" alt="FPT Telecom">
            </a>
          </div>
        </div>
      </div>
      <div class="item1-inner1">Copyright © 2021 Bản quyền thuộc về CÔNG TY TNHH MTV DV VIỄN THÔNG PHƯƠNG NAM</div>
    </div>
    <div class="item2">
      <div class="item2-inner1">
<!--        <div class="hinh"><img src="assets/images/img-footer4.png"></div>-->
        <a href="http://online.gov.vn/HomePage/CustomWebsiteDisplay.aspx?DocId=35569">
          <img src="https://fpt.vn/storage/upload/images/blocks/20150827110756-dathongbao.png" alt="FPT Telecom">
        </a>
      </div>
      <div class="item2-inner">Giấy chứng nhận ĐKKD số 0309130075 do sở Kế hoạch và đầu tư Thành Phố Hồ Chí Minh cấp ngày 01/03/2010</div>
    </div>
  </div>
</footer>

