import {Component, Input, OnInit} from '@angular/core';
import {NewsService} from '../../../../../_services/data/news.service';
import {NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-layout-news',
  templateUrl: './layout-news.component.html',
  styleUrls: ['./layout-news.component.css']
})
export class LayoutNewsComponent implements OnInit {
  @Input() muclucPN = '';
  @Input() muclucTP = '';
  tieude: any;
  newsList: any;
  newsListPN: any;
  newsListTieude: any;
  p = 1;
  routing: any;
  constructor(private  router: Router, private newsService: NewsService) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.getNewsPN();
    this.getNewsTinPnc();
    this.getTieude();
  }
  getNewsTinPnc(): void{
    switch (this.muclucPN) {
      case 'tin_tuc': this.routing = 'tin-tuc'; break;
      case 'su_kien': this.routing = 'su-kien'; break;
    }
    this.newsService.getNewsList(this.muclucTP, 1).subscribe((res: any) => {
      if (res){
        this.newsList = res;
        this.newsList = this.newsList.filter((i: any) => i.chi_nhanh === 'PNC');
        this.newsList = this.newsList.slice(0 , 10);
      }
    });
  }
  getNewsPN(): void{
    // @ts-ignore
    this.newsService.getNewsList(this.muclucPN, 2).subscribe((res) => {
      if (res){
        this.newsListPN = res;
        this.newsListPN = this.newsListPN.slice(0 , 10);
      }
    });
  }
  getTieude(): void{
    if (this.muclucPN === 'tin_tuc') {
      this.tieude = 'Sự kiện';
    } else {
      this.tieude = 'Tin tức';
    }
  }
  // tslint:disable-next-line:typedef
  onPageChange(page: number) {
    // @ts-ignore
    document.getElementById('tintuc').scrollIntoView();
    this.p = page;
    // const scrollToTop = window.setInterval(() => {
    //   console.log(window.pageYOffset);
    //   const pos = window.pageYOffset;
    //   if (pos >= 700) {
    //     // window.scrollTo(0, pos - 10);
    //   } else {
    //     window.clearInterval(scrollToTop);
    //   }
    // }, 1);
  }
}
