<div id="carousel_head" class="carousel slide" data-ride="carousel">

      <!-- Indicators -->
      <ul class="carousel-indicators">
        <li data-target="#carousel_head" data-slide-to="0" class="active"></li>
        <li data-target="#carousel_head" data-slide-to="1"></li>
        <li data-target="#carousel_head" data-slide-to="2"></li>
        <li data-target="#carousel_head" data-slide-to="3" *ngIf="imageList.length >= 4"></li>
        <li data-target="#carousel_head" data-slide-to="4" *ngIf="imageList.length >= 5"></li>
      </ul>

      <!-- The slideshow -->
      <div class="carousel-inner" id='banner'>
        <div class="carousel-item" *ngFor="let img of imageList; let i = index" [ngClass]="i === 0? 'active': ''">
          <img class="banner-img" src="assets/images/anh_bia/{{img}}">
        </div>
      </div>

      <!-- Left and right controls -->
      <a class="carousel-control-prev" href="#carousel_head" data-slide="prev">
        <span class="carousel-control-prev-icon"></span>
      </a>
      <a class="carousel-control-next" href="#carousel_head" data-slide="next">
        <span class="carousel-control-next-icon"></span>
      </a>
</div>
