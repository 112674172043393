// @ts-ignore

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NewsPagesComponent} from './news-pages/news-pages.component';
import {SharedModule} from '../../shared/shared.module';




@NgModule({
  declarations: [
    NewsPagesComponent,
  ],
  exports: [
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
})
export class TruyenthongModule {}
