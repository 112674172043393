import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lichsu-ht',
  templateUrl: './lichsu-ht.component.html',
  styleUrls: ['./lichsu-ht.component.css']
})
export class LichsuHtComponent implements OnInit {
  year = 0;
  yearList1 = [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2020];
  yearList2 = [0, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2020];
  // yearList2 = [2016, 2017, 2018, 2020];

  constructor() { }

  ngOnInit(): void {
  }

}
