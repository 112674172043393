/* tslint:disable:typedef */
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {EnvironmentsService} from '../environments.service';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  baseUrl: any;
  public data = {};
  constructor(private http: HttpClient, private envService: EnvironmentsService) {
    this.baseUrl = envService.apiUrl;
  }
  // tslint:disable-next-line:typedef
  convertToBase64(link: any) {
    return this.http.post<any>(this.baseUrl + '/convert_img_base64', link);
  }

  // tslint:disable-next-line:typedef
  asyncConvertToBase64(link: any) {
    return this.http.post<any>(this.baseUrl + '/convert_img_base64', link).toPromise();
  }

  // tslint:disable-next-line:typedef
  exportReport(content: any) {
    return this.http.post<any>(this.baseUrl + '/bao_cao_chi_tiet', content);
  }

  getInfoTuyenDung(content: any){
    return this.http.post<any>( this.baseUrl + '/list_tuyen_dung_pntelecom', content);
  }
}
