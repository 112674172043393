import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-news-tt-pn',
  templateUrl: './detail-news-tt-pn.component.html',
  styleUrls: ['../detail-news/detail-news.component.css']

})
export class DetailNewsTtPnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
