<div class="news-pages">
  <h5 class="header">{{category}}</h5>
  <div class="interface">
    <div class="content">
      <div class="item1">
        <div class="item1_inner">
          <img class="img-banner" [src]="newsList[0]?.img">
          <a [href]="newsList[0].linkUrl">
            <p class="tieude-banner">{{newsList[0].tieu_de}}</p>
            <p class="para-banner">{{newsList[0].tom_tat}}</p>
          </a>
        </div>
      </div>
      <div class="item2">
        <h5>Tin liên quan</h5>
        <div class="item2_inner">
          <div *ngFor="let o of newsList">
            <div class="item2_inner_inner">
              <div>
                <a [href]="o.linkUrl">
                  <img class="img-detail" [src]="o.img">
                </a>
              </div>
              <div>
                <a [href]="o.linkUrl">
                  <p class="tieude">{{o.tieu_de}}</p>
                  <p class="para">{{o.tom_tat}}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tinnoibat">
      <h5>Tin tức nổi bật</h5>
            <app-news-list [flexWidth]="'flexWidth33'"
                           [isShowVideo]="true"
                           [isShowPage]="true"
            ></app-news-list>
    </div>
  </div>
</div>
