import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-gioithieu-mobile',
  templateUrl: './gioithieu-mobile.component.html',
  styleUrls: ['./gioithieu-mobile.component.css']
})
export class GioithieuMobileComponent implements OnInit {
  // @ts-ignore
  @Input() isShowGioithieu1: boolean;
  // @ts-ignore
  @Input() isShowGioithieu2: boolean;
  // @ts-ignore
  @Input() isShowGioithieucty: boolean;
  // @ts-ignore
  @Input() isShowTongquan: boolean;
  // @ts-ignore
  @Input() isShowLichsu: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
