<div class="content-row">
  <div class="content-row-inner">
    <div *ngIf="cityList">
      <app-select-template [placeholder]="'Vị trí'" [elementList]="vitriList"
                           [customSelect]="'100%'" (dataEvent)="dtEvent($event)"
                           [strElement]="'vi_tri'" [defaultSelect]="'cohoi'">
      </app-select-template>
    </div>
    <div>
      <app-select-template [placeholder]="'Cấp bậc'" [elementList]="capbacList"
                           [strElement]="'thuc_tap'" (dataEvent)="dtEvent($event)"
                           [customSelect]="'100%'" [defaultSelect]="'cohoi'">
      </app-select-template>
    </div>
    <div>
      <app-select-template [placeholder]="'Khu vực'" [elementList]="cityList"
                           [customSelect]="'100%'" (dataEvent)="dtEvent($event)"
                           [strElement]="'khu_vuc'" [defaultSelect]="'cohoi'">
      </app-select-template>
    </div>
    <div>
      <button class="btn btn-submit" (click)="searchVacancy()"><mat-icon>search</mat-icon>Tìm kiếm</button>
    </div>
  </div>
</div>
<div class="cohoi-container" data-aos="fade-up">
  <div class="main">
    <div class="joblist">
      <div class="joblist-header">
        <div id="normal" [ngClass]="chosen === 1 ? 'is-active': ''" (click)="chosen = 1" >Tin tuyển dụng mới</div>
        <div id="manager" [ngClass]="chosen === 2 ? 'is-active': ''" (click)="chosen = 2" href="javascript:void(0)">Vị trí quản lý</div>
      </div>
      <div class="joblist-main" *ngIf="chosen === 1">
        <div *ngIf="message" class="joblist-none">{{message}}</div>
        <div *ngIf="jobList" id="joblist" class="joblist-content">
          <div class="job" *ngFor="let job of jobList">
            <div class="jobname">
              <a routerLink="//tuyen-dung/co-hoi/{{job.id}}">{{job.ten_vi_tri}}</a>
            </div>
            <div class="job-content">
              <div><img src="assets/images/location.svg"/>{{job.dia_diem}}</div>
              <div><img src="assets/images/Calendar.svg"/>Đã Đăng: {{job.ngay_dang}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="joblist-main" *ngIf="chosen === 2">
        Không có thông tin tuyển dụng
      </div>
    </div>
<!--    <div class="advertisement">-->
<!--      <img src="assets/images/cohoi-ad1.jpg"/>-->
<!--      <img src="assets/images/cohoi-ad2.jpg"/>-->
<!--      <img src="assets/images/cohoi-ad3.jpg"/>-->
<!--    </div>-->
  </div>
</div>
