import { Component, OnInit } from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import Swal from 'sweetalert2';
import {ParticipantService} from '../../../../../_services/data/participant.service';

@Component({
  selector: 'app-apply-form',
  templateUrl: './apply-form.component.html',
  styleUrls: ['./apply-form.component.css', '../ungtuyen.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'vi-VN'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class ApplyFormComponent implements OnInit {
  newsList: any;
  randomCaptcha = '';
  realCaptcha: any;
  enterCaptcha: any;
  maxDate: Date | undefined;
  ho_va_ten: any;
  gioi_tinh: any;
  ngay_sinh: any;
  sdt: any;
  email: any;
  chuc_vu: any;
  truong_hoc: any;
  chuyen_nganh: any;
  list_noi_o_hien_tai: any;
  list_nguon_tuyen_dung: any;
  list_vi_tri_tuyen: any;
  list_truong_hoc: any;
  list_chuyen_nganh: any;

  noi_o_hien_tai: any;
  vi_tri_tuyen: any;
  nguon_tuyen_dung: any;
  today = new Date();
  branch: any;
  newCandidate = {
    ho_va_ten: '',
    gioi_tinh: '',
    ngay_sinh: '',
    sdt: '',
    noi_o_hien_tai : '',
    email: '',
    nguon_tuyen_dung: '',
    chuc_vu: 0,
    vi_tri_tuyen: '',
    branch: ''
  };
  td = [String(this.today.getDate()), String(this.today.getMonth() + 1), this.today.getFullYear()].join('/');
  time = [String(this.today.getHours()), String(this.today.getMinutes() + 1), this.today.getSeconds()].join(':');
  day = this.td + ' ' + this.time;
  content = {
    date_get: this.day
  };
  nameRegex = '^[a-zA-ZaAàÀảẢãÃáÁạẠăĂằẰẳẲẵẴắẮặẶâÂầẦẩẨẫẪấẤậẬbBcCdDđĐeEèÈẻẺẽẼéÉẹẸêÊềỀểỂễỄếẾệỆfFgGhHiIìÌỉỈĩĨíÍịỊjJkKlLmMnNoOòÒỏỎõÕóÓọỌôÔồỒổỔỗỖốỐộỘơƠờỜởỞỡỠớỚợỢpPqQrRsStTuUùÙủỦũŨúÚụỤưƯừỪửỬữỮứỨựỰvVwWxXyYỳỲỷỶỹỸýÝỵỴzZ ]*$';
  phoneRegex = '^((\\+84[3|5|7|8|9])|(0[3|5|7|8|9])){1}([0-9]{8}$)';
  emailRegex = '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
  constructor(private participantService: ParticipantService) { }

  ngOnInit(): void {
    this.getDataCombobox();
    this.changeCaptcha();
  }
  getDataCombobox(): any {
    this.participantService.getDataComboBox(this.content).subscribe((res) => {
      this.list_noi_o_hien_tai = res.result.data.noi_o_hien_tai;
      this.list_vi_tri_tuyen = res.result.data.vi_tri_ung_tuyen;
      this.list_nguon_tuyen_dung = res.result.data.nguon_tuyen_dung;
    });
  }
  onSubmit(): any {
    if (this.enterCaptcha === this.randomCaptcha) {
      // @ts-ignore
      document.getElementById('error-captcha').innerText = 'Điền kí tự trong ô';
      // @ts-ignore
      document.getElementById('error-captcha').style.color = 'black';
      Object.keys(this.newCandidate).forEach((key) => {
        // @ts-ignore
        if (this[key] === undefined || this[key] === '' || this[key] === null) {
          // @ts-ignore
          document.getElementById(key).innerText = 'Bạn không được để trống!';
          if (key === 'chuc_vu') {
            this.newCandidate[key] = 2;
          }
          else {
            // @ts-ignore
            this.newCandidate[key] = '';
          }
        }
        else {
          if (key === 'ho_va_ten') {
            if (!this[key].match(this.nameRegex)) {
              this.newCandidate[key] = '';
              // @ts-ignore
              document.getElementById(key).innerText = 'Vui lòng nhập lại họ và tên!';
            }
            else {
              this.newCandidate[key] = this[key];
              // @ts-ignore
              document.getElementById(key).innerText = '';
            }
          }
          else if (key === 'sdt') {
            if (!this[key].match(this.phoneRegex)) {
              this.newCandidate[key] = '';
              // @ts-ignore
              document.getElementById(key).innerText = 'Vui lòng nhập lại số điện thoại!';
            }
            else {
              this.newCandidate[key] = this[key];
              // @ts-ignore
              document.getElementById(key).innerText = '';
            }
          }
          else if (key === 'email') {
            if (!this[key].match(this.emailRegex)) {
              this.newCandidate[key] = '';
              // @ts-ignore
              document.getElementById(key).innerText = 'Vui lòng nhập lại email!';
            }
            else {
              this.newCandidate[key] = this[key];
              // @ts-ignore
              document.getElementById(key).innerText = '';
            }
          }
          else if (key === 'ngay_sinh'){
            // @ts-ignore
            document.getElementById(key).innerText = '';
            const d = new Date(this[key]);
            this.newCandidate[key] = [this.convertToString(d.getDate()),
              this.convertToString(d.getMonth() + 1),
              this.convertToString(d.getFullYear())].join('/');
          }
          else if (key === 'chuc_vu') {
            if (this[key] === '0') {
              // @ts-ignore
              document.getElementById(key).innerText = '';
              this.newCandidate[key] = 0;
            }
            else if (this[key] === '1') {
              // @ts-ignore
              document.getElementById(key).innerText = '';
              this.newCandidate[key] = 1;
            }
          }
          else {
            // @ts-ignore
            document.getElementById(key).innerText = '';
            // @ts-ignore
            this.newCandidate[key] = this[key];
          }
        }
      });
      if (this.newCandidate.sdt !== '' && this.newCandidate.email !== ''
        && this.newCandidate.ho_va_ten !== '' && this.newCandidate.gioi_tinh !== ''
        && this.newCandidate.ngay_sinh !== '' && this.newCandidate.noi_o_hien_tai !== ''
        && this.newCandidate.nguon_tuyen_dung !== '' && this.newCandidate.vi_tri_tuyen !== ''
        && this.newCandidate.branch !== '' && this.newCandidate.chuc_vu !== 2) {
        this.participantService.addCandidate(this.newCandidate).subscribe((res) => {
          if (res.result) {
            const r = res.result;
            if (r.status === 1) {
              Swal.fire('Hoàn thành', r.msg, 'success');
              this.ho_va_ten = undefined;
              this.gioi_tinh = undefined;
              this.ngay_sinh = undefined;
              this.sdt = undefined;
              this.noi_o_hien_tai = undefined;
              this.chuc_vu = undefined;
              this.email = undefined;
              this.vi_tri_tuyen = undefined;
              this.nguon_tuyen_dung = undefined;
              this.branch = undefined;
              this.changeCaptcha();
            } else {
              Swal.fire('Lỗi', r.msg, 'error');
              this.changeCaptcha();
            }
          } else {
            Swal.fire('Lỗi', 'Đăng kí thất bại', 'error');
            this.changeCaptcha();
          }
        }, error => {
          Swal.fire('Lỗi', 'Đăng kí thất bại', 'error');
          this.changeCaptcha();
        });
      }
    }
    else {
      this.changeCaptcha();
      // @ts-ignore
      document.getElementById('error-captcha').innerText = 'Vui lòng nhập lại!';
      // @ts-ignore
      document.getElementById('error-captcha').style.color = 'red';
    }
  }
  changeCaptcha(): any {
    this.enterCaptcha = '';
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghiklmnopqrstuvwxyz';
    const stringLength = 6;
    this.randomCaptcha = '';
    for (let i = 0; i < stringLength; i++) {
      const s = Math.floor(Math.random() * chars.length);
      this.randomCaptcha += chars.substring(s, s + 1);
    }
    this.realCaptcha = this.randomCaptcha;
  }
  convertToString(n: number): string {
    return n > 9 ? '' + n : '0' + n;
  }

}
