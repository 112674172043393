import { Component, OnInit } from '@angular/core';
import {NewsService} from '../../../../_services/data/news.service';

@Component({
  selector: 'app-cauchuyen-td',
  templateUrl: './cauchuyen-td.component.html',
  styleUrls: ['./cauchuyen-td.component.css']
})
export class CauchuyenTdComponent implements OnInit {
  newsList: any;
  constructor(private newsService: NewsService) { }

  ngOnInit(): void {
    this.getNewsTdList();
  }
  getNewsTdList(): any{
    this.newsService.getNewsList('cau_chuyen_tuyen_dung', 2).subscribe((res: any) => {
      if (res){
        this.newsList = res;
      }
    });
  }
}
