// @ts-ignore
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {FptcameraComponent} from './fptcamera/fptcamera.component';
import {FptihomeComponent} from './fptihome/fptihome.component';
import {FptplayComponent} from './fptplay/fptplay.component';
import {TrienkhaiBaotriComponent} from './trienkhai-baotri/trienkhai-baotri.component';
import {TruyenhinhfptComponent} from './truyenhinhfpt/truyenhinhfpt.component';
import {SanphamDichvuRoutingModule} from './sanpham-dichvu-routing.module';
import { DvOnsiteComponent } from './dv-onsite/dv-onsite.component';
import { DvPhanmemComponent } from './dv-phanmem/dv-phanmem.component';
import { DvKhacphucsucoComponent } from './dv-khacphucsuco/dv-khacphucsuco.component';
import { LayoutDvComponent } from './share/layout-dv/layout-dv.component';
import {SanphamDichvuComponent} from './sanpham-dichvu.component';

@NgModule({
  declarations: [
    // NewsPagesComponent,
    FptcameraComponent,
    FptihomeComponent,
    FptplayComponent,
    TrienkhaiBaotriComponent,
    TruyenhinhfptComponent,
    DvOnsiteComponent,
    DvPhanmemComponent,
    DvKhacphucsucoComponent,
    LayoutDvComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    SanphamDichvuRoutingModule
  ],
})
export class SanphamDichvuModule {}
