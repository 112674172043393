import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-truyenhinhfpt',
  templateUrl: './truyenhinhfpt.component.html',
  styleUrls: ['./truyenhinhfpt.component.css']
})
export class TruyenhinhfptComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
