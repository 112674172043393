/* tslint:disable:typedef */
import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-select-template',
  templateUrl: './select-template.component.html',
  styleUrls: ['./select-template.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SelectTemplateComponent implements OnInit {
  @Input() customControlLabel = 'custom-control-label-80';
  @Input() customSelect = '290px';
  @Input() defaultSelect = 'custom-ng';
  // @ts-ignore
  @Input() disabled: boolean;
  // @ts-ignore
  @Input() title: string;
  // @ts-ignore
  @Input() colorText: string;
  // @ts-ignore
  @Input() element: string;
  // @ts-ignore
  @Input() placeholder: string;
  // @ts-ignore
  @Input() multiple: boolean;
  @Input() strElement: any;
  @Input() elementList: any;
  @Input() elementEvent = new EventEmitter<any>();
  @Output() dataEvent = new EventEmitter<any>();
  @Input() keyList: any;
  constructor() { }

  async ngOnInit() {
    if (this.elementEvent){
      this.elementEvent.subscribe(event => {
        this.element = event;
        this.dataEvent.emit({str: this.strElement, value: this.element});
      });
    }
    this.dataEvent.emit({str: this.strElement, value: this.element});
  }
  // @ts-ignore
  dtEvent(str, e){
    this.dataEvent.emit({str, value: e});
  }
  // @ts-ignore
  onSelectAll(str){
    // @ts-ignore
    this.element = this.elementList.map(item => item);
    this.dataEvent.emit({str, value: this.element});
  }
  // @ts-ignore
  onClearAll(str) {
    // @ts-ignore
    this.element = null;
    this.dataEvent.emit({str, value: this.element});
  }
  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.emp_name.toLocaleLowerCase().indexOf(term) > -1 || item.email.toLocaleLowerCase().indexOf(term) > -1;
  }

}
