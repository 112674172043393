/* tslint:disable:typedef */
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sanpham-dichvu',
  templateUrl: './sanpham-dichvu.component.html',
  styleUrls: ['./sanpham-dichvu.component.css']
})
export class SanphamDichvuComponent implements OnInit {
  // @Input() imageList = ['anh-bia-1200x400.jpg', 'bia-web-1200x400.jpg', 'IMG_5486-1200x400.jpg'];
  imgList = ['sanpham_dichvu/sanpham-dichvu1.jpg', 'sanpham_dichvu/sanpham-dichvu2.jpg', 'sanpham_dichvu/sanpham-dichvu3.jpg'];
  danhsachDichvu = [
    {stt: 1, duongdan: 'dv-khacphucsuco', img: 'icon-spdv3.png', img_hover: 'icon-spdv6.png', name: 'Triển Khai - Bảo Trì\nHệ Thống Mạng'},
    {stt: 2, duongdan: 'dv-onsite', img: 'icon-spdv1.png', img_hover: 'icon-spdv4.png', name: 'Sửa Chữa\n Các Thiết Bị Công Nghệ'},
    {stt: 3, duongdan: 'dv-phanmem', img: 'icon-spdv2.png', img_hover: 'icon-spdv5.png', name: 'Cài Đặt Phần Mềm'},
  ];

  selectedDichvu = 0;
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      // @ts-ignore
      if (event.url) {
        // @ts-ignore
        const r = event.url.split('/');
        if (r[r.length - 1] === 'dv-khacphucsuco') {
          this.selectedDichvu = 1;
        }
        else if (r[r.length - 1] === 'dv-onsite') {
          this.selectedDichvu = 2;
        }
        else if (r[r.length - 1] === 'dv-phanmem') {
          this.selectedDichvu = 3;
        }
      }
    });
  }

  ngOnInit(): void {
    this.scrollMenu();
  }

  scrollMenu() {
    let prevScroll = window.pageYOffset;
    // @ts-ignore
    let prevSpdv = document.getElementById('sanpham-dichvu').offsetTop;
    // tslint:disable-next-line:only-arrow-functions typedef
    window.onscroll = function() {
      const currentScroll = window.pageYOffset;
      const header = document.getElementById('header');
      const spdv = document.getElementById('sanpham-dichvu');
      if (currentScroll === 0) {
        // @ts-ignore
        header.style.background = '#ffffffc9';
        // @ts-ignore
        header.style.top = '0';
        if (spdv) {
          // @ts-ignore
          spdv.style.visibility = 'initial';
          // @ts-ignore
          spdv.style.opacity = 1;
          spdv.classList.remove('smaller');
        }
      }
      else {
        // @ts-ignore
        header.style.background = 'white';
        if (spdv) {
          const currentSpdv = spdv.offsetTop;
          // @ts-ignore
          spdv.classList.add('smaller');
          if (prevSpdv > currentSpdv) {
            // @ts-ignore
            spdv.style.opacity = 1;
            // @ts-ignore
            spdv.style.visibility = 'initial';
          }
          else {
            // @ts-ignore
            spdv.style.opacity = 0;
            // @ts-ignore
            spdv.style.visibility = 'hidden';
          }
          prevSpdv = currentSpdv;
        }
        if (prevScroll > currentScroll) {
          // @ts-ignore
          header.style.top = '0';
        } else {
          // @ts-ignore
          header.style.top = '-64px';
        }
        prevScroll = currentScroll;
      }
    };
  }

  selectDichvu(stt: any) {
    this.selectedDichvu = 0;
    const id = 'dichvu' + stt;
    // @ts-ignore
    document.getElementById('dichvu1').classList.remove('active');
    // @ts-ignore
    document.getElementById('dichvu2').classList.remove('active');
    // @ts-ignore
    document.getElementById('dichvu3').classList.remove('active');
    // @ts-ignore
    document.getElementById(id).classList.add('active');
  }
}
