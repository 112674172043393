<div [ngClass]="['flex-container', flexWidth]">
<!--  <h6>Thông tin nổi bật</h6>-->
<!---->
  <div class="list-container" *ngIf="isShowPage">
      <div class="row" *ngFor="let o of newsList; let idx = index; trackBy: trackByFn">
        <img class="col-6 img" [src]="o.link_hinh_anh | safeResourceUrl" />
        <div class="col-6">
          <a class="content" routerLink="/tin-tuc-su-kien/{{routing}}/{{o.tin_tuc_id}}">{{o.tieu_de}}</a>
          <p class="content-2">
            <img src="assets/images/icon-home4.svg">
            {{o.ngay_dang_bai}}
          </p>
        </div>
      </div>
  </div>

  <div class="list-container1" *ngIf="isShowPageHome">
    <div id="carousel_head2" class="carousel slide" data-ride="carousel">
      <!-- Indicators -->
      <ul class="carousel-indicators">
        <li data-target="#carousel_head2" data-slide-to="0" class="active"></li>
        <li data-target="#carousel_head2" data-slide-to="1"></li>
      </ul>
      <!-- The slideshow -->
      <div class="carousel-inner" *ngIf="newsList">
        <div class="carousel-item active">
          <div class="item1">
            <div class="row">
              <img class="col-6 img" [src]="newsList[0].link_hinh_anh | safeResourceUrl" />
              <div class="col-6">
                <div class="row-noidung">
                  <div class="hinh"><img src="assets/images/icon-home3.png"></div>
                  <div class="text">{{newsList[0].tac_gia}}</div>
                  <div class="hinh"><img class="row-icon" src="assets/images/icon-home4.png"></div>
                  <div class="text">{{newsList[0].ngay_dang_bai}}</div>
                </div>
                <a class="row-noidung1" routerLink="/tin-tuc-su-kien/tin-tuc/tp/{{newsList[0].tin_tuc_id}}">{{newsList[0].tieu_de}}</a>
<!--                <a class="row-noidung1" routerLink="/tin-tuc-su-kien/tp/{{newsList[0].tin_tuc_id}}">{{newsList[0].tieu_de}}</a>-->
                <div class="row-noidung2">{{newsList[0].tom_tat}}</div>
              </div>
            </div>
            <div class="row">
              <img class="col-6 img" [src]="newsList[1].link_hinh_anh | safeResourceUrl" />
              <div class="col-6">
                <div class="row-noidung">
                  <div class="hinh"><img src="assets/images/icon-home3.png"></div>
                  <div class="text">{{newsList[1].tac_gia}}</div>
                  <div class="hinh"><img class="row-icon" src="assets/images/icon-home4.png"></div>
                  <div class="text">{{newsList[1].ngay_dang_bai}}</div>
                </div>
                <a class="row-noidung1" routerLink="/tin-tuc-su-kien/tin-tuc/tp/{{newsList[1].tin_tuc_id}}">{{newsList[1].tieu_de}}</a>
<!--                <a class="row-noidung1" routerLink="/tin-tuc-su-kien/tp/{{newsList[1].tin_tuc_id}}">{{newsList[1].tieu_de}}</a>-->
                <div class="row-noidung2">{{newsList[1].tom_tat}}</div>
              </div>
            </div>
            <div class="row">
              <img class="col-6 img" [src]="newsList[2].link_hinh_anh | safeResourceUrl" />
              <div class="col-6">
                <div class="row-noidung">
                  <div class="hinh"><img src="assets/images/icon-home3.png"></div>
                  <div class="text">{{newsList[2].tac_gia}}</div>
                  <div class="hinh"><img class="row-icon" src="assets/images/icon-home4.png"></div>
                  <div class="text">{{newsList[2].ngay_dang_bai}}</div>
                </div>
                <a class="row-noidung1" routerLink="/tin-tuc-su-kien/tin-tuc/tp/{{newsList[2].tin_tuc_id}}">{{newsList[2].tieu_de}}</a>
<!--                <a class="row-noidung1" routerLink="/tin-tuc-su-kien/tp/{{newsList[2].tin_tuc_id}}">{{newsList[2].tieu_de}}</a>-->
                <div class="row-noidung2">{{newsList[2].tom_tat}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="item1">
            <div class="row">
              <img class="col-6 img" [src]="newsList[3].link_hinh_anh | safeResourceUrl" />
              <div class="col-6">
                <div class="row-noidung">
                  <div class="hinh"><img src="assets/images/icon-home3.png"></div>
                  <div class="text">{{newsList[3].tac_gia}}</div>
                  <div class="hinh"><img class="row-icon" src="assets/images/icon-home4.png"></div>
                  <div class="text">{{newsList[3].ngay_dang_bai}}</div>
                </div>
                <a class="row-noidung1" routerLink="/tin-tuc-su-kien/tin-tuc/tp/{{newsList[3].tin_tuc_id}}">{{newsList[3].tieu_de}}</a>
<!--                <a class="row-noidung1" routerLink="/tin-tuc-su-kien/tp/{{newsList[3].tin_tuc_id}}">{{newsList[3].tieu_de}}</a>-->
                <div class="row-noidung2">{{newsList[3].tom_tat}}</div>
              </div>
            </div>
            <div class="row">
              <img class="col-6 img" [src]="newsList[4].link_hinh_anh | safeResourceUrl" />
              <div class="col-6">
                <div class="row-noidung">
                  <div class="hinh"><img src="assets/images/icon-home3.png"></div>
                  <div class="text">{{newsList[4].tac_gia}}</div>
                  <div class="hinh"><img class="row-icon" src="assets/images/icon-home4.png"></div>
                  <div class="text">{{newsList[4].ngay_dang_bai}}</div>
                </div>
                <a class="row-noidung1" routerLink="/tin-tuc-su-kien/tin-tuc/tp/{{newsList[4].tin_tuc_id}}">{{newsList[4].tieu_de}}</a>
<!--                <a class="row-noidung1" routerLink="/tin-tuc-su-kien/tp/{{newsList[4].tin_tuc_id}}">{{newsList[4].tieu_de}}</a>-->
                <div class="row-noidung2">{{newsList[4].tom_tat}}</div>
              </div>
            </div>
            <div class="row">
              <img class="col-6 img" [src]="newsList[5]?.link_hinh_anh | safeResourceUrl" />
              <div class="col-6">
                <div class="row-noidung">
                  <div class="hinh"><img src="assets/images/icon-home3.png"></div>
                  <div class="text">{{newsList[5]?.tac_gia}}</div>
                  <div class="hinh"><img class="row-icon" src="assets/images/icon-home4.png"></div>
                  <div class="text">{{newsList[5]?.ngay_dang_bai}}</div>
                </div>
                <a class="row-noidung1" routerLink="/tin-tuc-su-kien/tin-tuc/tp/{{newsList[5]?.tin_tuc_id}}">{{newsList[5]?.tieu_de}}</a>
<!--                <a class="row-noidung1" routerLink="/tin-tuc-su-kien/tp/{{newsList[5]?.tin_tuc_id}}">{{newsList[5]?.tieu_de}}</a>-->
                <div class="row-noidung2">{{newsList[5]?.tom_tat}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carousel_head2" data-slide="prev">
      </a>
      <a class="carousel-control-next" href="#carousel_head2" data-slide="next">
      </a>
    </div>
  </div>

  <div class="list-container2" *ngIf="isShowPageHome1">
    <div class="list-container2-inner">
      <div class="tin_dautien" *ngIf="newsList">
        <div class="row">
          <img class="col-6 img" [src]="newsList[0].img | safeResourceUrl" />
          <div class="col-6">
            <div class="row-noidung">
              <div class="hinh"><img src="assets/images/icon-home3.png"></div>
              <div class="text">{{newsList[0].tac_gia}}</div>
              <div class="hinh"><img class="row-icon" src="assets/images/icon-home4.png"></div>
              <div class="text">{{newsList[0].ngay_dang_bai}}</div>
            </div>
            <a class="row-noidung1" routerLink="/tin-tuc-su-kien/{{routing}}/{{newsList[0].tin_tuc_id}}">{{newsList[0].tieu_de}}</a>
            <div class="row-noidung2">{{newsList[0].tom_tat}}</div>
          </div>
        </div>
      </div>
      <div id="carousel_head3" class="carousel slide" data-ride="carousel">
        <!-- Indicators -->
        <ul class="carousel-indicators">
          <li data-target="#carousel_head3" data-slide-to="0" class="active"></li>
          <li data-target="#carousel_head3" data-slide-to="1"></li>
        </ul>
        <!-- The slideshow -->
        <div class="carousel-inner" *ngIf="newsList1">
          <div class="carousel-item active">
            <div class="item1">
              <div class="row mobile">
                <img class="col-6 img" [src]="newsList[0].img | safeResourceUrl" />
                <div class="col-6">
                  <a class="row-noidung1" routerLink="/tin-tuc-su-kien/{{routing}}/{{newsList[0].tin_tuc_id}}">{{newsList[0].tieu_de}}</a>
<!--                  <a class="row-noidung1" routerLink="/tin-tuc-su-kien/pn/{{newsList[0].tin_tuc_id}}">{{newsList[0].tieu_de}}</a>-->
                  <div class="row-noidung">
                    <div class="hinh"><img src="assets/images/icon-home3.png"></div>
                    <div class="text">{{newsList[0].tac_gia}}</div>
                    <div class="hinh"><img class="row-icon" src="assets/images/icon-home4.png"></div>
                    <div class="text">{{newsList[0].ngay_dang_bai}}</div>
                  </div>
                  <div class="row-noidung2">{{newsList[0].tom_tat}}</div>
                </div>
              </div>
              <div class="row" *ngFor="let i of newsList1; let idx = index; trackBy: trackByFn">
                <img class="col-6 img" [src]="i.img | safeResourceUrl" />
                <div class="col-6">
                  <a class="row-noidung1" routerLink="/tin-tuc-su-kien/{{routing}}/{{i.tin_tuc_id}}">{{i.tieu_de}}</a>
                  <div class="row-noidung">
                    <div class="hinh"><img src="assets/images/icon-home3.png"></div>
                    <div class="text">{{i.tac_gia}}</div>
                    <div class="hinh"><img class="row-icon" src="assets/images/icon-home4.png"></div>
                    <div class="text">{{i.ngay_dang_bai}}</div>
                  </div>
                  <div class="row-noidung2">{{i.tom_tat}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item" *ngIf="newsList2">
            <div class="item1">
              <div class="row" *ngFor="let i of newsList2; let idx = index; trackBy: trackByFn">
                <img class="col-6 img" [src]="i.img | safeResourceUrl" />
                <div class="col-6">
                  <a class="row-noidung1" routerLink="/tin-tuc-su-kien/{{routing}}/{{i.tin_tuc_id}}">{{i.tieu_de}}</a>
                  <div class="row-noidung">
                    <div class="hinh"><img src="assets/images/icon-home3.png"></div>
                    <div class="text">{{i.tac_gia}}</div>
                    <div class="hinh"><img class="row-icon" src="assets/images/icon-home4.png"></div>
                    <div class="text">{{i.ngay_dang_bai}}</div>
                  </div>
                  <div class="row-noidung2">{{i.tom_tat}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carousel_head3" data-slide="prev">
        </a>
        <a class="carousel-control-next" href="#carousel_head3" data-slide="next">
        </a>
      </div>
    </div>
    <a *ngIf="newsList" class="col-6" routerLink="/tin-tuc-su-kien/{{routing}}/{{newsList[0].tin_tuc_id}}">
      <div class="button">Xem thêm</div>
    </a>
  </div>
</div>
