import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-right-lichsu-ht',
  templateUrl: './right-lichsu-ht.component.html',
  styleUrls: ['./right-lichsu-ht.component.css']
})
export class RightLichsuHtComponent implements OnInit {
  @Input() year = 0;
  constructor() {
  }
  ngOnInit(): void {
  }

}
