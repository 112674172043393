import { Component, OnInit } from '@angular/core';
import {TableName} from '../../../../../_models/elements/tableName';

@Component({
  selector: 'app-detail-news-pn',
  templateUrl: './detail-news-pn.component.html',
  styleUrls: ['./detail-news-pn.component.css']
})
export class DetailNewsPnComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
