import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './modules/mainpage/home/home.component';
import { MatIconModule } from '@angular/material/icon';
import {SafeUrlPipe} from 'src/app/_pipes/blob.pipe';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {MainpageRoutingModule} from './modules/mainpage/mainpage-routing.module';
import {CommonModule} from '@angular/common';
import {SharedModule} from './modules/shared/shared.module';
import {MainpageModule} from './modules/mainpage/mainpage.module';
import {TruyenthongModule} from './modules/mainpage/truyenthong/truyenthong.module';
import { LayoutDetailComponent } from './modules/mainpage/tintuc-sukien/shared/layout-detail/layout-detail.component';
import { DetailNewsPnComponent } from './modules/mainpage/tintuc-sukien/detail/detail-news-pn/detail-news-pn.component';
import { DetailNewsTpComponent } from './modules/mainpage/tintuc-sukien/detail/detail-news-tp/detail-news-tp.component';
import {EnvironmentsService} from './_services/environments.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LayoutDetailComponent,
    DetailNewsPnComponent,
    DetailNewsTpComponent,
    // SafeUrlPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MainpageRoutingModule,
    CommonModule,
    MainpageModule,
    SharedModule,
    TruyenthongModule,
  ],
  providers: [
    EnvironmentsService,
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvironmentsService) => () => envService.init(),
      deps: [EnvironmentsService],
      multi: true,
    },
  ],
  exports: [
      SafeUrlPipe,
      LayoutDetailComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
