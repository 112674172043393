import { Component, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';
// import {SocialLoginService} from '../../../_services/auth/sociallogin.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(-140%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class NavigationComponent implements OnInit {

  menuState = 'out';
  mobile = false;
  chosen: any;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      // @ts-ignore
      if (event.url) {
        // @ts-ignore
        const a = event.url.split('/')[1];
        // @ts-ignore
        if ( a === 'home') {
          this.chosen = 1;
        }
        switch (a){
          case 'home':
            this.chosen = 1;
            break;
          case 'gioi-thieu':
            this.chosen = 2;
            break;
          case 'san-pham-dich-vu':
            this.chosen = 3;
            break;
          case 'lien-he':
            this.chosen = 6;
            break;
          case 'dao-tao':
            this.chosen = 7;
            break;
        }
      }
    });
  }

  ngOnInit(): void {
    if (window.location.href.includes('mobile')) {
      this.mobile = true;
    }
    this.scrollMenu();
  }
  // tslint:disable-next-line:typedef
  scrollMenu() {
    let prevScroll = window.pageYOffset;
    // tslint:disable-next-line:only-arrow-functions typedef
    window.onscroll = function() {
      const currentScroll = window.pageYOffset;
      if (currentScroll === 0) {
        // @ts-ignore
        document.getElementById('header').style.background = '#ffffffc9';
        // @ts-ignore
        document.getElementById('header').style.top = '0';
      }
      else {
        // @ts-ignore
        document.getElementById('header').style.background = 'white';
        if (prevScroll >= currentScroll) {
          // @ts-ignore
          document.getElementById('header').style.top = '0';
        } else {
          // @ts-ignore
          document.getElementById('header').style.top = '-64px';
        }
        prevScroll = currentScroll;
      }
    };
  }

  // tslint:disable-next-line:typedef
  toggleMenu() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }
  // tslint:disable-next-line:typedef
  toggleSubMenu(e: any) {
    if (e.target.classList.contains('is-active') && !e.target.classList.contains('collapsed')) {
      e.target.classList.remove('is-active');
    }
    else {
      // @ts-ignore
      document.getElementById('checkbox2').classList.remove('is-active');
      // @ts-ignore
      document.getElementById('checkbox3').classList.remove('is-active');
      // @ts-ignore
      document.getElementById('checkbox4').classList.remove('is-active');
      // @ts-ignore
      document.getElementById('checkbox5').classList.remove('is-active');
      // @ts-ignore
      document.getElementById('checkbox6').classList.remove('is-active');
      e.target.classList.add('is-active');
    }
  }
}
