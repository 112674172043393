import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sodo',
  templateUrl: './sodo.component.html',
  styleUrls: ['./sodo.component.css']
})
export class SodoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
