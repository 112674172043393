import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-gioithieu-cty',
  templateUrl: './gioithieu-cty.component.html',
  styleUrls: ['./gioithieu-cty.component.css']
})
export class GioithieuCtyComponent implements OnInit {
  chosen = 1;
  constructor() { }
  ngOnInit(): void {
  }
  // @ts-ignore
  // tslint:disable-next-line:typedef
  isActive(event) {
    // @ts-ignore
    document.getElementById('gt-congty').classList.remove('is-active');
    // @ts-ignore
    document.getElementById('lichsu-ht').classList.remove('is-active');
    // @ts-ignore
    document.getElementById('tongquan').classList.remove('is-active');
    event.target.classList.add('is-active');
  }

  // @HostListener('window:scroll', ['$event'])
  // // tslint:disable-next-line:typedef
  // onWindowScroll($event: any) {
  //   const top = window.scrollY;
  //   if (top > 200) {
  //     this.fixed = true;
  //     console.log(top);
  //   } else if (this.fixed && top < 1190) {
  //     this.fixed = false;
  //     console.log(top);
  //   }
  // }

}
