export const TableName = {
  empCheckinTb: 'emp_checkin_tb',
  employeeTb: 'employees_tb',
  regionTb: 'region_tb',
  ptqTb: 'ptq_tb',
  departmentTb: 'department_tb',
  ticket: {unionTb: 'union_tb', ctpTb: 'module_office_CTP_tb', tamungTb: 'module_office_tam_ung_tb', hoaDonTb: 'module_office_hoa_don_tb'},
  deviceIdTb: 'device_id_tb',
  errorPTQTb: 'error_ptq_tb',
  adminTb: 'user_right_checkin_tb',
  atldTb: 'tool_ATLD_tb',
  officeWorkFlowTb: 'module_office_workflow_tb',
  covid19Tb: 'add_covid19_tb',
  newsTb: 'tin_tuc_tb',
  newsPNTb: 'tin_tuc_phuongnamtelecom_tb',
  accountTb: 'account_management_tb',
  cityTb: 'tinh_thanh_tb',
  districtTb: 'quan_huyen_tb',
  wardTb: 'phuong_xa_tb',
  libraryTb: 'thu_vien_tb',
  quanlyUngvienTb: 'tuyen_dung_quan_ly_ung_vien_tb',
  universityTb: 'danh_sach_truong_hoc_tb',
  lichsuLuongTb: 'lich_su_loai_luong_tb',
  dethiTb: 'tuyen_dung_bai_thi_test_tb',
  chuyennganhTb: 'danh_sach_chuyen_nganh_tb',
  dantocTb: 'danh_sach_dan_toc_tb',
  bankTb: 'danh_sach_ngan_hang_tb',
  ctyTb: 'danh_sach_cty_thanh_vien_tb',
  vitricvTb: 'danh_sach_vi_tri_cong_viec_tb',
  tuyendungTTCNTb: 'tuyen_dung_thong_tin_ca_nhan_tb',
  tuyendungEduTb: 'tuyen_dung_trinh_do_hoc_van_tb',
  tuyendungExpTb: 'tuyen_dung_kinh_nghiem_lam_viec_tb',
  tuyendungLangTb: 'tuyen_dung_ngoai_ngu_tb',
  tuyendungCerTb: 'tuyen_dung_chung_chi_chuyen_nganh_tb',
  tuyendungDanhgiaTb: 'tuyen_dung_danh_gia_ung_vien_tb',
  tuyendungInfo: 'thong_tin_tuyen_dung',
  tuyendungInfo2: 'info_tuyen_dung',
  ngPvTb: 'danh_sach_nguoi_pv_tb',
  xephangTb: 'xep_hang_nhan_vien_PNC_tb',
  gameQuaySoTb: 'tro_choi_so_vong_quay_tb',
  gameKQQuaySoTb: 'tro_choi_ket_qua_tb',
  notiTb: 'notify',
  flipbookTb: 'flipbook_tb',
  infoBlockTb: 'info_block_tb',
  salaryTb: {
    luongHoachToanTb: 'luong_hoach_toan_tb',
    luongDhTb: 'luong_dh_tb',
    luongFtiTb: 'luong_fti_tb',
    luongIndoTb: 'luong_indo_tb',
    luongInfTb: 'luong_inf_tb',
    luongKeyIndoTb: 'luong_key_indo_tb',
    luongAbcdTb: 'luong_nhom_abcd_tb',
    luongTfTb: 'luong_tf_tb',
    luongThucuocTb: 'luong_thu_cuoc_tb',
    luongTkBtTb: 'luong_tk_bt_tb',
    luongTNOSTb: 'luong_tn_os_tb',
    luongDHTINTb: 'luong_dh_tin_tb',
    luongTestTBTb: 'luong_testTB_tb',
    luongSOPNCTb: 'luong_so_tb',
    luongTFTINTb: 'luong_tf_tin_tb',
    luongTKBTTINTb: 'luong_tkbt_tin_tb'
  },
};
