import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-truyenthong',
  templateUrl: './truyenthong.component.html',
  styleUrls: ['./truyenthong.component.css']
})
export class TruyenthongComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
