import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dv-onsite',
  templateUrl: './dv-onsite.component.html',
  styleUrls: ['./dv-onsite.component.css']
})
export class DvOnsiteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
