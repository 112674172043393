import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {GioithieuComponent} from './gioithieu/gioithieu.component';
import {TuyendungComponent} from './tuyendung/tuyendung.component';
import {NewsPagesComponent} from './truyenthong/news-pages/news-pages.component';
import {MatTabsModule} from '@angular/material/tabs';
import {SanphamDichvuComponent} from './sanpham-dichvu/sanpham-dichvu.component';
import {LienheComponent} from './lienhe/lienhe.component';
import {NoiboComponent} from './noibo/noibo.component';
import {DaoTaoComponent} from './dao-tao/dao-tao.component';

const routes: Routes = [
  {path: 'gioi-thieu', component: GioithieuComponent,
    loadChildren: () => import('./gioithieu/gioithieu.module').then(m => m.GioithieuModule)},
  {
    path: 'tuyen-dung', component: TuyendungComponent,
    loadChildren: () => import('./tuyendung/tuyendung.module').then(m => m.TuyendungModule)},
  {path: 'news/:page', component: NewsPagesComponent},
  {path: 'tin-tuc-su-kien',
    loadChildren: () => import('./tintuc-sukien/tintuc-sukien.module').then(m => m.TintucSukienModule)},
  {path: 'lien-he', component: LienheComponent},
  {path: 'san-pham-dich-vu', component: SanphamDichvuComponent,
    loadChildren: () => import('./sanpham-dichvu/sanpham-dichvu.module').then(m => m.SanphamDichvuModule)},
  {path: 'noi-bo', component: NoiboComponent},
  {path: 'dao-tao', component: DaoTaoComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [
    RouterModule,
    MatTabsModule,
  ]
})
export class MainpageRoutingModule { }
