import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MatTabsModule} from '@angular/material/tabs';
import {MoitruongComponent} from './moitruong/moitruong.component';
import {CohoiComponent} from './cohoi/cohoi.component';
import {QuytrinhtuyendungComponent} from './quytrinhtuyendung/quytrinhtuyendung.component';
import {CauchuyenTdComponent} from './cauchuyen-td/cauchuyen-td.component';
import {UngtuyenComponent} from './ungtuyen/ungtuyen.component';
import {DetailCohoiComponent} from './cohoi/detail-cohoi/detail-cohoi.component';


const routes: Routes = [
  {path: 'moi-truong', component: MoitruongComponent},
  {path: 'co-hoi', component: CohoiComponent},
  {path: 'co-hoi/:id', component: DetailCohoiComponent},
  {path: 'quy-trinh-tuyen-dung', component: QuytrinhtuyendungComponent},
  {path: 'cau-chuyen-tuyen-dung', component: CauchuyenTdComponent},
  {path: 'ung-tuyen', component: UngtuyenComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [
    RouterModule,
    MatTabsModule
  ]
})
export class TuyendungRoutingModule { }
