import { Injectable } from '@angular/core';

// @ts-ignore
@Injectable({
  providedIn: 'root'
})
export enum Environment {
  Prod = 'prod',
  Staging = 'staging',
  Local = 'local',
}

export class EnvironmentsService {
  // tslint:disable-next-line:variable-name
  private _env!: Environment;
  // tslint:disable-next-line:variable-name
  private _apiUrl!: string;

  constructor() { }
  init(): Promise<void> {
    return new Promise(resolve => {
      this.setEnvVariables();
      resolve();
    });
  }
  get env(): Environment {
    return this._env;
  }
  private setEnvVariables(): void {
    const hostname = window && window.location && window.location.hostname;
    console.log(hostname);
    if (hostname.includes('localhost') || hostname.includes('172.27.121.204')) {
      this._env = Environment.Local;
      // this._apiUrl = 'http://mypt-api-stag.fpt.net';
      // this._apiUrl = 'https://apis.fpt.vn';
      this._apiUrl = 'https://mytinpnc.vn';
    }
    else if (hostname === 'mypt-stag.fpt.vn') {
      this._env = Environment.Staging;
      this._apiUrl = 'https://apis-stag.fpt.vn';
    }
    else if (hostname === 'mypt.fpt.vn') {
      this._env = Environment.Prod;
      this._apiUrl = 'https://apis.fpt.vn';
    }
    else if (hostname === 'phuongnamtelecom.vn') {
      this._env = Environment.Prod;
      this._apiUrl = 'https://mytinpnc.vn';
      // this._apiUrl = 'https://apis.fpt.vn';
    }
    else {
      console.warn(`Cannot find environment for host name ${hostname}`);
    }
  }

  get apiUrl(): string {
    return this._apiUrl;
  }
}
