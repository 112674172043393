import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NewsService} from '../../../../../_services/data/news.service';
import {DataService} from '../../../../../_services/data/data.service';

@Component({
  selector: 'app-layout-detail',
  templateUrl: './layout-detail.component.html',
  styleUrls: ['./layout-detail.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutDetailComponent implements OnInit {

  @Input() chinhanh: string | undefined;
  @Input() tableName: number | undefined;
  newsId: any;
  objCurrent: any;
  image: any;
  content: any;
  mobile: boolean | undefined;
  constructor(private route: ActivatedRoute, private newsService: NewsService, private dataService: DataService,
              private router: Router) {
  }

  // tslint:disable-next-line:typedef
  async ngOnInit() {
    // const p = this.route.snapshot.params.tin_tuc_id;
    this.route.params.forEach(async (params: Params) => {
      if (this.tableName) {
        const dt = await this.newsService.getDetailNews(params.tin_tuc_id, this.tableName);
        // console.log(dt);
        this.objCurrent = dt.data[0];
        this.dataService.convertToBase64({mode: 'convert_str', link: this.objCurrent.noi_dung}).subscribe((res) => {
          this.content = res.result;
        });
      }
    });

  }

}
