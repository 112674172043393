/* tslint:disable:typedef */
import { Component, OnInit } from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {ParticipantService} from '../../../../_services/data/participant.service';
import Swal from 'sweetalert2';
import {NewsService} from '../../../../_services/data/news.service';
import {Router} from '@angular/router';
import {DataService} from '../../../../_services/data/data.service';

@Component({
  selector: 'app-ungtuyen',
  templateUrl: './ungtuyen.component.html',
  styleUrls: ['./ungtuyen.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'vi-VN'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class UngtuyenComponent implements OnInit {
  newsList: any;
  constructor(private participantService: ParticipantService,
              private newsService: NewsService, private router: Router, private dataService: DataService) {
    this.getSomeNews();
  }

  ngOnInit(): void {
  }
  async getSomeNews(){
    // @ts-ignore
    await this.newsService.getNewsList('tuyen_dung', 1).subscribe(async (res) => {
      this.newsList = res;
      for (const i of this.newsList) {
        let a;
        if (i.link.includes('mobile')) {
          Object.assign(i, {linkUrl: '/api/auth/truyenthong/news/' + this.convertLink(i.link)});
        } else {
          const b = i.link.split('/mytinpnc.vn');
          Object.assign(i, {linkUrl: b[1]});
        }
        if (i.hinh_anh !== '') {
          a = await this.dataService.asyncConvertToBase64({mode: 'convert_link', link: i.hinh_anh});
        } else {
          a = await this.dataService.asyncConvertToBase64({
            mode: 'convert_link',
            link: '//home/cds/my_TIN_PNC/mytin_backend/data/upload/news/default.jpeg'
          });
        }
        if (a && a.result) {
          const b = 'data:image/png;base64, ' + a.result;
          Object.assign(i, {img: b});
        }
      }
    });
  }
  // @ts-ignore
  convertLink(str){
    const a = str.split('/mobile');
    return a[1];
  }
}
