<div id="jobdetail" class="job-detail">
  <button routerLink="//tuyen-dung/co-hoi"><mat-icon>keyboard_return</mat-icon>Quay lại</button>
  <div class="job-name">{{jobDetail.ten_vi_tri.toUpperCase()}}</div>
  <div class="job-end">
    <div><mat-icon>calendar_today</mat-icon><p>Thời hạn: {{jobDetail.thoi_han}}</p></div>
    <div><mat-icon>supervised_user_circle</mat-icon><p>Số lượng tuyển: {{jobDetail.so_luong}}</p></div>
  </div>
  <div [innerHTML]="contentHtml"></div>
<!--  <button class="btn btn-submit" (click)="sendInfo()">Ứng tuyển ngay!!!</button>-->
  <hr>
  <div class="job-form">
    <app-apply-form></app-apply-form>
  </div>
</div>
