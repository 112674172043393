import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {EnvironmentsService} from '../environments.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  baseUrl: any;

  constructor(private http: HttpClient, private envService: EnvironmentsService) {
    this.baseUrl = envService.apiUrl;
  }

  getNewsList(mucluc: string, typeBang: number): any {
    const arrResult: ReplaySubject<any> = new ReplaySubject(1);
    const content = {
      email: '',
      muc_luc: mucluc,
      type_bang: typeBang,
    };
    this.http.post(this.baseUrl + '/info_tin_tuc', content).subscribe((res) => {
      if (res) {
        // @ts-ignore
        const dt = res.data;
        for (const i of dt) {
          let a;
          if (i.link.includes('mobile')) {
            Object.assign(i, {linkUrl: '/api/auth/truyenthong/news/' + this.convertLink(i.link)});
          } else {
            const b = i.link.split('/mytinpnc.vn');
            Object.assign(i, {linkUrl: b[1]});
          }
          if (i.hinh_anh !== '') {
            if (typeBang === 1) {
              a = i.hinh_anh.split('/')[9];
              const b = 'https://mytinpnc.vn/data/news/' + a;
              Object.assign(i, {img: b});
            } else {
              Object.assign(i, {img: i.hinh_anh[0]});
            }
          } else {
            a = 'default.jpeg';
          }
        }
        arrResult.next(dt);
      }
    });
    return arrResult;
  }

  convertLink(str: string): any {
    const a = str.split('/mobile');
    return a[1];
  }

  getDetailNews(id: string, tableName: number): any {
    const content = {
      type_bang: tableName,
      id
    };
    const a = this.http.post(this.baseUrl + '/info_tin_tuc', content).toPromise();
    return a;
  }
}
