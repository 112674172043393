<div class="gt-qttuyendung">
  <div class="content">
    <div class="item1" data-aos="fade-up">QUY TRÌNH TUYỂN DỤNG</div>
    <div class="item2" data-aos="fade-up">
      <div class="item2-background"><img src="assets/images/background_quytrinhtuyendung.png"></div>
    </div>
    <div class="item3">
      <div class="item3-inner">
        <div class="item3-inner-inner" *ngFor="let l of listquytrinh" data-aos="fade-up">
          <div class="bieutuong">
            <div class="logo"><img src="{{l.icon}}"></div>
          </div>
          <div class="thongtin">
            <div class="tieude">{{l.tieude}}</div>
            <div class="noidung">{{l.noidung}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="item4" data-aos="fade-up">
      <div class="loicamon">
        Cám ơn bạn đã quan tâm đến cơ hội nghề nghiệp của PNC Telecom.
        Chúng tôi tin tưởng rằng PNC Telecom sẽ là nơi đồng hành cùng bạn để phát triển bản thân trở thành phiên bản tốt nhất của chính mình.
        <div>
        </div>
      </div>
    </div>
  </div>
</div>
