<div *ngIf="objCurrent" class="news-1">
  <div>
    <div class="tieude">{{objCurrent.tieu_de}}</div>
    <div class="thoigian">
      <img src="assets/images/icon-home4.svg">
      {{objCurrent.ngay_dang_bai}}
    </div>
    <div class="content" [innerHTML]="content | safe: 'html'">
    </div>
  </div>
</div>
<!--<div class="detail-news">-->
<!--  <div class="detail-news-inner">-->
<!--    <div class="item1">-->
<!--      <div class="container-news">-->
<!--        <div *ngIf="objCurrent" class="news-1">-->
<!--          <div>-->
<!--            <div class="tieude">{{objCurrent.tieu_de}}</div>-->
<!--            <div class="thoigian">-->
<!--              <img src="assets/images/icon-home4.svg">-->
<!--              {{objCurrent.ngay_dang_bai}}-->
<!--            </div>-->
<!--            <div class="content" [innerHTML]="content | safe: 'html'">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="item2">-->
<!--      <div class="item2-tieude">TIN LIÊN QUAN</div>-->
<!--      <app-news-list [flexWidth]="'flexWidth11'"-->
<!--                     [isShowPageHome]="false"-->
<!--                     [isShowPage]="true"-->
<!--                     [typeBang]= "2"-->
<!--      ></app-news-list>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
