import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tuyendung',
  templateUrl: './tuyendung.component.html',
  styleUrls: ['./tuyendung.component.css']
})
export class TuyendungComponent implements OnInit {
  imgList = ['trang_chu/home21.jpg', 'tuyen_dung/tuyendung4.jpg', 'tuyen_dung/tuyendung3.jpg' ];
  constructor() { }

  ngOnInit(): void {
  }

}
