import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class EmpCheckinService {
  readonly baseUrl = environment.backendUrl;
  constructor(private http: HttpClient) {}
  getAll(query: any): any {
    return this.http.post<any>(this.baseUrl + '/empCheckin', query);
  }

}
