import { Component, OnInit } from '@angular/core';
import {NewsService} from '../../../../_services/data/news.service';
import {DataService} from '../../../../_services/data/data.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-tintuc',
  templateUrl: './tintuc.component.html',
  styleUrls: ['./tintuc.component.css']
})
export class TintucComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
