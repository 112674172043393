// @ts-ignore
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {SukienComponent} from './sukien/sukien.component';
import {TintucComponent} from './tintuc/tintuc.component';
import {TintucSukienRoutingModule} from './tintuc-sukien-routing.module';
import { LayoutNewsComponent } from './shared/layout-news/layout-news.component';
import { DetailNewsComponent } from './detail/detail-news/detail-news.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { DetailNewsSkPnComponent } from './detail/detail-news-sk-pn/detail-news-sk-pn.component';
import { DetailNewsTtPnComponent } from './detail/detail-news-tt-pn/detail-news-tt-pn.component';
import {AppModule} from '../../../app.module';

@NgModule({
  declarations: [
    SukienComponent,
    TintucComponent,
    LayoutNewsComponent,
    DetailNewsComponent,
    DetailNewsSkPnComponent,
    DetailNewsTtPnComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        MatIconModule,
        TintucSukienRoutingModule,
        NgxPaginationModule,
    ],
})
export class TintucSukienModule {}
