<div class="application">
  <div class="title">
    ĐƠN ĐĂNG KÝ
  </div>
  <div class="main">
    <div class="form">
      <input id="name" type="text" placeholder="Họ và tên" [(ngModel)]="ho_va_ten"/>
      <div id="ho_va_ten" class="input-error"></div>
    </div>
    <div class="form">
      <ul>
        <li>
          <input class="select_close" type="radio" name="sex" id="select_close" [(ngModel)]="gioi_tinh" value=''/>
          <span class="select_label select_placeholder">Giới tính</span>
        </li>
        <li class="select_items">
          <input class="select_open" type="radio" name="sex" id="select_open"/>
          <label class="select_closeLabel" for="select_close"></label>
          <ul class="select_options">
            <li class="select_option">
              <input class="select_input" type="radio" name="sex" [(ngModel)]="gioi_tinh" id="male" value="Nam"/>
              <label class="select_label" for="male">Nam</label>
            </li>
            <li class="select_option">
              <input class="select_input" type="radio" name="sex" [(ngModel)]="gioi_tinh" id="female" value="Nữ"/>
              <label class="select_label" for="female">Nữ</label>
            </li>
          </ul>
          <label class="select_openLabel" for="select_open"></label>
        </li>
      </ul>
      <div id="gioi_tinh" class="input-error"></div>
    </div>
    <div class="form">
      <input id="birthday" type="text" placeholder="Ngày sinh" [(ngModel)]="ngay_sinh" [matDatepicker]="picker" [max]="maxDate" readonly/>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <div id="ngay_sinh" class="input-error"></div>
    </div>
    <div class="form">
      <input id="phone" type="text" [(ngModel)]="sdt" placeholder="Số điện thoại"/>
      <div id="sdt" class="input-error"></div>
    </div>
    <div class="form district">
      <ng-select placeholder="Nơi ở" [(ngModel)]="noi_o_hien_tai">
        <ng-option *ngFor="let district of list_noi_o_hien_tai" [value]="district">{{district}}</ng-option>
      </ng-select>
      <div id="noi_o_hien_tai" class="input-error"></div>
    </div>
    <div class="form">
      <ul>
        <li>
          <input class="select_close" type="radio" name="vacancy" id="select_close3" [(ngModel)]="chuc_vu" value="2"/>
          <span class="select_label select_placeholder">Chức vụ</span>
        </li>
        <li class="select_items">
          <input class="select_open" type="radio" name="vacancy" id="select_open3"/>
          <label class="select_closeLabel" for="select_close3"></label>
          <ul class="select_options">
            <li class="select_option">
              <input class="select_input" type="radio" [(ngModel)]="chuc_vu" name="vacancy" id="employ" value="0"/>
              <label class="select_label" for="employ">Nhân viên</label>
            </li>
            <li class="select_option">
              <input class="select_input" type="radio"[(ngModel)]="chuc_vu" name="vacancy" id="intern" value="1"/>
              <label class="select_label" for="intern">Thực tập</label>
            </li>
          </ul>
          <label class="select_openLabel" for="select_open3"></label>
        </li>
      </ul>
      <div id="chuc_vu" class="input-error"></div>
    </div>
    <div class="form form100">
      <input id="email1" type="text" [(ngModel)]="email" placeholder="Email"/>
      <div id="email" class="input-error"></div>
    </div>
    <div class="form form100">
      <ng-select placeholder="Chi nhánh" [(ngModel)]="branch">
        <ng-option value="PNC">Công ty TNHH MTV Dịch Vụ Viễn Thông Phương Nam</ng-option>
        <ng-option value="TIN">Công ty TNHH Hạ Tầng Viễn Thông Miền Bắc</ng-option>
      </ng-select>
      <div id="branch" class="input-error"></div>
    </div>
    <div class="form">
      <ng-select placeholder="Vị trí ứng tuyển" [(ngModel)]="vi_tri_tuyen">
        <ng-option *ngFor="let position of list_vi_tri_tuyen" [value]="position">{{position}}</ng-option>
      </ng-select>
      <div id="vi_tri_tuyen" class="input-error"></div>
    </div>
    <div class="form">
      <ng-select placeholder="Biết thông tin qua" [(ngModel)]="nguon_tuyen_dung">
        <ng-option *ngFor="let source of list_nguon_tuyen_dung" [value]="source">{{source}}</ng-option>
      </ng-select>
      <div id="nguon_tuyen_dung" class="input-error"></div>
    </div>
  </div>
  <div class="footer">
    <div class="captcha-container">
      <div class="captcha">
        <input type="text" id="randomfield" [(ngModel)]="realCaptcha" disabled>
      </div>
      <div class="input-captcha">
        <div id="error-captcha" >Điền kí tự trong ô</div>
        <input maxlength="6" [(ngModel)]="enterCaptcha"/>
      </div>
    </div>
    <button class="btn-send" (click)="onSubmit()">Gửi thông tin</button>
  </div>
</div>
