<app-img-carousel></app-img-carousel>
<section>
  <div class="home">
    <div class="item1">
      <div class="item1-inner1" data-aos="fade-up">
        <div class="item1-inner-inner">
          <div class="item1-thongtin">
            <div class="item1-tieude">Giới Thiệu Tổng Quan Phương Nam Telecom</div>
            <div class="item1-noidung1">Thông Tin Công Ty</div>
            <div class="item1-noidung2">Thành lập ngày 1/4/2010, Công ty TNHH MTV Dịch vụ Viễn thông Phương Nam (PNC)
              đang hoạt động trong lĩnh vực triển khai – bảo trì dịch vụ viễn thông.
              Với đội ngũ kỹ thuật kinh nghiệm, thái độ phục vụ chuyên nghiệp hóa đã giúp PNC ngày càng khẳng định được vị thế vững chắc của mình trong lòng khách hàng.
              Trong những năm gần đây, PNC đang từng bước cải tiến, áp dụng các công nghệ tiên tiến và giành được rất nhiều giải thưởng danh giá như:</div>
            <ul class="item1-noidung3">
              <li><mat-icon>check</mat-icon>
                <div>Dự án Phân công tối ưu đạt giải Chuyển đổi số xuất sắc nhất FPT Telecom</div></li>
              <li><mat-icon>check</mat-icon>
                <div>Sao Công nghệ FPT hạng Nhất cho dự án Phân công tối ưu của FPT Telecom.</div></li>
            </ul>
          </div>
          <div class="item1-hinhanh">
            <div class="hinh1"><img src="assets/images/img-trangchu4.jpg"></div>
<!--            <div class="hinh2"><img src="assets/images/img-trangchu1.jpg"></div>-->
          </div>
        </div>
      </div>
      <div class="item1-inner2" data-aos="fade-up">
        <div class="item1-inner2-inner">
          <div class="inner2-tieude">Giá Trị Cốt Lõi Của Công Ty</div>
          <div class="inner2-noidung">
            <div class="icon t1">
              <img src="assets/images/icon-home5.svg">
              <div class="readmore">
                <div class="text">Đào Tạo</div>
              </div>
            </div>
            <div class="icon t2">
              <img src="assets/images/icon-home6.svg">
              <div class="readmore">
                <div class="text">Thăng Tiến</div>
              </div>
            </div>
            <div class="icon t3">
              <img src="assets/images/icon-home7.svg">
              <div class="readmore">
                <div class="text">Đãi Ngộ</div>
              </div>
            </div>
            <div class="icon t4">
              <img src="assets/images/icon-home8.svg">
              <div class="readmore">
                <div class="text">Gắn Kết</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item2" data-aos="fade-up">
      <div class="item2-inner">
        <div class="item2-tieude">SẢN PHẨM & DỊCH VỤ</div>
        <div class="item2-carousel">
          <div id="test" class="carousel slide" data-ride="carousel">
            <!-- Indicators -->
            <ul class="carousel-indicators">
<!--              <li data-target="#test" data-slide-to="0" class="active"></li>-->
<!--              <li data-target="#test" data-slide-to="1"></li>-->
            </ul>
            <!-- The slideshow -->
            <div class="carousel-inner">
              <div class="carousel-item active">
               <div class="sanpham">
                 <a routerLink="../san-pham-dich-vu/dv-khacphucsuco"><div class="sanpham-inner">
<!--                   <div class="sanpham-button">Learn more</div>-->
                   <div class="sanpham-hinh">
                     <img src="assets/images/img-home1.png">
                     <div class="readmore">
                       <div class="text">Xem Thêm</div>
                     </div>
                   </div>
                   <div class="sanpham-inner-inner">
                     <div class="hinh"><img src="assets/images/icon-home1.png"></div>
                     <div class="sanpham-tieude">Triển Khai - Bảo Trì Hệ Thống Mạng</div>
                     <div class="sanpham-noidung">Hệ thống mạng ổn định là một trong các yếu tố quan
                       trọng được đặt lên hàng đầu của các doanh nghiệp hiện nay.
                       PNC Telecom hơn 10 năm kinh nghiệm triển khai bảo trì các hệ
                       thống lớn chúng tôi cung cấp các dịch vụ tối ưu và tiết kiệm chi phí cho các doanh nghiệp & khách hàng.</div>
                   </div>
                 </div></a>
                 <a routerLink="../san-pham-dich-vu/dv-onsite"><div class="sanpham-inner">
                   <div class="sanpham-hinh">
                     <img src="assets/images/img-home1.png">
                     <div class="readmore">
                       <div class="text">Xem Thêm</div>
                     </div>
                   </div>
                   <div class="sanpham-inner-inner">
                     <div><img src="assets/images/icon-home1.png"></div>
                     <div class="sanpham-tieude">Sửa Chữa Các Thiết Bị Công Nghệ</div>
                     <div class="sanpham-noidung">Phần cứng là bộ phận thường xuyên cần được bảo dưỡng,
                       bảo trì không kém gì phần mềm.
                       Thiết bị hoạt động đạt hiệu quả tốt nhất thì cả phẩn cứng lẫn phần
                       mềm đều phải đảm bảo trong tình trạng hoạt động tốt nhất. </div>
                   </div>
                 </div></a>
                 <a routerLink="../san-pham-dich-vu/dv-phanmem"><div class="sanpham-inner">
                   <div class="sanpham-hinh">
                     <img src="assets/images/img-home1.png">
                     <div class="readmore">
                       <div class="text">Xem Thêm</div>
                     </div>
                   </div>
                   <div class="sanpham-inner-inner">
                     <div><img src="assets/images/icon-home1.png"></div>
                     <div class="sanpham-tieude">Cài Đặt Phần Mềm</div>
                     <div class="sanpham-noidung">Dịch vụ cài đặt hệ điều hành tận nơi sẽ giúp cho máy tính
                       của bạn chạy mượt như mới sau một thời gian sử dụng. Với các chuyên gia chuyên xử lý và
                       ứng cứu sự cố máy tính chúng tôi hoàn toàn có thể đáp ứng nhu cầu của bạn một cách đơn giản và nhanh chóng nhất.</div>
                   </div>
                   </div></a>
               </div>
              </div>
            </div>
            <!-- Left and right controls -->
            <a class="carousel-control-prev" href="#test" data-slide="prev">
            </a>
            <a class="carousel-control-next" href="#test" data-slide="next">
            </a>
          </div>
        </div>
        <div class="item2-thongke" id="counter">
          <div class="thongke">
            <div class="number">
              3000+
            </div>
            <div class="text"><p>Nhân Viên</p></div>
          </div>
          <div class="thongke">
            <div class="number">
              150+
            </div>
            <div class="text">Đối Tác</div>
          </div>
          <div class="thongke">
            <div class="number">
              45+
            </div>
            <div class="text">Chi Nhánh</div>
          </div>
          <div class="thongke">
            <div class="number">
              30+
            </div>
            <div class="text">Tỉnh Thành</div>
          </div>
        </div>
      </div>
      <div class="background"><img src="assets/images/img-home2.png"></div>
    </div>
    <div class="item3" data-aos="fade-up">
      <div class="item3-inner">
        <div class="item3-tieude">TIN TỨC</div>
        <app-news-list [flexWidth]="'flexWidth11'"
                       [isShowPageHome]="true"
                       [typeBang]="1"
        ></app-news-list>
      </div>
    </div>
<!--    <div class="item4">-->
<!--      <div class="item4-inner">-->
<!--        <div class="item4-tieude">PHÁT TRIỂN NGUỒN NHÂN LỰC</div>-->
<!--        <app-news-list [flexWidth]="'flexWidth111'"-->
<!--                       [isShowPageHome1]="true"-->
<!--                       [typeBang]="2"-->
<!--                       [mucluc]="'cau_chuyen_tuyen_dung'"-->
<!--        ></app-news-list>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</section>
