import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fptplay',
  templateUrl: './fptplay.component.html',
  styleUrls: ['./fptplay.component.css']
})
export class FptplayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
