import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TableName} from '../../../../../_models/elements/tableName';
import {DataService} from '../../../../../_services/data/data.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-detail-cohoi',
  templateUrl: './detail-cohoi.component.html',
  styleUrls: ['./detail-cohoi.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DetailCohoiComponent implements OnInit {
  jobDetail: any;
  id: any;
  fileDescription: any;
  desciptionList = [
    {job: 'Nhân viên Triển Khai Bảo Trì', content: 'nhanvientrienkhaibaotri.txt'},
    {job: 'Kỹ thuật viên Điều hành', content: 'kythuatviendieuhanh.txt'},
    {job: 'Kỹ thuật viên Onsite', content: 'kythuatvienhotrokhachhangtainha(OS).txt'},
    {job: 'Nhân Viên Phát Triển Kinh Doanh', content: 'nhanvienphattrienkinhdoanh.txt'},
    {job: 'Nhân viên quản lý chất lượng', content: 'nhanvienquanlychatluongQA.txt'},
    {job: 'Nhân viên thanh tra, giám sát', content: 'nhanvienthanhtragiamsat.txt'},
    {job: 'Trưởng Phòng Kinh Doanh', content: 'truongphongkinhdoanh.txt'},
    {job: 'Kỹ thuật viên INDO', content: 'kysuhotrokythuatdichvuvienthong(INDO).txt'},
  ];
  contentHtml: any;
  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getJobDetail();
    window.scrollTo(0, 0);
  }
  getJobDetail(): any{
    let strLink = '//home/cds/my_TIN_PNC/mytin_backend/data/upload/tuyendung/';
    const objAdd = {
      condition: {id: this.id},
      name_table: TableName.tuyendungInfo2
    };
    this.dataService.getInfoTuyenDung(objAdd).subscribe((res) => {
      // console.log(res);
      if (res.result) {
        const r = res.result;
        if (r.status === 1) {
          this.jobDetail = r.detail.data[0];
          for (const des of this.desciptionList) {
            if (this.jobDetail.ten_vi_tri === des.job) {
              this.fileDescription = des.content;
              break;
            } else {
              this.fileDescription = '';
            }
          }
          if (this.fileDescription !== '') {
            strLink = strLink + 'description/' + this.fileDescription;
          }
          this.getContentHTML(strLink);
        }
      }
    });
  }
  // tslint:disable-next-line:typedef
  async getContentHTML(str: any){
    if (str !== ''){
      const dt = await this.dataService.asyncConvertToBase64({mode: 'convert_str', link: str});
      this.contentHtml = dt.result;
      console.log(this.contentHtml);
    }
  }
  sendInfo(){
    this.router.navigate(['//tuyendung/ungtuyen']);
  }
}
