// @ts-ignore
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {TuyendungRoutingModule} from './tuyendung-routing.module';
import { MoitruongComponent } from './moitruong/moitruong.component';
import { CohoiComponent } from './cohoi/cohoi.component';
import { QuytrinhtuyendungComponent } from './quytrinhtuyendung/quytrinhtuyendung.component';
import { CauchuyenTdComponent } from './cauchuyen-td/cauchuyen-td.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { DetailCohoiComponent } from './cohoi/detail-cohoi/detail-cohoi.component';
import { ApplyFormComponent } from './ungtuyen/apply-form/apply-form.component';
import {FormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
  declarations: [
    MoitruongComponent,
    CohoiComponent,
    QuytrinhtuyendungComponent,
    CauchuyenTdComponent,
    DetailCohoiComponent,
    ApplyFormComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    TuyendungRoutingModule,
    NgSelectModule,
    FormsModule,
    MatDatepickerModule,
  ],
  exports: [
    ApplyFormComponent
  ]
})
export class TuyendungModule {}
